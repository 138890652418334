import * as React from "react";
import {MutableRefObject, useEffect, useImperativeHandle, useRef, useState} from "react";
import { FormButton, FormDialog, FormField, FormInputType, FormTabs, useForm, useFormData } from '../../../raal_components/form/Form';
import {
    FirmaPart,
    InvoiceFrequency,
    Podstav,
    Provozovna,
    ProvozovnaFirmaFields,
    SmlouvaS,
    Stav
} from "../../../model/Provozovna";
import {FormMap, FormMapOptions} from "../../../../common/component/form/FormMap";
import {Dial} from "../../../raal_components/dial/Dial";
import {mapDataConverterPoint} from "../../../raal_components/SharedConfig";
import {
    useEnumeratedSelect,
    useFirmaSelect,
    useFirmaSelectWithoutICO, useProvozovnaPodstavSelect,
    useProvozovnaSelectKod, useProvozovnaStavSelect,
    useStatSelectAsAutocomplete,
    useYesNoSelect
} from '../../../raal_components/SelectOptions';
import {useTranslation} from "react-i18next";
import {Box, Divider, Grid, Typography} from '@material-ui/core';
import moment, {Moment} from "moment";
import { IDClass, LicenseType, Region, RegionList } from '../../../model/CommonTypes';
import {useStyleContext} from "../../../context/ThemeModeContext";
import {DateRange, FormDateRange, FormDateRangeOptions} from "../../../../common/component/form/FormDateRange";
import {FormNumberRange, FormNumberRangeOptions, NumberRange} from "../../../../common/component/form/FormNumberRange";
import {exist, int2ip} from "../../../../common/utils/Util";
import {FormSimpleList} from "../../../../common/component/form/FormSimpleList";
import {UzivatelCiselnikTabbed} from "./Taby/UzivatelCiselnikTabbed";
import {ProvozovnaRecordsView} from "./ProvozovnaRecordsView";
import {Firma} from '../../../model/Firma';
import {FormLinkButton} from '../../../../common/component/form/FormLinkButton';
import {getRejstrikLinks} from '../../../../common/utils/LinkUtils';
import {dispatchModal, ModalActionType} from '../../../../common/component/ModalContainer';
import {RejstrikLinksComponent} from '../../../raal_components/parts/RejstrikLinksComponent';
import {useFetchCustom, useFetchDetail} from "../../../../common/utils/HttpUtils";
import * as Endpoint from "../../../raal_components/Endpoints";
import {TabConfig} from "../../../raal_components/controller/CodeBookController.d";
import {
    onCreateProvozovna,
    onProvozovnaChangeFirma,
    onProvozovnaChangeStav,
    ZKRACENE_INFORMACE_PODSTAVY
} from '../../../../common/logic/provozovna-logic';
import {ToUpperCase} from "../../../../common/component/form/FormInput";
import {FieldError} from "../../../../common/component/form/ValidationError";
import {useCountryPhoneCode} from "./StatCiselnik";
import {showSnack} from "../../../../common/component/SnackContainer";
import {SimpleValue} from "../../../model/SimpleValue";
import {useProvozovnaCiselnikLogic} from "./ProvozovnaCiselnikCommon";
import {TrasaFormViewType} from "../user/kilometrovnik/TrasaForm";
import {GoogleMapsGeometryButton} from "../../../raal_components/parts/GoogleMapsComponents";
import {SeznamGeometryButton} from "../../../raal_components/parts/SeznamComponents";
import {LatLng} from "leaflet";
import {JsonProperty} from "../../../../common/utils/objectmapper/Mapper";
import {Waypoint} from "../../../model/Waypoint";
import {Link, useParams} from "react-router-dom";
import {useLocation} from "react-router";
import {Column} from "../../../raal_components/grid/DataGrid.d";
import {Alert} from "@material-ui/lab";
import { UserRole } from '../../../model/User';
import {TabHelperNew} from "../../../raal_components/controller/TabHelper";
import {ComponentMode, RouteComponentProps} from "../../../routes";
import {UzivatelCiselnik} from "./UzivatelCiselnik";
import {PravniDokumentaceCiselnik} from "../common/PravniDokumentaceCiselnik";
import { useConfirmDialog } from '../../../raal_components/ConfirmDialog';
import {GenericMap} from "../../../../index.d";

export type ProvozovnaFormProps = {
    lockFirma?:boolean
    edited:boolean
    mode?: ComponentMode
    trialPeriod: Moment | null
    updatePeriod: (moment: Moment) => void
}

export function provozovnaCreateParams(data: Provozovna) {
    let np = new ProvozovnaFirmaFields();
    Object.assign(np, data);
    np.firmaUpdate = new FirmaPart(data.firma?.datSmlouvy, data.firma?.gdpr, data.firma?.http, data.firma?.datPojist);
    return np;
}


type AddressValidatorExposed = {
    validate: () => void
}
const AddressValidator = ({componentRef, data}:{componentRef: MutableRefObject<AddressValidatorExposed>, data: Provozovna}) => {
    const [error, setError] = useState(false);
    const {t} = useTranslation();

    useImperativeHandle(componentRef, () => ({
        validate
    }))

    const validate = () => {
        if (!data.firma) {
            setError(true);
            return;
        }
        const isEqual = data.pUlice === data.firma.r1Ulice &&
            data.pCisloPopisne === data.firma.r1CisloPopisne &&
            data.pCastObce === data.firma.r1CastObce &&
            data.pPsc === data.firma.r1Psc &&
            data.pObec === data.firma.r1Obec &&
            data.pStat === data.firma.r1Stat

        setError(!isEqual)
    }

    return <Grid container spacing={2}>
        <Grid item xs={12} sm={12} lg={12}>
            <Alert severity={!error ? "info" : "error"}>
                <Grid item>{error ? t(`Provozovna.notEqualAddress`) : t(`Provozovna.equalAddress`)}</Grid>
            </Alert>
        </Grid>
    </Grid>
}

export function ProvozovnaForm({edited, lockFirma, trialPeriod, updatePeriod}:ProvozovnaFormProps) {
    const provozovna = useFormData<Provozovna>();
    const firmaSelect = useFirmaSelectWithoutICO();
    const stavSelect = useEnumeratedSelect(Stav, "Enumerations.Stav", "number");
    const podStavSelect = useEnumeratedSelect(Podstav, "Enumerations.Podstav", "number");
    const podStavSelectLimited = useEnumeratedSelect(Podstav, "Enumerations.Podstav", "number", undefined, [Podstav.BEZ_PODSTAVU]);
    const licencesProps = useEnumeratedSelect(LicenseType, "Enumerations.LicenceTyp", "number");
    const frekvenceFakturSelect = useEnumeratedSelect(InvoiceFrequency, "Enumerations.InvoiceFrequency", "string");
    const typSelect = useEnumeratedSelect(Region, "Enumerations.Region", "number", undefined, undefined, RegionList);
    const smlouvaSelect = useEnumeratedSelect(SmlouvaS, "Enumerations.SmlouvaS", "string");
    const statSelect = useStatSelectAsAutocomplete();
    const provozovnaSelectKod = useProvozovnaSelectKod({isClearable:true});
    const {t} = useTranslation();
    const {classes} = useStyleContext();
    const form = useForm<Provozovna>();
    const {fetch: kodFetch} = useFetchCustom<SimpleValue>({endpoint: 'admin/available-code'})
    const [f, setF] = useState(false);
    const componentRef = useRef<AddressValidatorExposed>(null);

    useEffect(() => {
        if(!edited) {
            kodFetch({}).then((kod) => {
                if (exist(kod?.value)) {
                    const field = form.fields.find(f => f.props.name === "kod");
                    if (exist(field)) {
                        field.valueChanged(kod.value);
                        field.forceUpdate();
                    }
                }
            }).catch(() => {
                showSnack({title: `${t("Errors.CouldNotFetchCode")}`, severity: "error"})
            })
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        componentRef.current.validate();
    }, [form?.data])

    const {getCountryPhoneCode} = useCountryPhoneCode();

    const validatePhone = (data: Provozovna) => {
        const errors = new Array<FieldError>();
        const error = data.telCislaKontaktu && data.telCislaKontaktu.some(tel => tel === '')
        if (error) errors.push(FieldError.Create("FieldIsRequired", true, "telCislaKontaktu"));
        return errors;
    }

    const validateSmsUpominky = (data: Provozovna) => {
        const errors = new Array<FieldError>();
        const error = data.smsUpominky && data.smsUpominky.some(tel => tel === '')
        if (error) errors.push(FieldError.Create("FieldIsRequired", true, "smsUpominky"));
        return errors;
    }

    const validateEmail = (data: Provozovna) => {
        const errors = new Array<FieldError>();
        const emailError = data.emaily && data.emaily.some(e => e === '')
        if (emailError) errors.push(FieldError.Create("FieldIsRequired", true, "emaily"));
        return errors;
    }

    const validateEmailProfa = (data: Provozovna) => {
        const errors = new Array<FieldError>();
        const emailError = data.emailProfa && data.emailProfa.some(e => e === '')
        if (emailError) errors.push(FieldError.Create("FieldIsRequired", true, "emailProfa"));
        return errors;
    }


    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={12}>
                    <Divider className={classes.hrClass} style={{marginBottom: 5, marginTop: 0}}/>
                    <Grid item xs={12} lg={12}>
                        <Typography className={classes.sectionLabel} color="textSecondary" display="block" variant="caption">{t("Firma.UserIdentityLabel")}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <FormField forceUpdateWhenDataChanged={[]} name={"firma"} title={t("Firma.Title")} type={"select"} selectProps={{...firmaSelect, autoOpenDisabled: true}} required disabled={Boolean(lockFirma) || !form.isCreate} setValue={(data, fieldName, value) => {
                        data.firma = undefined;
                        if(exist(value)) {
                            data.assignFirma(value);
                            onProvozovnaChangeFirma(data);
                            componentRef.current.validate()
                        }
                    }} serverValidation={{translate: t}}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                    <FormField noAutofocus disabled name={"firma.ico"} title={t("Firma.ico")} type={"text"} textFieldProps={{inputProps: {maxLength: 80}}} getValue={d => d.firma?.ico} setValue={(data, fieldName, value) => {
                        if(exist(data.firma))
                            data.firma.ico = value;
                    }} serverValidation={{translate: t}}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                    <FormField noAutofocus disabled name={"firma.dic"} title={t("Firma.dic")} type={"text"} textFieldProps={{inputProps: {maxLength: 80}}} getValue={d => d.firma?.dic} setValue={(data, fieldName, value) => {
                        if(exist(data.firma))
                            data.firma.dic = value;
                    }} serverValidation={{translate: t}}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                    <FormField noAutofocus disabled name={"firma.dic1"} title={t("Firma.dic1")} type={"text"} textFieldProps={{inputProps: {maxLength: 80}}} getValue={d => d.firma?.dic1} setValue={(data, fieldName, value) => {
                        if(exist(data.firma))
                            data.firma.dic1 = value;
                    }} serverValidation={{translate: t}}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                    <FormField noAutofocus name={"gdpr"} forceUpdateWhenDataChanged={["nonGdprInfo"]} title={t("Firma.gdpr")} type={"checkbox"} dataTip={t("Provozovna.TooltipZmenaVeFirme")} getValue={d => d.firma?.gdpr} setValue={(data, fieldName, value) => {
                        if(exist(data.firma)) {
                            data.firma.gdpr = value;
                            data.nonGdprInfo = !value;
                        }
                    }}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={1}>
                    <FormField<any, Firma> name="link" type={FormInputType.Custom} title={t("Buttons.Rejstriky")}
                                customComponent={FormLinkButton}
                                customComponentOptions={
                                    {
                                        disabled: (data: Firma) => getRejstrikLinks(data?.ico, data?.dic, data?.r1Stat).length===0,
                                        onClick: (data: Firma) => {
                                            const links = getRejstrikLinks(data?.ico, data?.dic, data?.r1Stat);
                                            dispatchModal({type:ModalActionType.Show, title:t("Buttons.Rejstriky"), body:(
                                            <RejstrikLinksComponent links={links}/>
                                            )})
                                        }
                                    }
                                }
                                getValue={(data) => data.firma}
                    />
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                    <Divider className={classes.hrClass} style={{marginBottom: 5, marginTop: 10}}/>
                    <Grid item xs={12} lg={12}>
                        <Typography className={classes.sectionLabel} color="textSecondary" display="block" variant="caption">{t("Provozovna.UserIdentityLabel")}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <FormField name={"kod"} title={t("Provozovna.KodProvozovny")} type={"text"} disabled={!form.isCreate} required textFieldProps={{inputProps: {maxLength: 4}}} wrappingFunction={ToUpperCase}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <FormField noAutofocus disabled name={"vs"} title={t("Provozovna.vs")} type={"text"}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <FormField disabled name={"zalozen"} title={t("Provozovna.Zalozen")} type={"datetime"} />
                </Grid>
                {!edited ? <Grid item xs={12} sm={6} lg={3}>
                    <FormField name={"users"} title={t("Provozovna.GenerateUsers")} type={"number"}
                               numberProps={{format: "0", constraint: {allowNegative: false, precision: 2, scale: 0}}}
                               dataTip={t("Provozovna.ToolTipGenerateUsers")} serverValidation={{translate: (m) => m}}
                               setValue={(data, fieldName, value) => {
                                   if (exist(data.firma)) {
                                       data.users = value
                                   }
                               }}/>
                </Grid> : null}
                <Grid item xs={12} sm={6} lg={3}>
                    <FormField name={"typ"} title={t("Default.region")} type={"select"}  selectProps={typSelect} required/>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <FormField name={"typLicence"} title={t("Default.typLicence")} type={FormInputType.AutoComplete} selectProps={licencesProps} required />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <FormField name={"stav"} forceUpdateWhenDataChanged={["podstav", "pozastavena", "datzmenstav", "nezobrazovat", "enabled", "zkraceneInformace", "datzkus", "poznamka", "smlouvaS", "users"]} title={t("Provozovna.Stav")} type={"select"}  selectProps={stavSelect} required setValue={(data: Provozovna, fieldName, value) => {
                        data.stav = value;
                        onProvozovnaChangeStav(data);
                    }}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <FormField name={"podstav"} forceUpdateWhenDataChanged={["datzmenstav", "zkraceneInformace", "nezobrazovat", "pozastavena"]} title={t("Provozovna.Podstav")} type={"select"} selectProps={()=>provozovna.stav===Stav.POZASTAVIL ? podStavSelect : podStavSelectLimited} required setValue={(data: Provozovna, fieldName, value) => {
                        data.podstav = value;
                        data.nezobrazovat = data.isNezobrazovatCondition();
                        data.zkraceneInformace = data.stav===Stav.POZASTAVIL && ZKRACENE_INFORMACE_PODSTAVY.includes(data.podstav);
                    }} disabled={() => form.data.stav !== Stav.POZASTAVIL}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <FormField disabled name={"datzmenstav"} title={t("Provozovna.datzmenstav")} type={"datetime"} />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <FormField noAutofocus disabled name={"firma.regDatum"} title={t("Firma.RegDatum")} type={"datetime"} dateTimeOptions={{timeFormat: false}} getValue={d => d.firma?.regDatum} setValue={(data, fieldName, value) => {
                        if(exist(data.firma))
                            data.firma.regDatum = value;
                    }}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <FormField name={"datSmlouvy"} title={t("Firma.datSmlouvy")} type={"datetime"}  dateTimeOptions={{timeFormat: false}} dataTip={t("Provozovna.TooltipZmenaVeFirme")} getValue={d => d.firma?.datSmlouvy} setValue={(data, fieldName, value) => {
                        if(exist(data.firma))
                            data.firma.datSmlouvy = value;
                    }}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <FormField name={"smlouvaS"} title={t("Provozovna.SmlouvaS")} type={"select"} selectProps={smlouvaSelect} />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <FormField name={"datSmlouvyZmena"} title={t("Provozovna.datSmlouvyZmena")} type={"datetime"} dateTimeOptions={{timeFormat: false}} />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <FormField name={"ipDll"} title={t("Provozovna.ipDll")} type={"text"} disabled getValue={
                        (v) => int2ip(v.ipDll)
                    }/>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <FormField name={"poslPripoj"} title={t("Provozovna.PoslPripoj")} type={"datetime"} disabled/>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <FormField name={"pocPripoj"} title={t("Provozovna.PocPripoj")} type={"number"} disabled/>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <FormField name={"pocetUzivatelu"} title={t("Provozovna.PocetUzivatelu")} type={"number"} disabled/>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <FormField name={"datzkus"} forceUpdateWhenDataChanged={["poznamka"]} title={t("Provozovna.datzkus")} type={"datetime"} dateTimeOptions={{timeFormat: false}} disabled={()=>!exist(form.data.id) || !form.data.zkusebniDoba} setValue={(data, fieldName, value) => {
                        data.datzkus = value;
                    }}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                    <FormField forceUpdateWhenDataChanged={["datzkus", "poznamka"]} name={"zkusebniDoba"} title={t("Provozovna.ZkusebniDoba")} type={"checkbox"} setValue={(data, fieldName, value) => {
                        data.zkusebniDoba = value;
                        if(!value) {
                            if (data.datzkus) {
                                updatePeriod(data.datzkus)
                            }
                            data.datzkus = undefined;
                        }
                        else if (trialPeriod) {
                                data.datzkus = trialPeriod
                            } else {
                                data.datzkus = moment().add(31, "day").startOf('day');
                            }
                    }}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                    <FormField name={"omezeneInformace"} title={t("Provozovna.OmezeneInformace")} type={"checkbox"} />
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                    <FormField name={"nonGdprInfo"} title={t("Provozovna.NonGdprInfo")} type={"checkbox"} disabled/>
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                    <FormField name={"zkraceneInformace"} title={t("Provozovna.ZkraceneInformace")} type={"checkbox"} disabled/>
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                    <FormField name={"nezobrazovat"} title={t("Provozovna.Nezobrazovat")} type={"checkbox"}
                               disabled={()=>form.data.stav !== Stav.JEDE && (form.data.stav !== Stav.POZASTAVIL ||
                                   form.data.podstav !== Podstav.PODEZRELY)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                    <FormField name={"enabled"} title={t("Default.Enabled")} type={"checkbox"} disabled/>
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                    <FormField name={"pozastavena"} forceUpdateWhenDataChanged={["datzmenstav", "nezobrazovat"]} title={t("Firma.pozastavena")} type={"checkbox"}
                               disabled={() => provozovna.stav !== Stav.POZASTAVIL ||
                                   (provozovna.podstav !== Podstav.VYPOVED && provozovna.podstav !== Podstav.PODEZRELY)}
                               setValue={(data: Provozovna, fieldName, value) => {
                                    data.pozastavena = value;
                                    data.datzmenstav = moment();
                                    data.nezobrazovat = data.isNezobrazovatCondition();
                                    //dodatečné nastavení nezobrazovat v případě výběru podstavu 4 (neuhrazený sw, v enumu historicky hodnota podezrely
                                    if(value && data.stav===Stav.POZASTAVIL && [Podstav.PODEZRELY, Podstav.VYPOVED].indexOf(data.podstav)>-1)
                                        data.nezobrazovat = true;
                               }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                    <FormField name={"poznamka"} title={t("Firma.fPoznamka")} type={"textarea"} textFieldProps={{inputProps: {maxLength: 510}}}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <FormField name={"raalJmeno1"} title={t("Provozovna.RaalJmeno1")} type={"text"} textFieldProps={{inputProps: {maxLength: 100}}}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <FormField name={"raalJmeno2"} title={t("Provozovna.RaalJmeno2")} type={"text"} textFieldProps={{inputProps: {maxLength: 100}}}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <FormField name={"raalJmeno3"} title={t("Provozovna.RaalJmeno3")} type={"text"} textFieldProps={{inputProps: {maxLength: 100}}}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                    <FormField disabled name={"kdoZmenil"} title={t("Provozovna.KdoZmenil")} type={"text"} textFieldProps={{inputProps: {maxLength: 30}}} getValue={((data) => data.kdoZmenil?.toString() ?? '')}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                    <FormField disabled name={"kdyZmena"} title={t("Provozovna.KdyZmena")} type={"datetime"}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                    <FormField disabled name={"potlacInzDatum"} title={t("Provozovna.PotlacInzDatum")} type={"datetime"}  />
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                    <FormField forceUpdateWhenDataChanged={[]} name={"potlacInz"} title={t("Provozovna.PotlacInz")} type={"checkbox"}  onWillChange={(f) => {
                        form.focusField(f);
                        provozovna.potlacInzDatum = moment();
                    }}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                    <FormField disabled name={"potlacPrepravyDatum"} title={t("Provozovna.PotlacPrepravyDatum")} type={"datetime"}  />
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                    <FormField forceUpdateWhenDataChanged={[]} name={"potlacPrepravy"} title={t("Provozovna.PotlacPrepravy")} type={"checkbox"}  onWillChange={(f) => {
                        form.focusField(f);
                        provozovna.potlacPrepravyDatum = moment();
                    }}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                    <FormField disabled name={"potlacVvDatum"} title={t("Provozovna.PotlacVvDatum")} type={"datetime"}  />
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                    <FormField forceUpdateWhenDataChanged={[]} name={"potlacVv"} title={t("Provozovna.PotlacVv")} type={"checkbox"}  onWillChange={(f) => {
                        form.focusField(f);
                        provozovna.potlacVvDatum = moment();
                    }}/>
                </Grid>
            </Grid>
            <Divider className={classes.hrClass}/>
            <AddressValidator componentRef={componentRef} data={form?.data} />
            <Box mt={5}>
                <FormTabs tabs={[
                    {
                        title:t("Firma.NextLabel"),
                        body:(<Grid container spacing={2}>
                            <Grid item xs={12} sm={6} lg={4}>
                                <FormField name={"fax"} title={t("Provozovna.Fax")} type={"PhoneNumber"} textFieldProps={{inputProps: {maxLength: 16}}}/>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <FormField name={"datovaSchranka"} title={t("Provozovna.DatovaSchranka")} type={"text"} textFieldProps={{inputProps: {maxLength: 50}}} />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <FormField name={"firma.http"} title={t("Firma.http")} type={"text"} textFieldProps={{inputProps: {maxLength: 50}}} dataTip={t("Provozovna.TooltipZmenaVeFirme")} getValue={d => d.firma?.http} setValue={(data, fieldName, value) => {
                                    if(exist(data.firma))
                                        data.firma.http = value;
                                }}/>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12}>
                                <FormField name={"emaily"} title={t("Provozovna.emaily")} type="CustomWithRef" customComponent={FormSimpleList} customComponentOptions={{
                                    maxLines: 2,
                                    maxLength: 50,
                                    preFillValue: ()=> "@",
                                    valueExtractor: (v: string) => v,
                                    valueConstructor: (v: string) => v,
                                    cursorStartPosition: 0
                                }} isContainer={true} validate={validateEmail} serverValidation={{translate: t}}/>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <FormField name={"spStat"} title={t("Provozovna.SpStat")} type={"text"} textFieldProps={{inputProps: {maxLength: 100}}}/>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <FormField name={"jazyky"} title={t("Provozovna.jazyky")} type={"text"} textFieldProps={{inputProps: {maxLength: 100}}}/>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <FormField name={"auta"} title={t("Provozovna.Auta")} type={"text"} textFieldProps={{inputProps: {maxLength: 100}}}/>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                                <FormField name={"not1"} title={t("Default.Note")} type={"text"} textFieldProps={{inputProps: {maxLength: 200}}}/>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <FormField disabled name={"firma.pojisteni"} title={t("Provozovna.Pojisteni")} type={"text"} textFieldProps={{inputProps: {maxLength: 13}}} getValue={d => d.firma?.pojisteni} setValue={(data, fieldName, value) => {
                                    if(exist(data.firma))
                                        data.firma.pojisteni = value;
                                }}/>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <FormField name={"firma.datPojist"} title={t("Firma.datPojist")} type={"datetime"} dateTimeOptions={{timeFormat: false}} dataTip={t("Provozovna.TooltipZmenaVeFirme")} getValue={d => d.firma?.datPojist} setValue={(data, fieldName, value) => {
                                    if(exist(data.firma))
                                        data.firma.datPojist = value;
                                }}/>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <FormField name={"uOsobaUser"} title={t("Provozovna.uOsobaUser")} type={"text"} textFieldProps={{inputProps: {maxLength: 20}}}/>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <FormField name={"uTelefonUser"} title={t("Provozovna.uTelefonUser")} type={"PhoneNumber"} textFieldProps={{inputProps: {maxLength: 16}}} serverValidation={{translate:t}}/>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <FormField name={"uEmailUser"} title={t("Provozovna.uEmailUser")} type={"text"} textFieldProps={{inputProps: {maxLength: 50}}}/>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12}>
                                <FormField name={"telCislaKontaktu"} title={t("Provozovna.telCislaKontaktu")} type="CustomWithRef" customComponent={FormSimpleList} customComponentOptions={{
                                    maxLines: 5,
                                    maxLength: 16,
                                    preFillValue: ()=> getCountryPhoneCode(form?.data.pStat ? form?.data.pStat : form?.data.firma?.r1Stat),
                                    valueExtractor: (v: string) => v,
                                    valueConstructor: (v: string) => v,
                                    showFlagAdornment: true,
                                    inputType: "phone",
                                }} isContainer={true} validate={validatePhone} isLastInTab={true}/>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <FormField name={"zasilatReport"} title={t("Provozovna.ZasilatReport")} type={"checkbox"} disabled />
                            </Grid>
                        </Grid>)
                    },
                    {
                        title:t("Firma.ContactLabel"),
                        body:(<Grid container spacing={2}>
                            <Grid item xs={12} sm={12} lg={12}>
                                <Divider className={classes.hrClass} style={{marginBottom: 5, marginTop: 10}}/>
                                <Grid item xs={12} lg={12}>
                                    <Typography className={classes.sectionLabel} color="textSecondary" display="block" variant="caption">{t("Provozovna.UserContactLabel")}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormField name={"pNazev"} title={t("Provozovna.PNazev")} type={"text"} textFieldProps={{inputProps: {maxLength: 120}}} isFirstInTab={1}/>
                            </Grid>
                            <Grid item xs={12} sm={2} lg={2}>
                                <FormField<FormMapOptions>
                                    name={"koordinat"}
                                    title={t("Default.MapDefine")}
                                    type={FormInputType.Custom}
                                    showIcon={false}
                                    customComponent={FormMap}
                                    onChanged={() => setF(!f)}
                                    customComponentOptions={{
                                        converters:mapDataConverterPoint,
                                        maxPoints:1,
                                        enableOSRM: true,
                                        latLng: form.data?.koordinat ? new LatLng(form.data?.koordinat.getLatLng().lat, form.data?.koordinat.getLatLng().lng) : null,
                                        zoom: form.data?.koordinat ? 18 : null,
                                        viewType: TrasaFormViewType.POINT
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2} lg={2}>
                                {<GoogleMapsGeometryButton gps={form.data?.koordinat}/>}
                            </Grid>
                            <Grid item xs={12} sm={2} lg={2}>
                                {<SeznamGeometryButton gps={form.data?.koordinat}/>}
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormField name={"pUlice"} onChanged={() => componentRef.current.validate()} title={t("Provozovna.PUlice")} type={"text"} textFieldProps={{inputProps: {maxLength: 60}}}/>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormField name={"pCisloPopisne"} onChanged={() => componentRef.current.validate()} title={t("Provozovna.PCisloPopisne")} type={"text"} textFieldProps={{inputProps: {maxLength: 10}}}/>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormField name={"pObec"} onChanged={() => componentRef.current.validate()} title={t("Provozovna.PObec")} type={"text"} textFieldProps={{inputProps: {maxLength: 30}}}/>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormField name={"pCastObce"} onChanged={() => componentRef.current.validate()} title={t("Provozovna.PCastObce")} type={"text"} textFieldProps={{inputProps: {maxLength: 30}}}/>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormField name={"pPsc"} onChanged={() => componentRef.current.validate()} title={t("Provozovna.PPsc")} type={"text"} textFieldProps={{inputProps: {maxLength: 10}}}/>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormField name={"pStat"} onChanged={() => componentRef.current.validate()} title={t("Provozovna.PStat")} type={"select"} forceUpdateWhenDataChanged={["telCislaKontaktu"]} selectProps={statSelect}/>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12}>
                                <Divider className={classes.hrClass} style={{marginBottom: 5, marginTop: 10}}/>
                                <Grid item xs={12} lg={12}>
                                    <Typography className={classes.sectionLabel} color="textSecondary" display="block" variant="caption">{t("Provozovna.UserCorespondLabel")}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormField name={"kNazev"} title={t("Provozovna.KNazev")} type={"text"} textFieldProps={{inputProps: {maxLength: 120}}}/>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormField name={"kAdresat"} title={t("Provozovna.KAdresat")} type={"text"} textFieldProps={{inputProps: {maxLength: 20}}}/>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormField name={"kUlice"} title={t("Provozovna.KUlice")} type={"text"} textFieldProps={{inputProps: {maxLength: 60}}}/>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormField name={"kCisloPopisne"} title={t("Provozovna.KCisloPopisne")} type={"text"} textFieldProps={{inputProps: {maxLength: 10}}}/>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormField name={"kObec"} title={t("Provozovna.KObec")} type={"text"} textFieldProps={{inputProps: {maxLength: 40}}}/>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormField name={"kCastObce"} title={t("Provozovna.KCastObce")} type={"text"} textFieldProps={{inputProps: {maxLength: 30}}}/>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormField name={"kPsc"} title={t("Provozovna.KPsc")} type={"text"} textFieldProps={{inputProps: {maxLength: 10}}}/>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormField name={"kStat"} title={t("Provozovna.KStat")} type={"select"}  selectProps={statSelect} isLastInTab={true}/>
                            </Grid>
                        </Grid>)
                    },
                    {
                        title:t("Provozovna.PayInfo"),
                        body:(<Grid container spacing={2}>
                            <Grid item xs={12} sm={6} lg={4}>
                                <FormField name={"uOsoba"} title={t("Provozovna.uOsobaUser")} type={"text"}  textFieldProps={{inputProps: {maxLength: 20}}} isFirstInTab={2}/>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <FormField name={"uTelefon"} title={t("Provozovna.uTelefonUser")} type={"PhoneNumber"}  textFieldProps={{inputProps: {maxLength: 16}}} serverValidation={{translate:t}}/>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <FormField name={"uEmail"} title={t("Provozovna.uEmailUser")} type={"text"} textFieldProps={{inputProps: {maxLength: 50}}}/>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12}>
                                <FormField name={"emailProfa"} title={t("Provozovna.EmailProfa")} type="CustomWithRef" customComponent={FormSimpleList} customComponentOptions={{
                                    maxLines: 3,
                                    maxLength: 50,
                                    preFillValue: ()=> "@",
                                    valueExtractor: (v: string) => v,
                                    valueConstructor: (v: string) => v,
                                    cursorStartPosition: 0
                                }} isContainer={true} validate={validateEmailProfa}  serverValidation={{translate: t}}/>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12}>
                                <FormField name={"smsUpominky"} title={t("Provozovna.SmsUpominky")} type="CustomWithRef" customComponent={FormSimpleList} customComponentOptions={{
                                    maxLines: 3,
                                    maxLength: 16,
                                    preFillValue: () => getCountryPhoneCode(form?.data.pStat ? form?.data.pStat : form?.data.firma?.r1Stat),
                                    valueExtractor: (v: string) => v,
                                    valueConstructor: (v: string) => v,
                                }} isContainer={true} validate={validateSmsUpominky} serverValidation={{translate: t}}/>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12}>
                                <Divider className={classes.hrClass} style={{marginBottom: 10, marginTop: 10}}/>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormField name={"frekvenceFaktur"} title={t("Provozovna.FrekvenceFaktur")} type={"select"} selectProps={frekvenceFakturSelect}/>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormField name={"souhrnne"} title={t("Provozovna.souhrnne")} type={"select"} selectProps={provozovnaSelectKod} />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12}>
                                <Divider className={classes.hrClass} style={{marginBottom: 5, marginTop: 10}}/>
                                <Grid item xs={12} lg={12}>
                                    <Typography className={classes.sectionLabel} color="textSecondary" display="block" variant="caption">{t("Provozovna.ZpusobFakturace")}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <FormField name={"email1"} title={t("Provozovna.email1")} type={"checkbox"} />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <FormField name={"posta"} title={t("Provozovna.posta")} type={"checkbox"} />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <FormField name={"odlisadr"} title={t("Provozovna.odlisadr")} type={"checkbox"} isLastInTab={true} />
                            </Grid>
                        </Grid>)
                    }
                ]} />
            </Box>
        </>
    );
}

export class ProvozovnaFilter extends Provozovna {
    telCislo:string;
    datSmlouvyZmenaRange: DateRange;
    datSmlouvyRange: DateRange;
    datzkusRange: DateRange;
    novaSmlouvaRange: DateRange;
    poslPripojRange: DateRange;
    zalozenRange: DateRange;
    datzmenstavRange: DateRange;
    kdyZmenaRange: DateRange;
    potlacPrepravyDatumRage: DateRange;
    potlacVvDatumRange: DateRange;
    potlacInzDatumRange: DateRange;
    datumSmlouvyRange: DateRange;
    pocPripojRange: NumberRange;
    dispecer: string;

    @JsonProperty({type:{clazz:Waypoint}})
    okoli?: Waypoint

    radius?: number
}

export function ProvozovnaFilterForm() {
    const firmaSelectFilter = useFirmaSelect({isClearable:true});
    const yesNoSelect = useYesNoSelect({isClearable:true});
    const stavSelect = useEnumeratedSelect(Stav, "Enumerations.Stav", "string", {isClearable:true});
    const podStavSelect = useEnumeratedSelect(Podstav, "Enumerations.Podstav", "string", {isClearable:true});
    const frekvenceFakturSelect = useEnumeratedSelect(InvoiceFrequency, "Enumerations.InvoiceFrequency", "string", {isClearable:true});
    const statSelectAutocompleteFilter = useStatSelectAsAutocomplete({isClearable:true});
    const typSelect = useEnumeratedSelect(Region, "Enumerations.Region", "number", {isClearable:true}, undefined, RegionList);
    const smlouvaSelect = useEnumeratedSelect(SmlouvaS, "Enumerations.SmlouvaS", "string", {isClearable: true});
    const provozovnaSelectKod = useProvozovnaSelectKod({isClearable:true});
    const spacing = 1;
    const {t} = useTranslation();
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"firma"} title={t("Firma.Title")} type={"select"}  selectProps={firmaSelectFilter} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"kod"} title={t("Provozovna.Kod")} type={"text"} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"vs"} title={t("Provozovna.vs")} type={"text"} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"typ"} title={t("Default.region")} type={"select"}  selectProps={typSelect} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"stav"} title={t("Provozovna.Stav")} type={"select"}  selectProps={stavSelect} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"podstav"} title={t("Provozovna.Podstav")} type={"select"}  selectProps={podStavSelect}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"poznamka"} title={t("Firma.fPoznamka")} type={"text"} textFieldProps={{inputProps: {maxLength: 510}}}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"enabled"} title={t("Default.Enabled")} type={"select"} selectProps={yesNoSelect} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"nonGdprInfo"} title={t("Provozovna.NonGdprInfo")} type={"select"} selectProps={yesNoSelect} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"zkraceneInformace"} title={t("Provozovna.ZkraceneInformace")} type={"select"} selectProps={yesNoSelect} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"omezeneInformace"} title={t("Provozovna.OmezeneInformace")} type={"select"} selectProps={yesNoSelect} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"nezobrazovat"} title={t("Provozovna.Nezobrazovat")} type={"select"} selectProps={yesNoSelect} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"pozastavena"} title={t("Firma.pozastavena")} type={"select"} selectProps={yesNoSelect} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"smlouvaS"} title={t("Provozovna.SmlouvaS")} type={"select"} selectProps={smlouvaSelect} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"zkusebniDoba"} title={t("Provozovna.ZkusebniDoba")} type={"select"} selectProps={yesNoSelect} />
            </Grid>
            <Grid item lg={6} sm={6} xs={12}>
                <FormField title={t("Provozovna.Dispecer")} name={"dispecer"} type={"text"} />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"uOsobaUser"} title={t("Provozovna.uOsobaUser")} type={"text"} textFieldProps={{inputProps: {maxLength: 20}}}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"uTelefonUser"} title={t("Provozovna.uTelefonUser")} type={"text"} textFieldProps={{inputProps: {maxLength: 30}}} serverValidation={{translate:t}}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"uEmailUser"} title={t("Provozovna.uEmailUser")} type={"text"} textFieldProps={{inputProps: {maxLength: 50}}}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"fax"} title={t("Provozovna.Fax")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"email"} title={t("Default.Email")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"datovaSchranka"} title={t("Provozovna.DatovaSchranka")} type={"text"} />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"telCislo"} title={t("Default.PhoneNumbersKratke")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"ipDll"} title={t("Provozovna.ipDll")} type={"text"} />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"spStat"} title={t("Provozovna.SpStat")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"jazyky"} title={t("Provozovna.jazyky")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"auta"} title={t("Provozovna.Auta")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"not1"} title={t("Provozovna.not1")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"kdoZmenil"} title={t("Provozovna.KdoZmenil")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"raalJmeno1"} title={t("Provozovna.RaalJmeno1")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"raalJmeno2"} title={t("Provozovna.RaalJmeno2")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"raalJmeno3"} title={t("Provozovna.RaalJmeno3")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"potlacPrepravy"} title={t("Provozovna.PotlacPrepravy")} type={"select"} selectProps={yesNoSelect}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"potlacVv"} title={t("Provozovna.PotlacVv")} type={"select"} selectProps={yesNoSelect}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"potlacInz"} title={t("Provozovna.PotlacInz")} type={"select"} selectProps={yesNoSelect}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"pNazev"} title={t("Provozovna.PNazev")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"pUlice"} title={t("Provozovna.PUlice")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"pCisloPopisne"} title={t("Provozovna.PCisloPopisne")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"pObec"} title={t("Provozovna.PObec")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"pCastObce"} title={t("Provozovna.PCastObce")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"pPsc"} title={t("Provozovna.PPsc")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"pStat"} title={t("Provozovna.PStat")} type={"select"}  selectProps={statSelectAutocompleteFilter}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"kNazev"} title={t("Provozovna.KNazev")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"kAdresat"} title={t("Provozovna.KAdresat")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"kUlice"} title={t("Provozovna.KUlice")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"kCisloPopisne"} title={t("Provozovna.KCisloPopisne")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"kObec"} title={t("Provozovna.KObec")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"kCastObce"} title={t("Provozovna.KCastObce")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"kPsc"} title={t("Provozovna.KPsc")} type={"text"}  />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"kStat"} title={t("Provozovna.KStat")} type={"select"}  selectProps={statSelectAutocompleteFilter}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"uOsoba"} title={t("Provozovna.uOsobaUser")} type={"text"}  textFieldProps={{inputProps: {maxLength: 20}}}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"uTelefon"} title={t("Provozovna.uTelefonUser")} type={"text"}  textFieldProps={{inputProps: {maxLength: 30}}}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"uEmail"} title={t("Provozovna.uEmailUser")} type={"text"} textFieldProps={{inputProps: {maxLength: 50}}}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"emailProfa"} title={t("Provozovna.EmailProfa")} type={"text"} />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"smsUpominky"} title={t("Provozovna.SmsUpominky")} type={"text"} />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"frekvenceFaktur"} title={t("Provozovna.FrekvenceFaktur")} type={"select"} selectProps={frekvenceFakturSelect}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField name={"souhrnne"} title={t("Provozovna.souhrnne")} type={"select"} selectProps={provozovnaSelectKod} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"email1"} title={t("Provozovna.email1")} type={"select"} selectProps={yesNoSelect} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"posta"} title={t("Provozovna.posta")} type={"select"} selectProps={yesNoSelect} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <FormField name={"odlisadr"} title={t("Provozovna.odlisadr")} type={"select"} selectProps={yesNoSelect} />
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
                <FormField<FormDateRangeOptions> title={t("Provozovna.datSmlouvyZmena")} name={"datSmlouvyZmenaRange"} type={"Custom"}
                                                 customComponent={FormDateRange} customComponentOptions={{spacing: spacing, timeFormat: false}}/>
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
                <FormField<FormDateRangeOptions> title={t("Provozovna.datzkus")} name={"datzkusRange"} type={"Custom"}
                                                 customComponent={FormDateRange} customComponentOptions={{spacing: spacing, timeFormat: false}} />
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
                <FormField<FormDateRangeOptions> title={t("Provozovna.PoslPripoj")} name={"poslPripojRange"} type={"Custom"}
                                                 customComponent={FormDateRange} customComponentOptions={{spacing: spacing, timeFormat: false}}/>
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
                <FormField<FormNumberRangeOptions> title={t("Provozovna.PocPripoj")} name="pocPripojRange" type={"Custom"}
                                                   customComponent={FormNumberRange} customComponentOptions={{spacing}}/>
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
                <FormField<FormDateRangeOptions> title={t("Provozovna.Zalozen")} name={"zalozenRange"} type={"Custom"}
                                                 customComponent={FormDateRange} customComponentOptions={{spacing: spacing, timeFormat: false}}/>
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
                <FormField<FormDateRangeOptions> title={t("Provozovna.datzmenstav")} name={"datzmenstavRange"} type={"Custom"}
                                                 customComponent={FormDateRange} customComponentOptions={{spacing: spacing, timeFormat: false}}/>
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
                <FormField<FormDateRangeOptions> title={t("Provozovna.KdyZmena")} name={"kdyZmenaRange"} type={"Custom"}
                                                 customComponent={FormDateRange} customComponentOptions={{spacing: spacing, timeFormat: false}}/>
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
                <FormField<FormDateRangeOptions> title={t("Provozovna.PotlacPrepravyDatum")} name={"potlacPrepravyDatumRange"} type={"Custom"}
                                                 customComponent={FormDateRange} customComponentOptions={{spacing: spacing, timeFormat: false}}/>
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
                <FormField<FormDateRangeOptions> title={t("Provozovna.PotlacVvDatum")} name={"potlacVvDatumRange"} type={"Custom"}
                                                 customComponent={FormDateRange} customComponentOptions={{spacing: spacing, timeFormat: false}}/>
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
                <FormField<FormDateRangeOptions> title={t("Provozovna.PotlacInzDatum")} name={"potlacInzDatumRange"} type={"Custom"}
                                                 customComponent={FormDateRange} customComponentOptions={{spacing: spacing, timeFormat: false}}/>
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
                <FormField<FormDateRangeOptions> title={t("Firma.datSmlouvy")} name={"datumSmlouvyRange"} type={"Custom"}
                                                 customComponent={FormDateRange} customComponentOptions={{spacing: spacing, timeFormat: false}}/>
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
                <FormField<FormDateRangeOptions> title={'Datum vytvoreni smlouvy'} name={"datumVytvoreniSmlouvyRange"} type={"Custom"}
                                                 customComponent={FormDateRange} customComponentOptions={{spacing: spacing, timeFormat: false}}/>
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
                <FormField<FormDateRangeOptions> title={'Datum potvrzeni prijimatelem'} name={"datumPotvrzeniSmlouvyPrijimatelemRange"} type={"Custom"}
                                                 customComponent={FormDateRange} customComponentOptions={{spacing: spacing, timeFormat: false}}/>
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
                <FormField<FormDateRangeOptions> title={'Datum potvrzeni RAALem'} name={"datumPotvrzeniSmlouvyRaalemRange"} type={"Custom"}
                                                 customComponent={FormDateRange} customComponentOptions={{spacing: spacing, timeFormat: false}}/>
            </Grid>
        </Grid>
    );
}
// eslint-disable-next-line
export function useProvozovnaTabs<Filter>(isHistory: Boolean = false):TabConfig<Provozovna>[] {
    const {t} = useTranslation();
    return isHistory ? undefined : [
		{
			title: t("CommonGrid.History"),
			countEndPoint: "admin/provozovna-history",
			params: (data: Provozovna) => {return {parentId: data?.id}},
			render:()=><TabHelperNew<Provozovna> render={(data, edited) => {
				return edited&&<ProvozovnaCiselnik historyTab url={Endpoint.admin_provozovna_history} mode={ComponentMode.GridMode}/>
			}}/>,
			disabled:(data: Provozovna, edited: boolean) => !edited
		},
        {
            title: t("Provozovna.Records"),
            countEndPoint: (data: Provozovna) => data ? `admin/provozovna/${data?.id}/zaznam` : null,
            countFce: (data: any) => {
                if (data?.list?.length !== 0) {
                    const closed = data.list.filter((d: any)  => d.uzavreno).length;
                    const active = data.list.length - closed;

                    return `${active} / ${closed}`;
                }
                return '0';
            },
            render:()=><TabHelperNew<Provozovna> render={(data, edited) => {
                return edited&&<ProvozovnaRecordsView provozovna={data} gridId={"provozovna-zaznamy"} mode={ComponentMode.GridMode}/>
            }} />,
            disabled:(data: Provozovna, edited: boolean) => !edited
        },
		{
			title: t("Firma.TabUzivatele"),
			countEndPoint: "admin/user",
			params: (data: Provozovna) => {return {provozovna: data?.id}},
			countFce: (data: any) => {
				if (data?.list?.length !== 0) {
					const users = data.list.filter((d: any) => d.roles && d.roles.indexOf(UserRole.ROLE_USER) > -1).length;
					return users.toString();
				}
				return '0';
			},
			render:()=><TabHelperNew<Provozovna> render={(data, edited) => {
				return edited&&<UzivatelCiselnikTabbed provozovna={data} defaultQueryParameters={{"provozovna": data.id}} adminView={true} mode={ComponentMode.GridMode}/>
			}} />,
			disabled:(data: Provozovna, edited: boolean) => !edited
		},
        {
            title: t("Provozovna.historieUzivatelu"),
            countEndPoint: "admin/user-union-view",
            params: (data: Provozovna) => {return {provozovna: data?.id}},
            render:()=><TabHelperNew<Provozovna> render={(data, edited) => {
                return <UzivatelCiselnik url={"admin/user-union-view"} mode={ComponentMode.GridMode} provozovna={data} admin={true} forceHistoryView={true}/>
            }}/>,
            disabled:(data: Provozovna, edited: boolean) => !edited
        },
        {
            title: t("Provozovna.Smlouvy"),
            countEndPoint: (data: Provozovna) => data ? `admin/pravni-dokumentace/${data?.id}/podepsana` : null,
            params: (data: Provozovna) => {return {provozovna: data?.id}},
            render:()=><TabHelperNew<Provozovna> render={(data, edited) => {
                return <PravniDokumentaceCiselnik url={`admin/pravni-dokumentace/${data?.id}/podepsana`} admin={true} kod={data.kod} />
            }}/>,
            disabled:(data: Provozovna, edited: boolean) => !edited
        }
    ].filter(i => exist(i));
}

interface ProvozovnaCiselnikProps {
    historyTab?: boolean
    url?: string
    forceHistoryView?: boolean
    mode?: ComponentMode
}

export const ProvozovnaHistoryCiselnik = (props: RouteComponentProps) => {
    return <ProvozovnaCiselnik url={Endpoint.admin_provozovna_history} forceHistoryView mode={props.mode}/>
}

export function ProvozovnaCiselnik(props: ProvozovnaCiselnikProps) {
    const isHistory = props.forceHistoryView || props.historyTab;
    const tabs = useProvozovnaTabs(isHistory);
    const {fetch:fetchFirma} = useFetchDetail(Firma, Endpoint.admin_firma);
    const {t} = useTranslation();
    const {pathname, state} = useLocation<any>();
    const firma = state?.tabDetailData?.firma;
    const provozovnaCiselnikLogic = useProvozovnaCiselnikLogic();
    const provozovnaStavSelect = useProvozovnaStavSelect({isClearable:true, isMulti:false})
    const provozovnaPodstavSelect = useProvozovnaPodstavSelect({isClearable:true, isMulti:false})
    const {id} = useParams<IDClass>();
	const [showConfirm] = useConfirmDialog();
    // State pre pamatanie zkusobnej doby medzi ukladaniami detailu provozovny
    const [trialPeriod, setPeriod] = useState<Moment | null>(null)

    const updatePeriod = (moment: Moment) => {
        setPeriod(moment)
    }

	const dialog: FormDialog = {
		body: t("Default.SendTheFirstAccessDataTextSingle"),
		title: t("Default.SendTheFirstAccessDataTitleSingle"),
		buttons: { confirm: t("Default.SendTheFirstAccessDataButtonSingle")}
	};

	const dialog2024: FormDialog = {
		body: t("Default.SendTheFirstAccessDataTextMulti"),
		title: t("Default.SendTheFirstAccessDataTitleMulti"),
		buttons: { confirm: t("Default.SendTheFirstAccessDataButtonMulti") }
	};

    const defaultQueryParameters : GenericMap = exist(props.historyTab) ? {
        parentId: id
    } : undefined;

    return (
            <Dial<Provozovna, ProvozovnaFilter>
                mode={props.mode}
                localization={{CreateRecord:t("Provozovna.VytvoritProvozovnu")}}
                excludeFieldsForDirtyCheck={['pocPripoj', 'poslPripoj', 'pocetUzivatelu']}
                config={{
                    clazz:Provozovna,
                    tableTitle: t("Dials.Places"),
                    endpoint: props.url ?? Endpoint.admin_provozovna,
                    hideAddNewActions: isHistory,
                    filtering:true,
                    defaultQueryParameters: defaultQueryParameters,
                    exportConfig: {
                        exportable: true,
                        exportAll: true,
                        endpoint: props.url ?? Endpoint.admin_provozovna,
                        fileName: "export_provozovny",
                        translationPrefix: ['Provozovna', 'Firma', 'Default'],
                        formats: ["csv"],
                        exportableProps: [
                            {
                                type: ["csv"],
                                fields: ["r1Nazev", "r1Ulice", "r1CisloPopisne", "r1Psc", "r1Obec", "r1Stat", "ico", "dic", "dic1", "gdpr", "kod", "vs", "zalozen", "typ", "typLicence", "stav", "podstav", "datzmenstav", "regDatum", "datSmlouvy", "smlouvaS", "datSmlouvyZmena", "ipDll", "poslPripoj", "pocPripoj", "pocetUzivatelu", "datzkus", "zkusebniDoba", "omezeneInformace", "nonGdprInfo", "zkraceneInformace", "nezobrazovat", "enabled", "pozastavena", "poznamka", "raalJmeno1", "raalJmeno2", "raalJmeno3", "kdoZmenil", "kdyZmena", "potlacInzDatum", "potlacInz", "potlacPrepravyDatum", "potlacPrepravy", "potlacVvDatum", "potlacVv", "fax", "datovaSchranka", "http", "emaily", "spStat", "jazyky", "auta", "not1", "pojisteni", "datPojist", "uosobaUser", "utelefonUser", "uemailUser", "telCislaKontaktu", "pnazev", "pulice", "pcislopopisne", "ppsc", "pobec", "pstat", "knazev", "kulice", "kcislopopisne", "kpsc", "kobec", "kstat", "uosoba", "utelefon", "uemail", "emailProfa", "smsUpominky", "frekvenceFaktur", "souhrnne", "email1", "posta", "odlisadr"]
                            }
                        ],
                        extendedProps: [
                            {
                                type: ["csv"],
                                translationPrefix: ["Firma"],
                                addExtendedProps: (data) => {
                                    return {
                                        r1Nazev: data.firma?.r1Nazev,
                                        r1Obec: data.firma?.r1Obec,
                                        r1Ulice: data.firma?.r1Ulice,
                                        r1CisloPopisne: data.firma?.r1CisloPopisne,
                                        r1Psc: data.firma?.r1Psc,
                                        r1Stat: data.firma?.r1Stat,
                                        ico: data.firma?.ico,
                                        dic: data.firma?.dic,
                                        dic1: data.firma?.dic1,
                                        gdpr: data.firma?.gdpr,
                                        regDatum: data.firma?.regDatum,
                                        datSmlouvy: data.firma?.datSmlouvy,
                                        pojisteni: data.firma?.pojisteni,
                                        datPojist: data.firma?.datPojist,
                                        http: data.firma?.http
                                    }
                                }
                            }
                        ],
                        formattedProps: [
                            {
                                type: ["csv"],
                                field: "datSmlouvyZmena",
                                format: (data) => {
                                    return data ? moment(data).format('DD.MM.YY'): null;
                                }
                            },
                            {
                                type: ["csv"],
                                field: "regDatum",
                                format: (data) => {
                                    return data ? moment(data).format('DD.MM.YY'): null;
                                }
                            },
                            {
                                type: ["csv"],
                                field: "datSmlouvy",
                                format: (data) => {
                                    return data ? moment(data).format('DD.MM.YY'): null;
                                }
                            },
                            {
                                type: ["csv"],
                                field: "datPojist",
                                format: (data) => {
                                    return data ? moment(data).format('DD.MM.YY'): null;
                                }
                            },
                            {
                                type: ["csv"],
                                field: "datPojist",
                                format: (data) => {
                                    return data ? moment(data).format('DD.MM.YY'): null;
                                }
                            },
                            {
                                type: ["csv"],
                                field: "emaily",
                                format: (data) => {
                                    return data ? data.join(', ') : null;
                                }
                            },
                            {
                                type: ["csv"],
                                field: "telCislaKontaktu",
                                format: (data) => {
                                    return data && data.length !== 0 ? data.map((d: any) => `${d.slice(0, 4)} ${d.slice(4)}`).join(', ') : null;
                                }
                            },
                            {
                                type: ["csv"],
                                field: "dispeceri",
                                format: (data) => {
                                    return data && data.length !== 0 ? data.filter((d: any) => d.jmeno).map((d: any) => d.formatDisplayName()).join(', ') : null;
                                }
                            },
                            {
                                type: ["csv"],
                                field: "uTelefonUser",
                                format: (data) => {
                                    return data ? `${data.slice(0, 4)} ${data.slice(4)}` : null;
                                }
                            },
                            {
                                type: ["csv"],
                                field: "fax",
                                format: (data) => {
                                    return data ? `${data.slice(0, 4)} ${data.slice(4)}` : null;
                                }
                            },
                            {
                                type: ["csv"],
                                field: "ipDll",
                                format: (data) => {
                                    return data ? int2ip(data) : null;
                                }
                            },
                            {
                                type: ["csv"],
                                field: "emailProfa",
                                format: (data) => {
                                    return data ? data.join(', ') : null;
                                }
                            },
                            {
                                type: ["csv"],
                                field: "smsUpominky",
                                format: (data) => {
                                    return data && data.length !== 0 ? data.map((d: any) => `${d.slice(0, 4)} ${d.slice(4)}`).join(', ') : null;
                                }
                            }
                        ],
                        defaultQueryParameters: defaultQueryParameters,
                        },
                    columns: [
                        !exist(props.historyTab) && isHistory ? {
                            title: t("Provozovna.Id"),
                            field: 'parentId',
                            filterProps: () => ({type: FormInputType.Number, numberProps: {format: '0.[00]'}}),
                            render: (data: Provozovna) => data.provozovnaId ? <Link to={{
                                pathname: `/admin/provozovny/${data.provozovnaId}`,
                                state: {
                                    forcePreviousPage: pathname
                                }
                            }
                            } onClick={(e) => {e.stopPropagation();}}>{data.provozovnaId}</Link> : data.provozovnaId,
                        } : undefined,
                        {
                            title: t("Provozovna.Kod"),
                            field: 'kod',
                            defaultSort:"asc",
                            filterProps:() => ({type:FormInputType.Text}),
                            cellStyle: {width: 50},
                            headerStyle: {width: 50},
                        },
                        {
                            title: t("Firma.Title"),
                            field: 'firma.r1Nazev',
                            setFilterValue:(data: Provozovna, value: string) => (()=>!data.firma ? data.firma = new Firma(): data.firma)().r1Nazev = value,
                            render:(row:Provozovna)=> `${row.firma?.r1Nazev??t("Firma.MissingFirma")}`,
                            filterProps:() => ({type: FormInputType.Text})
                        },
                        {
                            title: t("Provozovna.PNazev"),
                            field: 'pNazev',
                            filterProps:() => ({type:FormInputType.Text})
                        },
						{
							title: t("Provozovna.Stav"),
							field: 'stav',
                            filterProps:() => ({type:FormInputType.Select, selectProps: provozovnaStavSelect}),
							render(data: Provozovna) {
								return t(`Enumerations.Stav.${data?.stav}`);
							}
						},
						{
							title: t("Provozovna.Podstav"),
							field: 'podstav',
							filterProps:() => ({type:FormInputType.Select, selectProps: provozovnaPodstavSelect}),
							render(data: Provozovna) {
								return t(`Enumerations.Podstav.${data?.podstav}`);
							}
						},
						{
							title: t("Firma.ico"),
							field: 'firma.ico',
							filterProps:() => ({type:FormInputType.Text}),
							render(data: Provozovna) {
								return data.firma.ico ?? '';
							}
						},
						{
							title: t("Firma.dic"),
							field: 'firma.dic',
							filterProps:() => ({type:FormInputType.Text}),
							render(data: Provozovna) {
								return data.firma.dic ?? '';
							}
						},
						{
							title: t("Firma.dic1"),
							field: 'firma.dic1',
							filterProps:() => ({type:FormInputType.Text}),
							render(data: Provozovna) {
								return data.firma.dic1 ?? '';
							}
						},
						{
							title: t("Default.PhoneNumbersKratke"),
							field: 'telCislo',
							sorting:false,
							filterProps:() => ({type:FormInputType.Text}),
							render(data: Provozovna) {
								return data.telCislaKontaktu?.join(", ") ?? "";
							}
						},
						{
							title: t("Provozovna.emaily"),
							field: 'email',
							sorting:false,
							filterProps:() => ({type:FormInputType.Text}),
							render(data: Provozovna) {
								return data.emaily?.join(", ") ?? "";
							}
						},
						{
							title: t("Provozovna.not1"),
							sorting:false,
							field: 'poznamka',
							filterProps:() => ({type:FormInputType.Text})
						},
						{
							title: t("Provozovna.LastChange"),
							field: 'kdyZmena',
							filterProps:() => ({type:FormInputType.Text}),
							render(data: Provozovna) {
								return moment(data.kdyZmena)?.format("L LT") ?? '';
							}
						},
						{
							title: t("Provozovna.KdoZmenil"),
							field: 'kdoZmenil',
							filterProps:() => ({type:FormInputType.Text}),
							render: (data: Provozovna) => (data.kdoZmenil?.toString() ?? '')
						},
                    ].filter(i => exist(i)) as Column<Provozovna>[]
            }}
            hideNewButtonOnEdit
            crudConfig={{editEnabled: true, removeEnabled:false, addEnabled:false}}
            createInstance={async() => {
                const p = new Provozovna();
                p.enabled = true;
                p.smlouvaS=SmlouvaS.NONE;
                if(firma) {
                    p.assignFirma(await fetchFirma({arg:firma}))
                }
                onCreateProvozovna(p, p.firma);
                return p;
            }}
            preventCloseAfterSave
            createParameters={provozovnaCreateParams}
            filterClazz={ProvozovnaFilter}
            modalHeaderName={()=>t("Default.ModalZakladni")}
            layoutForm={(edited)=><ProvozovnaForm trialPeriod={trialPeriod} updatePeriod={updatePeriod} edited={edited} {...{lockFirma: state?.tabDetailData?.lockFirma}}/>}
            layoutFilter={()=><ProvozovnaFilterForm />}
            handlers={{handleSend: provozovnaCiselnikLogic.handleSend}}
            reloadData={true}
            tabs={tabs}
            onSuccess={provozovnaCiselnikLogic.onSuccess}
            saveResponseSetter={provozovnaCiselnikLogic.saveResponseSetter}
            isDetailReadOnly={isHistory}
            formDisabled={() => isHistory}
            tabDetailUrl={props.historyTab ? '/admin/historie/provozovny' : null }
				customButtons={(edited, data, changed, disabled, changedFields, origin) => {
                    if (data.pocetUzivatelu <= 1) { // Jednouzivatelska
                        return edited && [(<FormButton key={"sendTheFirstAccessDataSingle"}
                                                       skipBlock
                                                       type={"remove"}
                                                       confirmation={{showDialog: showConfirm, dialog: dialog}}
                                                       onSendWithConfirmation={() => {
                                                           return {
                                                               modifyUrl: (url: string) => `${url}/firstAccessData/${data.id}`,
                                                               skipValidation: true,
                                                               requestInit: {
                                                                   method: "POST", body: null
                                                               }
                                                           }
                                                       }}>
                            {t("Default.SendTheFirstAccessDataTitleSingle")}
                        </FormButton>)]
                    }

                    return edited && [(<FormButton key={"sendTheFirstAccessDataMulti"}
                                                   skipBlock
                                                   type={"remove"}
                                                   confirmation={{showDialog: showConfirm, dialog: dialog2024}}
                                                   onSendWithConfirmation={() => {
                                                       return {
                                                           modifyUrl: (url: string) => `${url}/firstAccessData2024/${data.id}`,
                                                           skipValidation: true,
                                                           requestInit: {
                                                               method: "POST", body: null
                                                           }
                                                       }
                                                   }}>
                        {t("Default.SendTheFirstAccessDataTitleMulti")}
                    </FormButton>)]
                }
				}
            />
        );
}
