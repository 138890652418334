import {Podstav, Provozovna, SmlouvaS, Stav} from "../../web/model/Provozovna";
import {Firma} from '../../web/model/Firma';
import {exist} from "../utils/Util";
import {LicenseType, Region} from '../../web/model/CommonTypes';
import moment from "moment";


export const ZKRACENE_INFORMACE_PODSTAVY = [Podstav.X3_A_DOST, Podstav.PLATBY, Podstav.POZASTAVEN_VLASTNI_ZADOST, Podstav.PODEZRELY, Podstav.PORUSENI_SMLOUVY, Podstav.ORIGINAL];

export function onCreateProvozovna(p: Provozovna, f: Firma) {
    if(f) {
        p.typ = Region.GLOBAL;
        p.typLicence = LicenseType.WITHKM;
        p.stav = Stav.POZASTAVIL;
        p.zkusebniDoba = true;
        p.enabled = false;
        if (f?.nezadouciZakaznik) {
            p.podstav = Podstav.PROBLEM;
        } else {
            p.podstav = Podstav.BEZ_PODSTAVU;
            p.omezeneInformace = true;
        }
    }
}

export function onProvozovnaChangeFirma(p: Provozovna) {
    if (exist(p.id)) {
        if (p.firma?.nezadouciZakaznik) {
            p.stav = Stav.POZASTAVIL;
            p.podstav = Podstav.PROBLEM;
        }
    } else {
        onCreateProvozovna(p, p.firma)
    }
}

export function onProvozovnaChangeStav(p: Provozovna) {
    p.datzmenstav = moment();
    p.zkraceneInformace = p.stav===Stav.POZASTAVIL && ZKRACENE_INFORMACE_PODSTAVY.includes(p.podstav);
    p.nezobrazovat = p.isNezobrazovatCondition();

    switch (p.stav) {
        case Stav.JEDE:
            p.podstav = Podstav.BEZ_PODSTAVU;
            p.enabled = true;
            p.pozastavena = false;
            break;
        case Stav.POZASTAVIL:
            p.enabled = false;
            break;
    }

    if(p.stav===Stav.JEDE && p.zkusebniDoba) {
        p.poznamka = (p.poznamka ? (p.poznamka.endsWith("\n") ? `${p.poznamka}` : `${p.poznamka} `) : '')
    }

    if(p.stav===Stav.JEDE && (!exist(p.smlouvaS) || p.smlouvaS === SmlouvaS.NONE)) {
        if(p.firma?.r1Stat==="SK") {
            p.smlouvaS=SmlouvaS.FONITRANOVA;
        } else {
            p.smlouvaS = SmlouvaS.RAALHKNOVA;
        }
    }
}
