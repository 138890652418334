import {Column} from "../../../../raal_components/grid/DataGrid.d";
import {PrejezdFilterZadani, VPList} from "../../../../model/PrepravaVozidlo";
import {TFunction} from "i18next";
import numeral from "numeral";
import {sizeFormat, sizeIntegerFormat, vahaConstraint} from "../_vp/PrepravaAVozidlaShared";
import {FormField, FormInputType, useFormData} from "../../../../raal_components/form/Form";
import {useTranslation} from "react-i18next";
import {Avatar, Grid} from "@material-ui/core";
import {FormNominatim} from "../../../../../common/component/form/FormNominatim";
import {Waypoint} from "../../../../model/Waypoint";
import * as React from "react";
import {useCallback} from "react";
import {FormNumberRange, FormNumberRangeOptions} from "../../../../../common/component/form/FormNumberRange";
import {brown, deepOrange, green} from "@material-ui/core/colors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as faIcon from "@fortawesome/free-solid-svg-icons";
import {FormSectionCard} from "../../../../../common/component/form/FormSectionCard";
import {useCiselnikSelect, useDateIsoSelect} from "../../../../raal_components/SelectOptions";
import {CiselnikTyp} from "../../../../model/Ciselnik";
import {njsSwitch} from "../_vp/VPFilter";
import {useKilometrovnikShared} from "./KilometrovnikShared";
import {useCiselnikValues} from "../../../../context/DataContext";
import {
    CISELNIK_DRUH,
    DEFAULT_ADVANCE_VALUE,
    DEFAULT_RANGE_VALUE,
    PARAM_ADVANCE_KEY
} from "../prohlizeni/PrepravyCiselnikView";
import {FormDateRangeSelect} from "../../../../raal_components/FormTwoDatesSelect";

const pFilterSharedOptions = {spacing: 1, numberProps: {format: '0,0.[00]', constraint: {precision:9, scale:5}}, valueMultiplier: 1000}

export function prepareCols(createColumns: () => Column<VPList<any>>[], admin: boolean, t: TFunction) {
    let addCols = createColumns() as any;
    addCols = [
        {
            title: t("Prejezdy.prejezdCelkem"),
            field: "prejezd",
            defaultSort: "asc",
            render: (data: any) => data.prejezd && numeral(data.prejezd / 1000).format(sizeIntegerFormat),
            filterProps:() => ({type:FormInputType.Custom, customComponent:FormNumberRange, name:"prejezdRange", customComponentOptions: {...pFilterSharedOptions, verticalSpace: 0}}),
        },
        {
            title: t("Prejezdy.prejezdOd"),
            field: "prejezdOd",
            render: (data: any) => data.prejezdOd && numeral(data.prejezdOd / 1000).format(sizeIntegerFormat),
            filterProps:() => ({type:FormInputType.Custom, customComponent:FormNumberRange, name:"prejezdOdRange", customComponentOptions: {...pFilterSharedOptions, verticalSpace: 0}}),
        },
        {
            title: t("Prejezdy.prejezdKam"),
            field: "prejezdKam",
            render: (data: any) => data.prejezdKam && numeral(data.prejezdKam / 1000).format(sizeIntegerFormat),
            filterProps:() => ({type:FormInputType.Custom, customComponent:FormNumberRange, name:"prejezdKamRange", customComponentOptions: {...pFilterSharedOptions, verticalSpace: 0}}),
        },
        ...addCols
    ]
    return addCols;
}

export function PrejezdFilterAdditionalPart() {
    const {t} = useTranslation();

    return <>
    <Grid item md={6} sm={12} xs={12}>
        <FormField<FormNumberRangeOptions> title={t("Prejezdy.radiusPrejezd")} name={"prejezdRange"} type={"Custom"}
                                           customComponent={FormNumberRange} customComponentOptions={pFilterSharedOptions}/>
    </Grid>
    <Grid item md={6} sm={12} xs={12}>
        <FormField<FormNumberRangeOptions> title={t("Prejezdy.radiusPrejezdOd")} name={"prejezdOdRange"} type={"Custom"}
                                           customComponent={FormNumberRange} customComponentOptions={pFilterSharedOptions}/>
    </Grid>
    <Grid item md={6} sm={12} xs={12}>
        <FormField<FormNumberRangeOptions> title={t("Prejezdy.radiusPrejezdKam")} name={"prejezdKamRange"} type={"Custom"}
                                           customComponent={FormNumberRange} customComponentOptions={pFilterSharedOptions}/>
    </Grid>
    </>;
}


export const usePrejezdyFilter = (filterData: PrejezdFilterZadani) => {
    const {KilometrovnikFilterDataItem, getNSJ} = useKilometrovnikShared();
    const {druhyJoined} = useCiselnikValues(CISELNIK_DRUH);
    const {t} = useTranslation();

    const getFilterData = () => {
        if (filterData) {
            const druhy = druhyJoined(filterData.druhyPrejezd);
            const nsj = getNSJ(filterData)
            return (<Grid container>
                {KilometrovnikFilterDataItem(t("Prejezdy.prejezdCelkem"), filterData.dPrejezd??'-')}
                {KilometrovnikFilterDataItem(t("Prejezdy.prejezdOd"), filterData.dPrejezdOd??'-')}
                {KilometrovnikFilterDataItem(t("Prejezdy.prejezdKam"), filterData.dPrejezdKam??'-')}
                {KilometrovnikFilterDataItem(t("Prejezdy.odkud"), filterData.prejezdOdWaypoint?.nazevMista??'')}
                {KilometrovnikFilterDataItem(t("Prejezdy.kam"), filterData.prejezdKamWaypoint?.nazevMista??'')}
                {KilometrovnikFilterDataItem(t('Preprava.nsj'), nsj??'-')}
                {KilometrovnikFilterDataItem(t("Preprava.delka"), filterData.delka??'-')}
                {KilometrovnikFilterDataItem(t("Preprava.vaha"), filterData.vaha??'-')}
                {KilometrovnikFilterDataItem(t("Preprava.druhy"), druhy?.length !== 0 ? druhy : '-')}
            </Grid>)
        }

        return null;
    }

    return {getFilterData};
}

export function PrejezdFilterPart() {
    const {t} = useTranslation();
    const prejezdFilter = useFormData<PrejezdFilterZadani>();
    const ciselnikSelectFilter = useCiselnikSelect(CiselnikTyp.R, {isClearable: true, isMulti: true});
    const compareRegion = useCallback((field: "odkud" | "kam") => {
        const odkud = prejezdFilter.prejezdOdWaypoint?.countryCode;
        const kam = prejezdFilter.prejezdKamWaypoint?.countryCode;
        return (field === "kam" && odkud === "CZ") || (field === "odkud" && kam === "CZ");
    }, [prejezdFilter]);
    const dateFromSelect = useDateIsoSelect({dateType: 'datOd', typAdvance: PARAM_ADVANCE_KEY, defaultAdvanceValue: DEFAULT_ADVANCE_VALUE})
    const dateSpacing = 0;

    return <>
        <Grid container spacing={2}>
            <FormSectionCard title={t("Prejezdy.trasa")} avatar={<Avatar style={{backgroundColor: green[500]}}><FontAwesomeIcon icon={faIcon.faRoute} size="sm"/></Avatar>}>
                <Grid item lg={6} sm={12} xs={12}>
                    <FormField required title={t("Prejezdy.odkud")} name={"prejezdOdWaypoint"} type={"Custom"}
                               customComponent={FormNominatim}
                               getValue={(data) => {
                                   return data.prejezdOdWaypoint?.nazevMista;
                               }}
                               setValue={(data, fieldName, value) => {
                                   data.prejezdOdWaypoint.nazevMista = value;
                               }}
                               customComponentOptions={{
                                   disableClearable: true,
                                   autofillValue: true,
                                   checkLicense: true,
                                   countryCodeForCompare: () => compareRegion("odkud"),
                                   onChange: (osmPlace: any) => {
                                       prejezdFilter.prejezdOdWaypoint = Waypoint.fromOsmPlace(osmPlace);
                                   }
                               }}
                    />
                </Grid>
                <Grid item lg={6} sm={12} xs={12}>
                    <FormField required title={t("Prejezdy.kam")} name={"prejezdKamWaypoint"} type={"Custom"}
                               customComponent={FormNominatim}
                               getValue={(data) => {
                                   return data.prejezdKamWaypoint?.nazevMista;
                               }}
                               setValue={(data, fieldName, value) => {
                                   data.prejezdKamWaypoint.nazevMista = value;
                               }}
                               customComponentOptions={{
                                   disableClearable: true,
                                   autofillValue: true,
                                   checkLicense: true,
                                   countryCodeForCompare: () => compareRegion("kam"),
                                   onChange: (osmPlace: any) => {
                                       prejezdFilter.prejezdKamWaypoint = Waypoint.fromOsmPlace(osmPlace);
                                   }
                               }}
                    />
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                    <FormField title={t("Prejezdy.radiusPrejezdOd")} name={"dPrejezdOd"} type={FormInputType.Number}/>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                    <FormField title={t("Prejezdy.radiusPrejezdKam")} name={"dPrejezdKam"} type={FormInputType.Number}/>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                    <FormField title={t("Prejezdy.radiusPrejezd")} name={"dPrejezd"} type={FormInputType.Number}/>
                </Grid>
            </FormSectionCard>
            <FormSectionCard title={t("Prejezdy.vozidlo")} avatar={<Avatar style={{backgroundColor: brown[500]}}><FontAwesomeIcon icon={faIcon.faTruck} size="sm"/></Avatar>}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <FormField title={t("Preprava.delka")} name={"delka"} type={FormInputType.Number} numberProps={{format:sizeFormat, constraint:vahaConstraint}}/>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <FormField title={t("Preprava.vaha")} name={"vaha"} type={FormInputType.Number} numberProps={{format:sizeFormat, constraint:vahaConstraint}}/>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <FormField title={t("Preprava.druhy")} name={"druhyPrejezd"} type={"select"} selectProps={ciselnikSelectFilter}/>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <FormField title={t("Preprava.naves")} name={"naves"} type={"checkbox"} onWillChange={(field, newValue) => {
                        if (newValue) njsSwitch('N', prejezdFilter);
                    }} forceUpdateWhenDataChanged={['souprava', 'jine']} />
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <FormField title={t("Preprava.souprava")} name={"souprava"} type={"checkbox"} onWillChange={(field, newValue) => {
                        if (newValue) njsSwitch('S', prejezdFilter)
                    }} forceUpdateWhenDataChanged={['naves', 'jine']} />
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <FormField title={t("Preprava.jine")} name={"jine"} type={"checkbox"} onWillChange={(field, newValue)=> {
                        if (newValue) njsSwitch('J', prejezdFilter)
                    }} forceUpdateWhenDataChanged={['souprava', 'naves']} />
                </Grid>
            </FormSectionCard>
            <FormSectionCard title={t("Prejezdy.datum")} avatar={<Avatar style={{backgroundColor: deepOrange[500]}}><FontAwesomeIcon icon={faIcon.faCalendar} size="sm"/></Avatar>}>
                <Grid item lg={12} sm={12} xs={12}>
                    <FormField name={"vpRange"} type={"Custom"}
                               customComponent={FormDateRangeSelect} customComponentOptions={{dateSpacing, dateFromSelect, typParametru: PARAM_ADVANCE_KEY, DEFAULT_RANGE_VALUE, flexDirection: "row"}}/>
                </Grid>
            </FormSectionCard>
            <FormSectionCard title={t("Prejezdy.info")} avatar={<Avatar style={{backgroundColor: brown[500]}}><FontAwesomeIcon icon={faIcon.faQuestionCircle} size="sm"/></Avatar>}>
                <span>{t('Kilometrovnik.Note')}</span>
            </FormSectionCard>
        </Grid>
    </>
}
