import {useTranslation} from "react-i18next";
import {useAppContext} from "../../../../context/AppContext";
import React, {useRef} from "react";
import {cenaFormat, inzerceAdminExportableFields, useInzercePdfLayout} from "../zadani/InzerceCiselnik";
import {cenaConstraint, getExportableProps} from "../_vp/PrepravaAVozidlaShared";
import {
    useCurrencySelectObj,
    useInzeratInvalDuvSelect,
    useUserBasicSelect
} from "../../../../raal_components/SelectOptions";
import {useLocalizeCiselnikValue} from "../../../../context/DataContext";
import {CiselnikTyp} from "../../../../model/Ciselnik";
import {DataGridExposed} from "../../../../raal_components/grid/DataGrid";
import {
    InvalidDuvodInzerat,
    InzeratFilterOznaceno,
    InzeratView,
    InzeratViewAdmin,
    InzeratViewArchiv,
    InzeratViewDetail
} from "../../../../model/Inzerat";
import {Dial} from "../../../../raal_components/dial/Dial";
import {FormInputType} from "../../../../raal_components/form/Form";
import {FormNumberRange} from "../../../../../common/component/form/FormNumberRange";
import {FormDateRange} from "../../../../../common/component/form/FormDateRange";
import {Column} from "../../../../raal_components/grid/DataGrid.d";
import {TabHelperNew} from "../../../../raal_components/controller/TabHelper";
import {InzerceViewCiselnikFilter, InzerceViewCiselnikProps} from "../prohlizeni/InzerceViewCiselnik";
import InzerceHistoryViewPart from "../prohlizeni/InzerceHistoryViewPart";
import {ComponentMode} from "../../../../routes";
import {InzerceCiselnikFormNew} from "../_inzerce/InzerceCiselnikForm";
import {InzeratViewDetailFormBasicPartNew} from "../_inzerce/InzerceCiselnikViewDetailFormBasicPart";
import {useClearZaznamOznaceni, useZaznamOznaceni, useZaznamOznaceniStyle, ZaznamOznaceniTyp} from "../Oznaceni";


export const InzerceCiselnikArchive = (props: InzerceViewCiselnikProps) => {
    const {t} = useTranslation();
    const {user} = useAppContext();
    const oznacenoFilter = useRef(false);
    const refreshFun = useRef(() => {});
    const rangeCenaSharedOptions = {numberProps: {format: cenaFormat, constraint: cenaConstraint}};
    const dateKey = "inzerat-archive-browse-date";
    const endpoint = 'user/inzerat-archiv'
    const locFun = useLocalizeCiselnikValue(CiselnikTyp.I);
    const invalDuvSelect = useInzeratInvalDuvSelect({isClearable: true});
    const dtGrid = useRef<DataGridExposed<InzeratViewAdmin, InzeratFilterOznaceno>>();
    const {pdfLayout} = useInzercePdfLayout(user);
    const currencySelectProps = useCurrencySelectObj({isClearable:true});
    const userSelectProps = useUserBasicSelect({isClearable:true});
    const zaznamOznaceni = useZaznamOznaceniStyle(ZaznamOznaceniTyp.ARCHIV_ZADANI_INZERCE);
    const clearZaznamOznaceni = useClearZaznamOznaceni(
        useZaznamOznaceni("user", ZaznamOznaceniTyp.ARCHIV_ZADANI_INZERCE),
        () => {dtGrid.current?.table()?.refresh()}
    );

    return <>
        <Dial<InzeratViewAdmin, InzeratFilterOznaceno, InzeratViewDetail>
            mode={props.mode}
            lastBrowsedDateKey={dateKey}
            logActivity
            gridRef={dtGrid}
            onGridMount={()=>{
                refreshFun.current = () => dtGrid.current?.table()?.refresh();
                oznacenoFilter.current = dtGrid.current.table().getFilter().filters.oznaceno;
            }}

            onFilterChanged={(data) => {
                oznacenoFilter.current =data?.oznaceno ?? false;
            }}
            filterClazz={InzeratFilterOznaceno}
            clazzDetail={InzeratViewDetail}
            config={{
                lockSupport: {enabled: false},
                overflowHidden: true,
                requiredColumns: ['popis'],
                alternativeButtons: [clearZaznamOznaceni],
                exportConfig: {
                    exportable: true,
                    endpoint: endpoint,
                    fileName: "export_archiv_inzerce",
                    translationPrefix: ['Inzerce', 'User', 'Preprava', 'Archive'],
                    formats: ["pdf", "xls", "csv", "xml"],
                    exportAllFormats: ["csv"],
                    pdfLayout: (data, fields, pageBreak, index, origin) => pdfLayout(data, fields, pageBreak, index, origin),
                    exportableProps: getExportableProps(inzerceAdminExportableFields,true),
                    excludedProps: [
                        {
                            type: ["csv", "xls"],
                            fields: ["id", "sekce"]
                        }
                    ],
                    extendedProps: [
                        {
                            type: ["csv", "xls"],
                            addExtendedProps: (data) => {
                                return {
                                    provozovna: data.uzivatel?.provozovna?.kod,
                                    ...{archived: data.deletedOn}
                                }
                            }
                        }
                    ],
                    formattedProps: [
                        {
                            type: ["csv", "xls", "pdf"],
                            field: "invalDuv",
                            format: (data) => {
                                return locFun("Enumerations.InvalidDuvodInzerat", InvalidDuvodInzerat, data)
                            }
                        },
                        {
                            type: ["csv", "xls", "pdf"],
                            field: "rychlyKontakt",
                            format: (data) => {
                                return data ? `${data.slice(0, 4)} ${data.slice(4)}` : null;
                            }
                        }
                    ]
                },
                options: {
                    rowStyle: (data: any) => {
                        if (data.klasifikace?.barva) {
                            return {backgroundColor: data.klasifikace?.barva}
                        }
                        const oznaceniStyle = zaznamOznaceni.getStyle(data);
                        if(oznaceniStyle) return oznaceniStyle;
                    }
                },
                clazz: InzeratViewAdmin,
                hideDefaultDummyAction: false,
                tableTitle: t("Dials.Advertising"),
                endpoint: endpoint,
                filtering: true,
                hideAddNewActions: true,
                defaultQueryParameters: props.filter,
                columns: [
                    {
                        title: t("Inzerce.AdText"),
                        field: 'popis',
                        filterProps:() => ({type:FormInputType.Text}),
                        cellStyle: {minWidth: 200},
                        headerStyle: {minWidth: 200}
                    },
                    {
                        title: t("Default.Telefon"),
                        field: 'rychlyKontakt',
                        filterProps: () => ({type: FormInputType.Text})
                    },
                    {
                        title:t('Default.Email'),
                        field:"email",
                        filterProps: () => ({type: FormInputType.Text}),
                        editProps:() => ({type: FormInputType.Text}),
                        sorting: false,
                    },
                    {
                        title: t("Inzerce.Price"),
                        field: 'cena',
                        render:(data:InzeratView) => data.cena,
                        filterProps:() => ({type:FormInputType.Custom, customComponent:FormNumberRange, name:"cenaRange", customComponentOptions:{...rangeCenaSharedOptions, verticalSpace: 0}}),
                        cellStyle: {textAlign:"right"}
                    },
                    {
                        title: t("Preprava.currency"),
                        field: "currency",
                        render:(data:InzeratView)=> data.currency?.name ?? '',
                        filterProps:() => ({type: FormInputType.Select, selectProps: currencySelectProps}),
                    },
					{
						title: t("Default.Jednotka"),
						field: 'jednotka',
						render:(data:InzeratView) => data.jednotka,
						filterProps: () => ({type: FormInputType.Text})
					},
                    {
                        title: t("User.Title"),
                        field: 'uzivatel',
                        render:(row:InzeratViewArchiv)=> row.uzivatel ? `${row.uzivatel?.login ?? ''} ${row?.uzivatel.jmeno ? `(${row.uzivatel.jmeno})` : ""}` : '',
                        filterProps:() => ({type: FormInputType.Select, selectProps: userSelectProps}),
                        cellStyle: {minWidth: 180},
                        headerStyle: {minWidth: 180}
                    },
                    {
                        title: t("Inzerce.Dispecer"),
                        field: 'dispecer',
                        render:(row:InzeratView)=> `${row.dispecer?.jmeno ?? ''}`,
                        filterProps:() => ({type: FormInputType.Text, name: "dispecerText"}),
                        cellStyle: {width: 200, maxWidth: 200},
                        headerStyle: {width: 200, maxWidth: 200}
                    },
                    {
                        title: t("PVI.Modified"),
                        field: 'modifiedOn',
                        render: (data: any) => data.modifiedOn?.format("L LT"),
                        filterProps:() => ({type:FormInputType.Custom, customComponent:FormDateRange, customComponentOptions:{timeFormat: false, verticalSpace: 0},  name: "modifiedOnRange"}),
                    },
                    {
                        title: t("PVI.InvalDuv"),
                        field: 'invalDuv',
                        render: (data: InzeratViewAdmin) =>  {return locFun("Enumerations.InvalidDuvodInzerat", InvalidDuvodInzerat, data.invalDuv)},
                        filterProps: () => ({type: FormInputType.Select, selectProps: invalDuvSelect}),
                        cellStyle: {minWidth: 180},
                        headerStyle: {minWidth: 180}
                    },
                    {
                        title: t("Archive.Archived"),
                        field: 'deletedOn',
                        defaultSort: "desc",
                        render:(data:InzeratViewAdmin) =>  data.deletedOn?.format("L LT") ?? "",
                        filterProps:() => ({type:FormInputType.Custom, customComponent:FormDateRange, customComponentOptions:{timeFormat: false, verticalSpace: 0},  name: 'deletedOnRange'}),
                    }
                ].filter(i => Boolean(i)) as Column<InzeratView>[]
            }}
            layoutDetail={()=><TabHelperNew<InzeratViewDetail> render={(data) =>
                <>
                    <InzeratViewDetailFormBasicPartNew data={data} archive/>
                </>}/>}
            crudConfig={{addEnabled:false, editEnabled:false, removeEnabled: false}}
            layoutFilter={() =><InzerceViewCiselnikFilter {...{lastBrowseDateDataKey: dateKey, archive: true, isArchiveOfOwnOffers: true}}/>}
            layoutForm={() => <InzerceCiselnikFormNew archiveZadani={true} />}
            isDetailReadOnly
            formDisabled={() => true}
            hideSaveButton
            hideNewButtonOnEdit
            tabs={[
                {
                    title: t("Inzerce.History"),
                    render: ()=><TabHelperNew<InzeratViewDetail> render={(data) => <InzerceHistoryViewPart inzerat={data} archive mode={ComponentMode.GridMode}/>}/>
                }
            ]}

        />
    </>
}
