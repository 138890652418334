import {CustomFieldComponentProps} from "./FormFieldInterface";
import {StandaloneField} from "./StandaloneField";
import React, {useEffect} from 'react';
import {FormInputType} from "../../../web/raal_components/form/Form";
import {Box, Grid} from "@material-ui/core";
import {FormRange, FormRangeOptions, useFormRange, useRangeFocusable} from "./FormRange";
import {NumberProps} from "./FormNumber";
import {useTranslation} from 'react-i18next';
import {useMountedEffect} from "../hooks/SharedHooks";
import {invoke} from "../../utils/Invoke";
import {exist, isNumber} from "../../utils/Util";

export type FormNumberRangeOptions = {
    numberProps?:NumberProps
    flexDirection?:"column"|"row"
    valueMultiplier?:number
    verticalSpace?:number
}
&FormRangeOptions<number>
export type NumberRange = FormRange<number>
export function FormNumberRange(props:CustomFieldComponentProps<NumberRange, FormNumberRangeOptions>) {
    const options = typeof props.options === 'function' ? props.options() : props.options;
    const [range, setRange, check, {maxTitle, minTitle, spacing}] = useFormRange<number>(props);
    const textFieldProps = {style:{maxWidth:'100%'}};
    const [focused, onFieldFocused] = useRangeFocusable(props);
    const {t} = useTranslation();
    useMountedEffect(() => {
        props.onValueChanged(range);
    }, [range]);

    useEffect(() => {
        setRange({min: props.value?.min, max: props.value?.max})
        // eslint-disable-next-line
    }, [ props.value?.min, props.value?.max])

    return (
        <>
            <Grid item lg={12} sm={12} xs={12}>
                <Box mr={spacing} style={{width:'100%'}}>
                    <StandaloneField focused={focused===1}
                                    numberProps={options?.numberProps}
                                    onBlur={()=>{
                                        check();
                                        invoke(props.onBlur)
                                    }}
                                    variant={props.variant ?? "outlined"}
                                    onKeyDown={props.onKeyDown}
                                    value={(options.valueMultiplier && range?.min) ? range.min / options.valueMultiplier : range?.min}
                                    type={FormInputType.Number}
                                    showAdornment={props.showAdornment}
                                    inputClassName={props.inputClassName}
                                    onValueChanged={value => {
                                        if(!exist(options.valueMultiplier) || (options.valueMultiplier && (isNumber(value) || !exist(value)))) {
                                            setRange({
                                                ...range,
                                                min: (options.valueMultiplier && value) ? value * options.valueMultiplier : value
                                            });
                                        }
                                    }}
                                    title={minTitle}
                                    placeholder={"min"}
                                    /* dataTip={t("MuiInput.MinTip")} dočasně zakomentováno na přání zákazníka #4147 */
                                    textFieldProps={textFieldProps}
                                    onFocus={(o, userFocus) => {
                                        if(userFocus) {
                                            onFieldFocused(1);
                                        }
                                    }}
                    />
                </Box>
            </Grid>
            <div style={{height: options?.verticalSpace ?? 8}}/>
            <Grid item lg={12} sm={12} xs={12}>
                <Box style={{width:'100%'}}>
                    <StandaloneField focused={focused===2}
                                    numberProps={options?.numberProps}
                                    onBlur={()=>{
                                        check();
                                        invoke(props.onBlur)
                                    }}
                                    variant={props.variant ?? "outlined"}
                                    onKeyDown={props.onKeyDown}
                                    value={(options.valueMultiplier && range?.max) ? range.max / options.valueMultiplier : range?.max}
                                    type={FormInputType.Number}
                                    showAdornment={props.showAdornment}
                                    inputClassName={props.inputClassName}
                                    onValueChanged={value => {
                                        if(!exist(options.valueMultiplier) || (options.valueMultiplier && (isNumber(value) || !exist(value)))) {
                                            setRange({
                                                ...range,
                                                max: (options.valueMultiplier && value) ? value * options.valueMultiplier : value
                                            });
                                        }
                                    }}
                                    title={maxTitle}
                                    placeholder={"max"}
                                    dataTip={t("MuiInput.MaxTip")}
                                    textFieldProps={textFieldProps}
                                    onFocus={(o, userFocus) => {
                                        if(userFocus) {
                                            onFieldFocused(2);
                                        }
                                    }}
                    />
                </Box>
            </Grid>
        </>
    );
}
