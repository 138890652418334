import {CustomFieldComponentProps} from "./FormFieldInterface";
import {StandaloneField} from "./StandaloneField";
import React, {useEffect, useState} from 'react';
import {FormInputType} from "../../../web/raal_components/form/Form";
import {Box, Grid} from "@material-ui/core";
import moment from "moment";
import {FormRange, FormRangeOptions, useFormRange, useRangeFocusable} from "./FormRange";
import {CustomDateTimePickerOptions} from "./FormDatetime";
import {useMountedEffect} from "../hooks/SharedHooks";

export type FormDateRangeOptions = {
        flexDirection?:"column"|"row"
        verticalSpace?:number
}
&FormRangeOptions<string>
&Pick<CustomDateTimePickerOptions, "dateFormat" | "timeFormat">
export function useDatesErrors<P, O>(props:CustomFieldComponentProps<P, O>) {
    const [errors, setErrors] = useState<{datOd?:string, datDo?:string}>({});
    useEffect(()=>{
        const errors = props.allErrors?.filter(f=>f.name === "datOd" || f.name === "datDo");
        const newErrors:{datOd?:string, datDo?:string} = {datOd:undefined, datDo:undefined};
        if((errors?.length ?? 0) > 0) {
            const datOd = errors.filter(f=>f.name === "datOd");
            newErrors.datOd = datOd.length > 0 ? datOd.map(f=>f.message).join(",") : undefined;

            const datDo = errors.filter(f=>f.name === "datDo");
            newErrors.datDo = datDo.length > 0 ? datDo.map(f=>f.message).join(",") : undefined;
            if(props.dataHolder && props.dataHolder.current) {
                props.dataHolder.current.hasError = typeof newErrors.datOd !== "undefined" || typeof newErrors.datDo !== "undefined"
            }
        }
        setErrors(newErrors);
    }, [props.allErrors, props.dataHolder]);
    return [errors];
}
export type DateRange = FormRange<string>
export function FormDateRange(props:CustomFieldComponentProps<DateRange, FormDateRangeOptions>) {
    const [range, setRange, check, {maxWidth, maxTitle, minTitle, spacing}] = useFormRange<string>(props);
    const [focused] = useRangeFocusable(props);
    const textFieldProps = {style:{maxWidth:maxWidth}};
    const [errors] = useDatesErrors(props);
    useMountedEffect(() => {
        if(!range.min && !range.max) {
            props.onValueChanged(null);
        } else {
            props.onValueChanged(range);
        }
    }, [range]);
    const formatMoment = (value:string) => {
        const m = moment(value);
        if(m.isValid()) {
            return m.toISOStringWithMillis();
        }
        return null;
    };

    const format = (d:string) => {
        const m = moment(d);
        if(m.isValid()) {
            return m;
        }
        return null;
    };

    return (
        <>
            <Grid item lg={12} sm={12} xs={12}>
                <Box mr={spacing} style={{width:'100%'}}>
                    <StandaloneField inputClassName={props.inputClassName} onInteractStart={props.onInteractStart} onDisableActionKeys={props.onDisableActionKeys} onInteractEnd={props.onInteractEnd} error={errors?.datOd} focused={focused===1}  onBlur={check} variant={props.variant ?? "outlined"} dateTimeOptions={{timeFormat:(typeof props.options === 'function' ? props.options() : props.options)?.timeFormat, dateFormat:(typeof props.options === 'function' ? props.options() : props.options)?.dateFormat}} onKeyDown={props.onKeyDown} value={format(range.min)} type={FormInputType.DateTime} showAdornment={props.showAdornment} onValueChanged={value => setRange({...range, min:formatMoment(value)})} title={minTitle} textFieldProps={textFieldProps} />
                </Box>
            </Grid>
            <div style={{height: (typeof props.options === 'function' ? props.options() : props.options)?.verticalSpace ?? 8}}/>
            <Grid item lg={12} sm={12} xs={12}>
                <Box style={{width:'100%'}}>
                    <StandaloneField inputClassName={props.inputClassName} onInteractStart={props.onInteractStart} onDisableActionKeys={props.onDisableActionKeys} onInteractEnd={props.onInteractEnd} error={errors?.datDo} focused={focused===2} onBlur={check} variant={props.variant ?? "outlined"} dateTimeOptions={{timeFormat:(typeof props.options === 'function' ? props.options() : props.options)?.timeFormat, dateFormat:(typeof props.options === 'function' ? props.options() : props.options)?.dateFormat}} onKeyDown={props.onKeyDown} value={format(range.max)} type={FormInputType.DateTime} showAdornment={props.showAdornment} onValueChanged={value => setRange({...range, max:formatMoment(value)})}  title={maxTitle} textFieldProps={textFieldProps} />
                </Box>
            </Grid>
        </>
    );
}
