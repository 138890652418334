import React, { ReactElement } from 'react';
import {Avatar, createStyles, Grid, ListItemAvatar, ListItemText, Theme, useMediaQuery} from "@material-ui/core";
import {SpeedDial, SpeedDialAction} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";
import {invoke} from "../utils/Invoke";
import {DialerSip, Drafts, Email} from '@material-ui/icons';
import {useTranslation} from 'react-i18next';
import { Vozidlo } from '../../web/model/Vozidlo';
import { Preprava } from '../../web/model/Preprava';
import { useCodeBookDetailContextNew } from '../../web/raal_components/controller/CodeBookDetail';
import { exist, formatPrice, isNotBlank } from '../utils/Util';
import numeral from 'numeral';
import { sizeIntegerFormat, sizeViewFormat } from '../../web/page/Dials/user/_vp/PrepravaAVozidlaShared';
import { useCiselnikValues } from '../../web/context/DataContext';
import { CiselnikTyp } from '../../web/model/Ciselnik';
import { InzeratViewDetail } from '../../web/model/Inzerat';
import { useAppContext } from '../../web/context/AppContext';
import { useTranslationByLangAndParam } from '../../web/raal_components/LanguageUtil';
import {VPAbstract} from "../../web/model/PrepravaVozidlo";
import {useLocation} from "react-router";
import {httpEndpointCustom} from "../utils/HttpUtils";

const useStylesIcon = makeStyles((theme:Theme) =>
    createStyles({
        phoneNumberDial: {
            '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
                left: theme.spacing(0)
            },
            '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
                left: theme.spacing(0)
            }
        },
        phoneNumberDialRelative: {
            position: "relative"
        },
        actions: {
            position: 'absolute',
            top: "-3.0rem",
            left: "2rem",
            zIndex: 100
        },
        actionsMobile: {
            flexWrap: "wrap",
            width: "10rem",
            position: 'absolute',
            top: "-4rem",
            left: "2rem",
            zIndex: 100
        },
        fab: {
            backgroundColor: "#2eac00",
            '&:hover': {
                backgroundColor: "#2eac00"
            },
            width: "2.5rem",
            height: "2.5rem",
        },
        root: {
            zIndex: 1
        },
        phoneItem: {
            width: "3.5rem",
            position: "relative"
        },
        phoneNumberContainer: {
            position: "relative",
            textTransform: "none"
        },
        viber: {
            textDecoration: "none !important",
            color: "rgba(0, 0, 0, 0.54) !important",
            width: 24,
            height: 24,
            zIndex: 50
        },
        iconColor: {
            color: theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50]
        }
    }),
);

const useStylesText = makeStyles((theme) =>
    createStyles({
        fab: {
            color: "#1976d2",
            textDecoration: "underline",
            width: "auto",
            height: "auto",
            backgroundColor: "transparent",
            '&:hover': {
                backgroundColor: "transparent",
                color: "#1976d2",
                textDecoration: "underline",
                border: 0,
                boxShadow: "none"
            },
            '&:active': {
                backgroundColor: "transparent",
                color: "#1976d2",
                textDecoration: "underline",
                border: 0,
                boxShadow: "none"
            },
            border: 0,
            boxShadow: "none",
            zIndex: 5,
            transition: "none !important"
        },
        actions: {
            position: 'absolute',
            left: "5.5rem",
            zIndex: 10
        },
        phoneItem: {
            position: "relative"
        },
        iconColor: {
            color: theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50]
        }
    }),
);

type Button = {
    text: string,
    icon: ReactElement,
    call: () => void
}

type CurrentPage = 'PREPRAVY' | 'VOLNEVOZY' | 'INZERCE' | '';

export const getLanguageCode = (pStat: string | null) => {
    switch (pStat) {
        case 'A':
        case 'D':
            return 'de';
        case 'SK':
            return 'sk';
        case 'CZ':
            return 'cs';
        case 'PL':
            return 'pl';
        default:
            return 'en';
    }
};

export const EmailClient = ({emailAddress = '-', showDials = true, hideEmail = false, hideIcon = false}:{emailAddress?:string, showDials?: boolean, hideEmail?: boolean, hideIcon?: boolean}) => {
    const {t} = useTranslation();
	const { t2 } = useTranslationByLangAndParam();
	const { user } = useAppContext();
    const classes = useStylesIcon();
    const classesText = useStylesText();
    const [open, setOpen] = React.useState(false);
	const {druhyJoined} = useCiselnikValues(CiselnikTyp.R);
	const codeBookDetailContext = useCodeBookDetailContextNew<Preprava | Vozidlo>();
	const data = codeBookDetailContext ? codeBookDetailContext.data : null;

    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));

    const getPrice = () => {
        const price = data.cena && data.currency?.currencyCode ? formatPrice(data.cena, data.currency.currencyCode) : data.cena ?? null;
        return data.jednotka ? price + "/" + data.jednotka : price
	}

	 const getMailTo = () => {
		if (exist(data) && (data instanceof InzeratViewDetail || data instanceof VPAbstract)) {
            let subject = '';
            let body = '';
            const lang = getLanguageCode(data?.uzivatel?.provozovna?.pStat);
			if (data instanceof InzeratViewDetail) {
				let inzeratData: InzeratViewDetail = data as InzeratViewDetail;
				subject = `${t2("Mail.InzeratSubject", lang, [user.provozovna.kod + "/" + inzeratData.uzivatel.provozovna.kod])}`;
				let bodyBegin = `${t2("Mail.InzeratBodyBegin", lang, [user.provozovna.kod])}`;
				body = `
${bodyBegin}

${t2("Inzerce.AdText", lang)}: ${inzeratData.popis ? inzeratData.popis : '-'}
${t2("Inzerce.Price", lang)}: ${getPrice() ? getPrice() : '-'}
${t2("PVI.Modified", lang)}: ${inzeratData.modifiedOn ? inzeratData.modifiedOn?.format("L LT") : '-'}
`.trim();
			} else {
				let bodyBegin = '';
				if (data instanceof Preprava) {
					subject = `${t2("Mail.PrepravaSubject", lang, [(user.provozovna?.kod ?? '-') + "/" + (data.uzivatel?.provozovna?.kod ?? '-')])}`;
					bodyBegin = `${t2("Mail.PrepravaBodyBegin", lang, [user.provozovna?.kod ?? '-'])}`;
				}
				if (data instanceof Vozidlo) {
					subject = `${t2("Mail.VozidloSubject", lang, [(user.provozovna?.kod ?? '-') + "/" + (data.uzivatel?.provozovna?.kod ?? '-')])}`;
					bodyBegin = `${t2("Mail.VozidloBodyBegin", lang, [user.provozovna?.kod ?? '-'])}`;
				}

				body = `
${bodyBegin}

${t2("Mail.KodZadavateleNabidky", lang)}: ${data.uzivatel?.provozovna?.kod}
${t2("Preprava.odkud", lang)}: ${data.regOd}:${data.pscOdkud}:${data.odkud}
${t2("Preprava.kam", lang)}: ${data.regKam}:${data.pscKam}:${data.kam}
${t2("RAALKratkyKody.N", lang)} / ${t2("RAALKratkyKody.S", lang)} / ${t2("RAALKratkyKody.J", lang)}: ${data.naves ? t2("YesNo.true", lang) : t2("YesNo.false", lang)} / ${data.souprava ? t2("YesNo.true", lang) : t2("YesNo.false", lang)} / ${data.jine ? t2("YesNo.true", lang) : t2("YesNo.false", lang)}
${t2("Preprava.delka", lang)}: ${data.delka ? numeral(data.delka).format(sizeViewFormat) : '-'}
${t2("Preprava.vaha", lang)}: ${data.vaha ? numeral(data.vaha).format(sizeViewFormat) : '-'}
${t2("Preprava.druhy", lang)}: ${data.druhy && data.druhy.length > 0 ? druhyJoined(data.druhy) : '-'}
${t2("Preprava.datOd", lang)}: ${data.datOd?.format("L LT")}
${t2("Preprava.datDo", lang)}: ${data.datDo?.format("L LT")}
${t2("Preprava.cena", lang)}: ${getPrice() ? getPrice() : '-'}
${t2("Preprava.verejnaPozn", lang)}: ${data.verejnaPozn ? data.verejnaPozn : '-'}
`.trim();
			}
			const encodedSubject = encodeURIComponent(subject);
			const encodedBody = encodeURIComponent(body);

			return `mailto:${emailAddress}?subject=${encodedSubject}&body=${encodedBody}`;
		} else {
			return `mailto:${emailAddress}`;
		}
	}
    const mailTo = getMailTo();

    const buttons: Button[] = [
        {text: t("Contacts.SendEmail"), icon: <Drafts />, call: () => {
            window.location.href = mailTo;
        }},
        {text: t("Contacts.SipEmail"), icon: <DialerSip />, call: () => {
            window.location.href = "sip:" + emailAddress
        }},
        ];

    return (
        <Grid container justifyContent={"flex-start"} alignItems={"center"}>
            <Grid item>
                <Grid container justifyContent={"flex-start"} alignItems={"center"} className={classesText.phoneItem}>
                    {!hideIcon && <ListItemAvatar><Avatar className={"avatar-contact"}><Email/></Avatar></ListItemAvatar>}
                    <SpeedDial
                        ariaLabel="email"
                        className={showDials ? classes.phoneNumberDial : classes.phoneNumberDialRelative}
                        classes={{
                            root: classes.root,
                            actions: isDesktop || hideIcon ? hideIcon ? classesText.actions : classes.actions : classes.actionsMobile,
                            fab: classesText.fab
                        }}
                        icon={
                            <Grid item>
                                <Grid container alignItems={"center"} className={classes.phoneNumberContainer}>
                                    {!hideIcon && <ListItemText>
                                        {showDials ? hideEmail ? '' :
                                            <a style={{userSelect: "text"}} href={mailTo}>{emailAddress} </a> : '-'}
                                    </ListItemText>}
                                    {hideIcon &&
                                        <span>
											{showDials ? hideEmail ? '' : <a style={{userSelect: "text"}} href={mailTo}>{emailAddress}</a> : '-'}
										</span>
                                    }
                                </Grid>
                            </Grid>
                        }
                        onClose={handleClose}
                        onOpen={(_, reason) => {
                            if (reason === 'focus') {
                                return
                            }
                            setOpen(true)
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            handleOpen();
                            e.stopPropagation();
                        }}
                        open={open}
                        FabProps={{size:"small", variant:"circular", className: classes.fab}}
                        direction={"right"}>
                        { showDials && buttons.map((btn, index) => (
                            <SpeedDialAction
                                title={btn.text}
                                key={index}
                                icon={btn.icon}
                                onClick={(e)=>{
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleClose();
                                    invoke(btn.call)
                                }}
                            />
                        ))}
                    </SpeedDial>
                </Grid>
            </Grid>
        </Grid>
    )
}

/**
 * EmailClient komponenta pre bunky v tabulke ktora podla kodu provozovny asynchronne fetchne jej jazyk a posklada predmet a telo emailu
 * @param emailAddress
 * @param rowData
 */

export const InlineEmailClient = ({emailAddress = '-', showDials = true, hideIcon = false, rowData}: {
    emailAddress?: string,
    showDials?: boolean,
    hideIcon?: boolean,
    rowData: any
}) => {
    const {t} = useTranslation();
    const {t2} = useTranslationByLangAndParam();
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    const classes = useStylesIcon();
    const classesText = useStylesText();
    const {druhyJoined} = useCiselnikValues(CiselnikTyp.R);
    const {user} = useAppContext();
    const [open, setOpen] = React.useState(false);
    const {pathname} = useLocation();
    const currentPage: CurrentPage = pathname.includes('/prepravy') ? 'PREPRAVY' : pathname.includes('/volnevozy') ? 'VOLNEVOZY' : pathname.includes('/inzerce') ? 'INZERCE' : '';

    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    const getPrice = () => {
        const price = rowData.cena && rowData.currency?.currencyCode ? formatPrice(rowData.cena, rowData.currency.currencyCode) : rowData.cena ?? null;
        return rowData.jednotka ? price + "/" + rowData.jednotka : price;
    }

    const getMailTo = async () => {
        let subject = '';
        let body = '';
        let lang = 'DEFAULT'

        const provozovnaLangResponse = await httpEndpointCustom(`user/provozovnainfo/getstat?kod=${rowData.provozovna}`)

        if (provozovnaLangResponse.response.ok) {
            lang = getLanguageCode(provozovnaLangResponse.json.pstat);
        }

        if (currentPage === "INZERCE") {
            subject = `${t2("Mail.InzeratSubject", lang, [user.provozovna.kod + "/" + rowData.provozovna])}`;
            let bodyBegin = `${t2("Mail.InzeratBodyBegin", lang, [user.provozovna.kod])}`;
            body = `
${bodyBegin}
                        
${t2("Inzerce.AdText", lang)}: ${rowData.popis ? rowData.popis : '-'}
${t2("Inzerce.Price", lang)}: ${getPrice() ? getPrice() : '-'}
${t2("PVI.Modified", lang)}: ${rowData.modifiedOn ? rowData.modifiedOn?.format("L LT") : '-'}
`.trim();
        } else {
            let bodyBegin = '';
            if (currentPage == "PREPRAVY") {
                subject = `${t2("Mail.PrepravaSubject", lang, [(user.provozovna?.kod ?? '-') + "/" + (rowData.provozovna ?? '-')])}`;
                bodyBegin = `${t2("Mail.PrepravaBodyBegin", lang, [user.provozovna?.kod ?? '-'])}`;
            }
            if (currentPage == "VOLNEVOZY") {
                subject = `${t2("Mail.VozidloSubject", lang, [(user.provozovna?.kod ?? '-') + "/" + (rowData.provozovna ?? '-')])}`;
                bodyBegin = `${t2("Mail.VozidloBodyBegin", lang, [user.provozovna?.kod ?? '-'])}`;
            }

            body = `
${bodyBegin}
                        
${t2("Mail.KodZadavateleNabidky", lang)}: ${rowData.provozovna}
${t2("Preprava.odkud", lang)}: ${rowData.regOd}:${rowData.pscOdkud}:${rowData.odkud}
${t2("Preprava.kam", lang)}: ${rowData.regKam}:${rowData.pscKam}:${rowData.kam}
${t2("RAALKratkyKody.N", lang)} / ${t2("RAALKratkyKody.S", lang)} / ${t2("RAALKratkyKody.J", lang)}: ${rowData.naves ? t2("YesNo.true", lang) : t2("YesNo.false", lang)} / ${rowData.souprava ? t2("YesNo.true", lang) : t2("YesNo.false", lang)} / ${rowData.jine ? t2("YesNo.true", lang) : t2("YesNo.false", lang)}
${t2("Preprava.delka", lang)}: ${rowData.delka ? numeral(rowData.delka).format(sizeViewFormat) : '-'}
${t2("Preprava.vaha", lang)}: ${rowData.vaha ? numeral(rowData.vaha).format(sizeViewFormat) : '-'}
${t2("Preprava.druhy", lang)}: ${rowData.druhy && rowData.druhy.length > 0 ? druhyJoined(rowData.druhy) : '-'}
${t2("Preprava.datOd", lang)}: ${rowData.datOd?.format("L LT")}
${t2("Preprava.datDo", lang)}: ${rowData.datDo?.format("L LT")}
${t2("Preprava.cena", lang)}: ${getPrice() ? getPrice() : '-'}
${t2("Preprava.verejnaPozn", lang)}: ${rowData.verejnaPozn ? rowData.verejnaPozn : '-'}
                    `.trim();
        }

        const encodedSubject = encodeURIComponent(subject);
        const encodedBody = encodeURIComponent(body);

        window.location.href = `mailto:${emailAddress}?subject=${encodedSubject}&body=${encodedBody}`;

        return `mailto:${emailAddress}?subject=${encodedSubject}&body=${encodedBody}`;
    }

    const buttons: Button[] = [{
        text: t("Contacts.SendEmail"), icon: <Drafts/>, call: async () => {
            await getMailTo();
        }
    }, {
        text: t("Contacts.SipEmail"), icon: <DialerSip/>, call: () => {
            window.location.href = "sip:" + emailAddress
        }
    },];

    return (<Grid>
            <Grid item>
                <Grid className={classesText.phoneItem}>
                    {!hideIcon &&
                        <ListItemAvatar><Avatar className={"avatar-contact"}><Email/></Avatar></ListItemAvatar>}
                    <SpeedDial
                        ariaLabel="email"
                        className={showDials ? classes.phoneNumberDial : classes.phoneNumberDialRelative}
                        classes={{
                            root: classes.root,
                            actions: isDesktop || hideIcon ? hideIcon ? classesText.actions : classes.actions : classes.actionsMobile,
                            fab: classesText.fab
                        }}
                        icon={<Grid item>
                            <Grid className={classes.phoneNumberContainer}>
                                <span>{emailAddress}</span>
                            </Grid>
                        </Grid>}
                        onClose={handleClose}
                        onOpen={(_, reason) => {
                            if (reason === 'focus') {
                                return
                            }
                            setOpen(true)
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            handleOpen();
                            e.stopPropagation();
                        }}
                        open={open}
                        FabProps={{size: "small", variant: "circular", className: classes.fab}}
                        direction={"right"}>
                        {showDials && buttons.map((btn, index) => (<SpeedDialAction
                                title={btn.text}
                                key={index}
                                icon={btn.icon}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleClose();
                                    invoke(btn.call)
                                }}
                            />))}
                    </SpeedDial>
                </Grid>
            </Grid>
        </Grid>)
}