import {JsonProperty} from "../../common/utils/objectmapper/Mapper";
import {UserSimple} from "./UserSimple";
import {Moment} from "moment";
import {MomentConverters} from "./Converters";
import {BasicUserData, LicenseType, Region} from "./CommonTypes";
import {DateRange} from "../../common/component/form/FormDateRange";
import {exist} from "../../common/utils/Util";

export enum UserRole {
    ROLE_ADMIN = "ROLE_ADMIN",
    ROLE_MASTER = "ROLE_MASTER",
    ROLE_USER = "ROLE_USER"
}

export enum UserRoleFilter {
    ROLE_ADMIN = "ROLE_ADMIN",
    ROLE_MASTER = "ROLE_MASTER",
    ROLE_USER = "ROLE_USER",
    ROLE_NONE = "ROLE_NONE"
}
export class User extends UserSimple {
    enabled?: boolean;

    poslIpUser?: string;

    @JsonProperty({converters: MomentConverters})
    poslPripojUser?: Moment;

    pocPripojUser?: number;

    @JsonProperty({type:{enum: Region}})
    region: Region;

    @JsonProperty({type:{enum:LicenseType}})
    typLicence: LicenseType;

    @JsonProperty({type:{isArray:true}})
    phoneNumbers: string[];

    @JsonProperty({converters: MomentConverters})
    datCas?: Moment;

    @JsonProperty({type:{clazz:BasicUserData}})
    kdoZmenil?: BasicUserData;

    //historie
    userId?: number;

    dispecer?: boolean;

    canShowKmFeature(): boolean {
        return (this.roles ?? []).indexOf(UserRole.ROLE_ADMIN)>-1 || this.typLicence===LicenseType.WITHKM
    }

    isDispecer(): boolean {
        return (this.roles?.indexOf(UserRole.ROLE_USER) ?? - 1) > -1 && exist(this.jmeno) && ((exist(this.phoneNumbers) ? this.phoneNumbers.length : 0) + (exist(this.email) ? 1 : 0)) >= 2;
    }

    isUser() : boolean {
        return this.roles?.indexOf(UserRole.ROLE_USER) > -1
    }

	signDocumentation?: boolean;

	countOfUsersWithUserRole?: number;

	lastAction?: string;

	fcmToken?: string;

    // Property ktora urcuje ci provozovna uzivatela je dvojclenna (master + 1) alebo viacclenna
    jednouzivatelskaLicence?: boolean;
}

export class UserFilter {
    login: string;
    jmeno: string;
    email: string;
    @JsonProperty({type:{enum: Region}})
    region: Region;
    @JsonProperty({type:{enum:LicenseType}})
    typLicence: LicenseType;
    phoneNumbers: string;
    enabled?: boolean;
    poznamka: string;
    dispecer: boolean;
    provozovna: string;
    datCasRange: DateRange;
    @JsonProperty({type:{enum: UserRole}})
    role: UserRole;
}

export class UserBasic {
    id:number;
    login:string;
    jmeno:string;
    email:string;

    displayName() {
        return `${this.login ?? ''} ${this.jmeno ? `(${this.jmeno})` : ""}`
    }
}

export class UserBasicProvozovnaKod extends UserBasic{
    provozovna: string
}
