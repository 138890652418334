import {Moment} from "moment";
import {JsonProperty} from "../../common/utils/objectmapper/Mapper";
import {MomentConverters} from "./Converters";
import {Provozovna} from "./Provozovna";
import {TelCislo} from "./CommonTypes";

export class DispecerListAdmin {

    id: string;

    jmeno: string;

    email: string;

    @JsonProperty({converters: MomentConverters})
    datCas: Moment;

    @JsonProperty({type:{clazz:Provozovna}})
    provozovna: Provozovna;

    @JsonProperty({type:{isArray:true, clazz:TelCislo}})
    telCisloDispecera?: TelCislo[]
}


export class Dispecer {

    id: string;

    jmeno: string;

    email: string;

    @JsonProperty({converters: MomentConverters})
    datCas: Moment;

    @JsonProperty({type:{clazz:Provozovna}})
    provozovna: Provozovna;

    @JsonProperty({type:{isArray:true}})
    phoneNumbers?: string[];

    enabled?: boolean;

    formatDisplayName() {
       return formatDispecer(this);
    }
}

export class UserDispecer {

    id: string;

    jmeno: string;

    email: string;

    @JsonProperty({converters: MomentConverters})
    datCas: Moment;

    @JsonProperty({type:{isArray:true}})
    phoneNumbers: string[]
    formatDisplayName(): string {
        return formatDispecer(this);
    }
}

export class UserViewDispecer {

    id: string;

    jmeno: string;

    email: string;

    @JsonProperty({type:{isArray:true}})
    phoneNumbers: string[]
    formatDisplayName() {
        return formatDispecer(this);
    }
}


export function formatDispecer(dispecer:Dispecer|UserViewDispecer) {
    return `${[dispecer?.jmeno ?? dispecer.id, (()=>{
        const additionalData = [...dispecer.phoneNumbers??[], dispecer.email].filter(a=>a);
        if(additionalData.length === 0) {
            return undefined;
        }
        return `(${additionalData.join(", ")})`
    })()].filter(a=>a).join(" ")}`;
}