import {
    useCodeBookDetailContext,
    useCodeBookDetailContextNew
} from "../../../../raal_components/controller/CodeBookDetail";
import {Inzerat} from "../../../../model/Inzerat";
import {useTranslation} from "react-i18next";
import {
    createProvozovnaEmailyContactSelect,
    useCurrencySelectObj,
    useDispecerForInputFormsSelect,
    useRychlyContactSelect
} from "../../../../raal_components/SelectOptions";
import {useAppContext} from "../../../../context/AppContext";
import {Box, Grid, Typography} from "@material-ui/core";
import {OfferArchiveActionsPart} from "../prohlizeni/OfferArchiveActionsPart";
import {FormField} from "../../../../raal_components/form/Form";
import {cenaConstraint} from "../_vp/PrepravaAVozidlaShared";
import {exist} from "../../../../../common/utils/Util";
import {FieldError} from "../../../../../common/component/form/ValidationError";
import React from "react";
import {cenaFormat} from "../zadani/InzerceCiselnik";
import { priceUnits } from '../../../../model/CommonTypes';

/**
 * TODO stejná logika jako New, nutné nahradit všude novou novou
 * @constructor
 */
export function InzerceCiselnikForm({archiveZadani}:{archiveZadani?: boolean}) {
    const {data, edited} = useCodeBookDetailContext<Inzerat>();
    const {t} = useTranslation();
    const dispecerSelectProps = useDispecerForInputFormsSelect({isClearable: true, params: {uzivatelId: data.uzivatel?.id}});
    const currencySelectProps = useCurrencySelectObj({isClearable: true});
    const {user} = useAppContext();
    const rychlyKontaktSelect = useRychlyContactSelect({isClearable: true, params: {provozovnaId: user.provozovna.id}, noDynamicFontSize: true, autoOpenDisabled: true, inputType: "phone"});
    return (
        <Grid container spacing={2}>
            {
                archiveZadani ? <Grid container item spacing={2} alignItems="stretch" direction="row" style={{paddingBottom:8}}>
                    <Grid item xs>
                        <OfferArchiveActionsPart data={data}/>
                    </Grid>
                </Grid> : undefined
            }
            {edited &&
                <Grid container spacing={2} justifyContent={'flex-end'} style={{marginBottom: 10, marginRight: 0}}>
                    <Grid item>
                        <Typography variant="caption" className="bold">{t("PVI.Creator")}:</Typography><Typography variant="caption"> {`${data.uzivatel?.login ?? ''} ${data.uzivatel?.jmeno ? `(${data.uzivatel?.jmeno})` : ""}`}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption" className="bold">{t("PVI.CreatedOn")}:</Typography><Typography variant="caption"> {data.datIns?.format("L LT")}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption" className="bold">{t("PVI.ModifiedOn")}:</Typography><Typography variant="caption"> {data.modifiedOn?.format("L LT")}</Typography>
                    </Grid>
                </Grid>
            }

            <Grid item xs={12} sm={6} lg={12}>
                <FormField title={t("Inzerce.Description")} name='popis' type='text' required textFieldProps={{inputProps: {maxLength: 70}}}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <FormField title={t("Default.Telefon")} name={"rychlyKontakt"} type={"select"}  selectProps={rychlyKontaktSelect} autoSelectFirstValueOnTab serverValidation={{translate: t}}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <FormField title={t("Inzerce.Price")} name='cena' type='number' forceUpdateWhenDataChanged={["currency"]} numberProps={{format:cenaFormat, constraint:cenaConstraint}} validate={(data: Inzerat) => {
                    if (exist(data.cena) && data.cena <=0) return [FieldError.Create(t("FormLocalization.FieldMessages.FieldNotNull"), false, "cena")];
                    return null;}} onWillChange={(field, newValue) => {
                    if (!exist(newValue) && data.currency) {
                        data.currency = null;
                    }
                }}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <FormField title={t("Currency.Title")} name='currency' type='select' disabled={() => !exist(data?.cena) || data.cena === 0 } selectProps={currencySelectProps} autoSelectFirstValueOnTab={true} serverValidation={{translate: t}} validate={(data: Inzerat) => {
                    if (exist(data.cena) && !exist(data.currency))
                        return [FieldError.Create(t("Inzerce.CurrencyRequiredWhenPrice"), false, "currency")];
                    return null;
                }}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <FormField title={t("Inzerce.Dispecer")} name='dispecer' type='select' selectProps={dispecerSelectProps} autoSelectFirstValueOnTab={true}/>
            </Grid>
            <Box display="none">
                <Grid item sm={4} xs={12}>
                    <FormField title={t("TransportNabidka.pocetEditaci")} name={"pocetEditaci"} type={"number"} disabled/>
                </Grid>
            </Box>
        </Grid>
    );
}

export function InzerceCiselnikFormNew({archiveZadani}:{archiveZadani?: boolean}) {
    const {data, edited} = useCodeBookDetailContextNew<Inzerat>();
    const {t} = useTranslation();
    const dispecerSelectProps = useDispecerForInputFormsSelect({isClearable: true, params: {uzivatelId: data.uzivatel?.id}, autoOpenDisabled: true});
    const currencySelectProps = useCurrencySelectObj({isClearable: true});
    const {user} = useAppContext();
    const rychlyKontaktSelect = useRychlyContactSelect({isClearable: true, params: {provozovnaId: user.provozovna.id}, noDynamicFontSize: true, autoOpenDisabled: true, inputType: "phone"});
    const emailSelect = createProvozovnaEmailyContactSelect({isClearable: true, params: {provozovnaId: user.provozovna.id}, autoOpenDisabled: true})
    return (
        <Grid container spacing={2}>
            {
                archiveZadani ? <Grid container item spacing={2} alignItems="stretch" direction="row" style={{paddingBottom:8}}>
                    <Grid item xs>
                        <OfferArchiveActionsPart data={data}/>
                    </Grid>
                </Grid> : undefined
            }
            {edited &&
                <Grid container spacing={2} justifyContent={'flex-end'} style={{marginBottom: 10, marginRight: 0}}>
                    <Grid item>
                        <Typography variant="caption" className="bold">{t("PVI.Creator")}:</Typography><Typography variant="caption"> {`${data.uzivatel?.login ?? ''} ${data.uzivatel?.jmeno ? `(${data.uzivatel?.jmeno})` : ""}`}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption" className="bold">{t("PVI.CreatedOn")}:</Typography><Typography variant="caption"> {data.datIns?.format("L LT")}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption" className="bold">{t("PVI.ModifiedOn")}:</Typography><Typography variant="caption"> {data.modifiedOn?.format("L LT")}</Typography>
                    </Grid>
                </Grid>
            }

            <Grid item xs={12} sm={6} lg={12}>
                <FormField title={t("Inzerce.Description")} name='popis' type='text' required textFieldProps={{inputProps: {maxLength: 70}}}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <FormField title={t("Default.Telefon")} name={"rychlyKontakt"} type={"select"}  selectProps={rychlyKontaktSelect} autoSelectFirstValueOnTab serverValidation={{translate: t}}/>
            </Grid>

            <Grid item lg={3} sm={6} xs={12}>
                <FormField title={"Email"} name={"email"} type={"select"} selectProps={emailSelect} autoSelectFirstValueOnTab/>
            </Grid>

            <Grid item xs={12} sm={6} lg={2}>
                <FormField title={t("Inzerce.Price")} name='cena' type='number' forceUpdateWhenDataChanged={["currency", "jednotka"]} numberProps={{format:cenaFormat, constraint:cenaConstraint}} validate={(data: Inzerat) => {
                    if (exist(data.cena) && data.cena <=0) return [FieldError.Create(t("FormLocalization.FieldMessages.FieldNotNull"), false, "cena")];
                    return null;}} onWillChange={(field, newValue) => {
                    if (!exist(newValue) && data.currency) {
                        data.currency = null;
                    }
                }}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
                <FormField title={t("Currency.Title")} name='currency' type='select' disabled={() => !exist(data?.cena) || data.cena === 0 } selectProps={currencySelectProps} autoSelectFirstValueOnTab={true} serverValidation={{translate: t}} validate={(data: Inzerat) => {
                    if (exist(data.cena) && !exist(data.currency))
                        return [FieldError.Create(t("Inzerce.CurrencyRequiredWhenPrice"), false, "currency")];
                    return null;
                }}/>
            </Grid>
			<Grid item xs={12} sm={6} lg={2}>
				<FormField title={t("Default.Jednotka")} name={"jednotka"} type={"select"} selectProps={{ options: priceUnits }} disabled={() => !exist(data?.cena) || data.cena === 0 }/>
			</Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <FormField title={t("Inzerce.Dispecer")} name='dispecer' type='select' selectProps={dispecerSelectProps} />
            </Grid>

            <Box display="none">
                <Grid item sm={4} xs={12}>
                    <FormField title={t("TransportNabidka.pocetEditaci")} name={"pocetEditaci"} type={"number"} disabled/>
                </Grid>
            </Box>
        </Grid>
    );
}
