import {FieldError} from "../../../../../common/component/form/ValidationError";
import {useTranslation} from "react-i18next";
import {useData} from "../../../../context/DataContext";
import {SystemParameter, SystemParamKey} from "../../../../model/SystemParameter";
import { clamp, exist, formatInterval, isNotBlank } from '../../../../../common/utils/Util';
import moment, {Moment} from "moment";
import {TFunction} from "i18next";
import {
    formatForRaalUsers,
    FormNominatim,
    FormNominatimOptions,
    resolveCity,
    resolvePostCode
} from "../../../../../common/component/form/FormNominatim";
import {OsmPlace, search} from "../../../../../common/component/map/Nominatim";
import {Geometry} from "../../../../model/Geometry";
import {setLoading} from "../../../../../common/component/LoadingContainer";
import {invoke} from "../../../../../common/utils/Invoke";
import {DataGridFieldProps} from "../../../../raal_components/grid/DataGrid.d";
import {FormField, FormInputType} from "../../../../raal_components/form/Form";
import {Grid} from "@material-ui/core";
import React, { MutableRefObject, useCallback } from 'react';
import {formatTempPlace, Misto} from "../../../../model/PrepravaVozidlo";
import {telefonFormat} from '../../../../../common/component/form/FormNumber';
import {Currency} from "../../../../model/Currency";
import {useMpzResolver} from "../../../../../common/utils/MpzUtils";
import {DataProps} from "../../../../raal_components/grid/MTExportContainer";
import {User} from "../../../../model/User";
import {useProvozovnaPdfLayout} from "../ProvozovnaCiselnik";
import {useAppContext} from "../../../../context/AppContext";
import {Region} from "../../../../model/CommonTypes";
import {
    useCodeBookDetailContext,
    useCodeBookDetailContextNew
} from "../../../../raal_components/controller/CodeBookDetail";
import { ArchivPoznamka } from '../../../../model/ArchivPoznamka';
import { toUpperCaseRemoveDiacritics } from '../../../../../common/component/form/FormCustomPlaceDialog';

export const cenaConstraint = {
    precision:9,
    scale:2
};

export const longCenaConstraint = {
    precision:13,
    scale:2
};

export const vahaConstraint = {
    precision:7,
    scale:2
};

export const paletyConstraint = {
    precision:4,
    scale:0
};

export function getFilterVersion() {
    return 6;
}

export interface VPMista {
    getMista():Misto[]
}

export interface VozidloPrepravaSharedFields extends VPMista {
    datOd?: Moment,
    datum:string,
    datDo?: Moment,
    mistoOdkud?: Misto,
    odkudTouched?: boolean,
    mistoKam?: Misto,
    kamTouched?: boolean,
    naves?: boolean,
    souprava?: boolean,
    jine?: boolean,
    modifiedOn?: Moment
    druhy: number[];
    delka?: number;
    vaha?: number;
    cena?: number;
    regOd?: string;
    regKam?: string;
    currency?: Currency;
}
export const sizeFormat = "0,0.[00]";
export const sizeIntegerFormat = "0,0";
export const sizeViewFormat = "0,0.00";
export const kontaktNumberProps = {format:telefonFormat, constraint:{precision:15, scale:0}};
export const exportableFields: DataProps[] = [
    {
        type: ["csv", "xls"],
        fields: ["regOd", "pscOdkud", "odkud", "regKam", "pscKam", "kam", "naves", "souprava", "jine", "delka", "vaha", "druhy", "datOd", "datDo", "rychlyKontakt", "cena", "currency", "provozovna", "uzivatel", "modifiedOn", "invalDuv", "palety", "verejnaPozn", "dispecer", "sirka", "vyska", "lozPlocha", "objem", "neverPozn", "adr", "naklProstorVcelku", "zvedaciCelo", "druhyRidic", "zakazDokladky", "hydraulickaRuka", "nadrozmernyNaklad", "nakladkaZezadu", "nakladkaBokem", "nakladkaShora"]
    },
    {
        type: ["xml"],
        fields: ["id", "regOd", "pscOdkud", "odkud", "regKam", "pscKam", "kam", "naves", "souprava", "jine", "delka", "vaha", "druhy", "datOd", "datDo", "rychlyKontakt", "cena", "currency", "uzivatel", "modifiedOn", "invalDuv", "palety", "verejnaPozn", "dispecer", "sirka", "vyska", "lozPlocha", "objem", "neverPozn", "adr", "naklProstorVcelku", "zvedaciCelo", "druhyRidic", "zakazDokladky", "hydraulickaRuka", "nadrozmernyNaklad", "nakladkaZezadu", "nakladkaBokem", "nakladkaShora"]
    },
    {
        type: ["pdf"],
        fields: ["regOd", "regKam", "pscOdkud", "pscKam", "odkud", "kam", "datOd", "datDo", "naves", "souprava", "jine", "druhy", "delka", "vaha", "cena", "currency", "rychlyKontakt", "uzivatel", "modifiedOn", "invalDuv", "palety", "verejnaPozn", "dispecer", "sirka", "vyska", "lozPlocha", "objem", "neverPozn", "adr", "naklProstorVcelku", "zvedaciCelo", "druhyRidic", "zakazDokladky", "hydraulickaRuka", "nadrozmernyNaklad", "nakladkaZezadu", "nakladkaBokem", "nakladkaShora"]
    }
]
export const exportableFieldsView: DataProps[] = [
    {
        type: ["csv", "xls"],
        fields: ["regOd", "pscOdkud", "odkud", "regKam", "pscKam", "kam", "naves", "souprava", "jine", "delka", "vaha", "druhy", "datOd", "datDo", "rychlyKontakt", "cena", "currency", "provozovna", "uzivatel", "datIns", "modifiedOn", "palety", "verejnaPozn", "dispecer", "sirka", "vyska", "lozPlocha", "objem", "neverPozn", "adr", "naklProstorVcelku", "zvedaciCelo", "druhyRidic", "zakazDokladky", "hydraulickaRuka", "nadrozmernyNaklad", "nakladkaZezadu", "nakladkaBokem", "nakladkaShora"]
    },
    {
        type: ["pdf"],
        fields: ["regOd", "regKam", "pscOdkud", "pscKam", "odkud", "kam", "datOd", "datDo", "naves", "souprava", "jine", "druhy", "delka", "vaha", "cena", "currency", "rychlyKontakt", "uzivatel", "modifiedOn", "palety", "verejnaPozn", "dispecer", "sirka", "vyska", "lozPlocha", "objem", "neverPozn", "adr", "naklProstorVcelku", "zvedaciCelo", "druhyRidic", "zakazDokladky", "hydraulickaRuka", "nadrozmernyNaklad", "nakladkaZezadu", "nakladkaBokem", "nakladkaShora"]
    }
]

export const exportableFieldsAdminView: DataProps[] = [
    {
        type: ["csv", "xls"],
        fields: ["regOd", "pscOdkud", "odkud", "regKam", "pscKam", "kam", "naves", "souprava", "jine", "delka", "vaha", "druhy", "datOd", "datDo", "rychlyKontakt", "cena", "currency", "provozovna", "uzivatel", "datIns", "modifiedOn", "invalDuv", "palety", "verejnaPozn", "dispecer", "sirka", "vyska", "lozPlocha", "objem", "neverPozn", "adr", "naklProstorVcelku", "zvedaciCelo", "druhyRidic", "zakazDokladky", "hydraulickaRuka", "nadrozmernyNaklad", "nakladkaZezadu", "nakladkaBokem", "nakladkaShora"]
    },
    {
        type: ["pdf"],
        fields: ["regOd", "regKam", "pscOdkud", "pscKam", "odkud", "kam", "datOd", "datDo", "naves", "souprava", "jine", "druhy", "delka", "vaha", "cena", "currency", "rychlyKontakt", "uzivatel", "modifiedOn", "invalDuv", "palety", "verejnaPozn", "dispecer", "sirka", "vyska", "lozPlocha", "objem", "neverPozn", "adr", "naklProstorVcelku", "zvedaciCelo", "druhyRidic", "zakazDokladky", "hydraulickaRuka", "nadrozmernyNaklad", "nakladkaZezadu", "nakladkaBokem", "nakladkaShora"]
    }
]

export const getExportableProps = (expProps: DataProps[], archive?: boolean) => {
    const tempExpProps = JSON.parse(JSON.stringify(expProps))
    if (archive) {
        tempExpProps.forEach((e: any) => {
            if (e.type !== "pdf") {
                e.fields = insertAfterReference(e.fields, 'archived', ['modifiedOn'])
            }
        })
    }

    return tempExpProps;
}

export const insertAfterReference = (arr: string[], newElement: string, references: string[]): string[] => {
    for (const reference of references) {
        const indexOfReference = arr.indexOf(reference);
        if (indexOfReference !== -1) {
            arr.splice(indexOfReference + 1, 0, newElement);
            return arr;
        }
    }
    arr.push(newElement);
    return arr;
}

type ValidationParams = {
    realtimeErrorsOd?:MutableRefObject<string[]>,
    realtimeErrorsDo?:MutableRefObject<string[]>,
    maxDruhyKey?:SystemParamKey,
    preprava:boolean
}

export function useValidation<T extends VozidloPrepravaSharedFields>({preprava, realtimeErrorsOd = null, realtimeErrorsDo = null, maxDruhyKey = null}:ValidationParams = {preprava:false}): {
    nsjValidation: (data: T) => FieldError[],
    inlineValidation: (data: T) => FieldError[],
    coordinatesCheck: (data: T) => FieldError[],
    maxDruhuValidation: (data: T) => FieldError[]
} {
    const maxDruhuDefault = 2;
    const {t} = useTranslation();
    const [maxDruhu] = useData<SystemParameter>(state => state.systemParam.find(s => s.key === maxDruhyKey));
    const {user} = useAppContext();

    const maxDruhuValidation = (data:T) => {
        if((data.druhy?.length ?? 0) > (maxDruhu?.toInt() ?? maxDruhuDefault)) {
            return [
                FieldError.Create(t("Preprava.DruhyOverflow").replace("{0}", maxDruhu?.value ?? `${maxDruhuDefault}`), false, "druhy")
            ]
        }
        if (!exist(data.druhy) || data.druhy?.length === 0) {
            return [
                FieldError.Create(t("FormLocalization.FieldMessages.FieldIsRequired"), false, "druhy")
            ]
        }
        return [] as FieldError[];
    };

    const nsjValidation = (data: T) => {
        if(!preprava && !data.naves && !data.souprava && !data.jine) {
            return [
                FieldError.Create(t("Preprava.NSJOneMustBeChecked"), false, "naves")
            ]
        }
        if (!data.naves && !data.souprava && !data.jine) {
            return [
                FieldError.Create(t("Preprava.NSJAnyMustBeChecked"), false, "naves")
            ]
        }
        return [] as FieldError[];
    };
    const requiredFields = (data: T) => {
        const errors = [] as FieldError [];
        const requiredFields = ["delka", "vaha", "datOd",  "odkud", "kam"];
        requiredFields.forEach(f => {
            if (!exist((data as any)[f]) || (((data as any)[f] instanceof Array) && (data as any)[f].length === 0)) {
                errors.push(FieldError.Create(t("FormLocalization.FieldMessages.FieldIsRequired"), false, f))
            }
        });

        if (exist((data as any)["cena"]) && !exist((data as any)["currency"]))
            errors.push(FieldError.Create(t("Preprava.CurrencyRequiredWhenPrice"), false, "currency"))

        const requiredNotNullFields = ["delka", "vaha", "cena"]
        requiredNotNullFields.forEach(f => {
            if (exist((data as any)[f]) && (data as any)[f] <=0) {
                errors.push(FieldError.Create(t("FormLocalization.FieldMessages.FieldNotNull"), false, f))
            }
        });
        return errors;
    };

    const coordinatesCheck = (data: T, currentErrors: any[] = []) => {
        const errors = [] as FieldError [];
		// znevalidněno kvůli realizaci #4349
        // if (!data.mistoKam?.koordinat && data.kamTouched) {
        //     const error = FieldError.Create(t("FormLocalization.FieldMessages.CoordinatesMissing").replace("{0}", t("Preprava.kam")), false, "kam");
        //     if(!(currentErrors instanceof Array) || !currentErrors.find(e => e.name==="kam"))
        //     errors.push(error);
        // }
        // if (!data.mistoOdkud?.koordinat && data.odkudTouched) {
        //     const error = FieldError.Create(t("FormLocalization.FieldMessages.CoordinatesMissing").replace("{0}", t("Preprava.odkud")), false, "odkud");
        //     if(!(currentErrors instanceof Array) || !currentErrors.find(e => e.name==="odkud"))
        //         errors.push(error);
        // }

        if (user?.provozovna?.typ === Region.EU) {
            const odkud = data.regOd ?? data.mistoOdkud?.countryCode;
            const kam = data.regKam ?? data.mistoKam?.countryCode;
            if (odkud === 'CZ' && kam === 'CZ') {
                const error = FieldError.Create(t("FormLocalization.FieldMessages.UserLicenceError").replace("{0}", t("Preprava.odkud")), false, "odkud");
                const error2 = FieldError.Create(t("FormLocalization.FieldMessages.UserLicenceError").replace("{0}", t("Preprava.kam")), false, "kam");
                errors.push(...[error, error2])
            }
        }

        return errors;
    };

    const dateRangeValidation = (data: T) => {
        const errors = [] as FieldError [];
        const requiredFields = ["datOd", "datDo"];
        requiredFields.forEach(f => {
            if (f === 'datOd' && exist((data as any)[f]) && moment((data as any)[f]).isBefore(moment().startOf('day'))) {
                errors.push(FieldError.Create(t("FormLocalization.DateTimePicker.minDateMessage"), false, f));
            }
            if (f === 'datDo' && exist((data as any)[f]) && moment((data as any)[f]).isBefore(moment())) {
                errors.push(FieldError.Create(t("FormLocalization.DateTimePicker.minDateTimeMessage"), false, f));
            }
            if (f === 'datDo' && exist((data as any)[f]) && exist((data as any)['datOd']) && moment((data as any)[f]).isBefore(moment((data as any)['datOd']))) {
                errors.push(FieldError.Create(t("FormLocalization.DateTimePicker.minDateTimeMessage"), false, f));
            }
        });
        return errors;
    };

    const inlineValidation = (data: T) => {
        let errors =  nsjValidation(data).concat(requiredFields(data));
        errors = errors.concat(coordinatesCheck(data, errors))
            .concat(maxDruhuValidation(data))
            .concat(dateRangeValidation(data))
            .concat((realtimeErrorsDo?.current ?? []).map(e=>FieldError.Create(e, false, "datDo")))
            .concat((realtimeErrorsOd?.current ?? []).map(e=>FieldError.Create(e, false, "datOd")));
        return errors;
    };
    return {inlineValidation, nsjValidation, coordinatesCheck, maxDruhuValidation};
}

export const useDateLogic = <T extends VozidloPrepravaSharedFields>(paramKey: SystemParamKey, defaultValue:number, advanceKey: SystemParamKey, defaultAdvanceValue:number):
    [(data: T, setData: (data: T) => void)=>(field:string)=>void,
        (data:T)=>Moment,
        (data:T)=>Moment,
        (data:T, day:Moment, field:string)=>boolean,
        (data: T, setData: (data: T) => void, field:string)=>(e:React.FormEvent<HTMLInputElement>)=>void,
        (data: T, setData: (data: T) => void, field:string)=>(value:string)=>void,
        (data:T)=>Moment,
        (data: T, setData: (data: T) => void)=>(field:string)=>void ] => {
    const [param] = useData<SystemParameter>(state => state.systemParam.find(s => s.key === paramKey));
    const [advance] = useData<SystemParameter>(state => state.systemParam.find(s => s.key === advanceKey));
    const maxRange = param ? parseInt(param.value) : defaultValue;
    const maxAdvance = advance ? parseInt(advance.value) : defaultAdvanceValue;
    const trunc = (m:Moment) => m.clone().set({hour:0, minute:0, second:0, millisecond:0});
    const createHandler = (data: T, setData: (data: T) => void) => (field: string) => {
           // Neexistuje datOd a existuje datDo a je změna v datDo
           if(Boolean(data.datDo) && !Boolean(data.datOd) && field === "datDo") {
               // Nastavit datOd na datOd 00:00
               data.datOd = setDatOdTime(moment(data.datDo), null);
           }
           // Neexistuje datDo a existuje datOd a je změna v datDo
           if(Boolean(data.datOd) && !Boolean(data.datDo) && field === "datDo") {
               // Nastavit datOd na datDo 23:59
               data.datDo = setDatDoTime(moment(data.datOd), null);
           }
           // Existuje datOd a existuje datDo
           if (data.datOd && data.datDo) {
               // Kontrola rozdílu počtu dnů mezi datOd a datDo
               const diff = trunc(data.datDo).diff(trunc(data.datOd), 'days');
               // Pokud je rozdíl větší než maxRange nebo je < 0
               if (diff > maxRange || diff < 0) {
                   // data.datOd > data.datDo
                   if(field === "datOd") {
                       if (data.datOd > data.datDo) {
                           // datDo nastavit na datOd + maxRange 23:59
                           data.datDo = setDatDoTime(data.datOd, null);
                       }
                       // Změna v datOd a datOd < datDo
                       else if (data.datOd < data.datDo) {
                           // datDo nastavit na datOd + maxRange 23:59
                           data.datDo = setDatDoTime(moment(data.datOd).add(maxRange, 'days'), null);
                       }
                   }
               }
               // Nastavit hodnotu pro datOd a datDo zobrazenou v poli v Gridu např. 10.04., 10.04.-11.04.
               data.datum = formatInterval(data.datOd, data.datDo);
           }

        setData(data);
    };
    const createHandlerPicker = (data: T, setData: (data: T) => void) => (field: string) => {
        // Neexistuje datOd a je změna v datOd
        if(!Boolean(data.datOd) && field === "datOd") {
            data.datDo = null
        }
        /*
        Odstraněno na základě tasku 4261 - nevyprazdňovat pole při chybě validace
        // Existuje datOd a je změna v datOd a datOd je v minulosti (dny)
        if (field === "datOd" && Boolean(data.datOd) && moment(data.datOd).isBefore(moment(), "day")) {
          data.datDo = null;
        }*/

        // Existuje datOd a existuje datDo
        if (data.datOd && data.datDo) {
            // Změna v datOd a datOd > datDo
            if (field === "datOd" && data.datOd > data.datDo) {
                // datDo nastavit na datOd 23:59
                data.datDo = setDatDoTime(moment(data.datOd), null);
            }
            // Nastavit hodnotu pro datOd a datDo zobrazenou v poli v Gridu např. 10.04., 10.04.-11.04.
            data.datum = formatInterval(data.datOd, data.datDo);
        }

        setData(data);
    };
    const shouldDisableDate = (data:T, day:Moment, field:string) => {
        const disabledByDo = moment(day).startOf('day').isBefore(moment(data.datOd).startOf('day'));

        const disabledByOd = moment(day).startOf('day').isAfter(moment().startOf('day').add(maxAdvance, 'days'));

        if (field) {
            if (field === "datOd") return disabledByOd;
            if (field === "datDo") return disabledByDo;
        }

        if(data.datOd) {
            return disabledByOd;
        } else if(data.datDo) {
            return disabledByDo;
        }

        return false;
    };

    const setMaxDate = (data:T) => {
        const daysCount = maxAdvance + (moment().diff(data.datOd, 'days') - 1);
        return moment(data.datOd).startOf('day').add(clamp(daysCount, 0, maxRange),'days');
    };

    const setDatOdTime = (m:Moment, m2?:Moment)=>{
        const hours = m2?.hours();
        const minutes = m2?.minutes();
        return m.clone().set({
            hours: hours ?? 0,
            minutes: minutes ?? 0,
        })
    };
    const setDatDoTime = (m:Moment, m2?:Moment)=>{
        const hours = m2?.hour();
        const minutes = m2?.minutes();
        return m.clone().set({
            hours: hours ?? 23,
            minutes: minutes ?? 59,
        })
    };
    const setClosestDateToDay = (data:T, v:string, f:string) => {
        const day = parseInt(v);
        if(isNaN(day)) {
            return;
        }
        const momentCurrDate = moment().set("date", day);
        const momentNextDate = moment().add(1, "month").set("date", day);
        const now = moment();
        const candidates = [
            {value:momentCurrDate, diff:momentCurrDate.diff(now, "days")},
            {value:momentNextDate, diff:momentNextDate.diff(now, "days")}
        ];
        const bestDate = candidates.filter(a => a.diff >= 0).sort((a, b) => a.diff - b.diff)[0]?.value;
        if(f === "datDo") {
            data.datDo = setDatDoTime(bestDate, data.datDo);
        } else {
            data.datOd = setDatOdTime(bestDate, data.datOd);
        }
    };
    return [
        createHandler,
        //initial from
        (data:T)=>setDatOdTime(data.datOd ? moment(data.datOd) : moment(), data.datOd),
        //initial to
        (data:T)=>setDatDoTime(data.datDo ? moment(data.datDo) : moment(), data.datDo),
        shouldDisableDate,
        //onkeyup
        (data: T, setData: (data: T) => void, field:string)=>(e) => {
            //this code works only for dd.MM.YYYY format
            const target = (e.target as HTMLInputElement);
            const value = target.value;
            if(!moment(value).isValid() && (target.selectionStart === 2)) {
                setClosestDateToDay(data, value, field);
                createHandler(data, setData)(field);
                setTimeout(()=>{
                    target.setSelectionRange(3,5);
                }, 10);
            }
        },
        //onblur
        (data: T, setData: (data: T) => void, field:string)=>(e) => {
            setClosestDateToDay(data, e, field);
            createHandler(data, setData)(field);
        },
        setMaxDate,
        createHandlerPicker
    ]
};
type NSJLogicFN<T> = (data: T,  setData: (data:T) => void, value?:boolean) => void
export function useNSJLogic<A extends VozidloPrepravaSharedFields>(preprava:boolean, reloadData: boolean = false):[NSJLogicFN<A>, NSJLogicFN<A>, NSJLogicFN<A>] {
    const [delka] = useData<SystemParameter>(state => state.systemParam.find(s => s.key === (preprava ? 'PREPRAVA_LENGTH' : 'VOZIDLO_LENGTH')));
    const [vaha] = useData<SystemParameter>(state => state.systemParam.find(s => s.key === (preprava ? 'PREPRAVA_WEIGHT' : 'VOZIDLO_WEIGHT')));
    const n = (data:A, setData: (data:A) => void, value:boolean) => {
        data.naves = value;
        if(!preprava) {
            data.naves = true;
            data.souprava = false;
            data.jine = false;
        }
        if (value) {
            if (!data.delka) data.delka = delka ? Number(delka.value) : 13.6;
            if (!data.vaha) data.vaha = vaha ? Number(vaha.value) : 24;
        }
        reloadData && setData(data);
    };
    const s = (data:A, setData: (data:A) => void, value:boolean) => {
        data.souprava = value;
        if(!preprava) {
            data.naves = false;
            data.souprava = true;
            data.jine = false;
        }
        if (value) {
            if (!data.delka) data.delka = delka ? Number(delka.value) : 13.6;
            if (!data.vaha) data.vaha = vaha ? Number(vaha.value) : 24;
        }
        reloadData && setData(data);
    };
    const j = (data:A, setData: (data:A) => void, value:boolean) => {
        data.jine = value;
        if(!preprava) {
            data.naves = false;
            data.souprava = false;
            data.jine = true;
        }
        reloadData && setData(data);
    };
    return [n, s, j];
}
type vpLogicFN<T> = (data: T,  setData: (data:T) => void, value?:number) => void
export function useVPLogic<A extends VozidloPrepravaSharedFields>(preprava:boolean):[vpLogicFN<A>] {
    const cena = (data:A, setData: (data:A) => void, value:number) => {
        data.cena = value;
        if (!value && data.currency) {
            data.currency = null;
        }
        setData(data);
    };
    return [cena];
}

export const getNominatimOptions = <T extends VozidloPrepravaSharedFields>(data: T, field: string, t: TFunction, resolveMpz: (isoCode: string) => string, maxLength: number, selectAll?: boolean): FormNominatimOptions => {

    const setMistoPrepravy = (data: T, field: string, osmPlace: OsmPlace) => {
        if (osmPlace) {
            (data as any)[field === "odkud" ? "mistoOdkud" : "mistoKam"] = new Misto(osmPlace.display_name, osmPlace.lat ? new Geometry(osmPlace.lat, osmPlace.lon) : null, osmPlace.address?.country_code?.toUpperCase());
            (data as any)[field] = resolveCity(osmPlace);
            (data as any)[field === "odkud" ? "regOd" : "regKam"] = resolveMpz(osmPlace.address?.country_code?.toUpperCase());
            (data as any)[field === "odkud" ? "pscOdkud" : "pscKam"] = resolvePostCode(osmPlace.address?.postcode);

            const waypointMisto = new Misto(formatForRaalUsers(osmPlace, () => resolveMpz(osmPlace.address?.country_code))?.substr(0, 50), osmPlace.lat ? new Geometry(osmPlace.lat, osmPlace.lon) : null, osmPlace.address?.country_code?.toUpperCase());

            if(field === "odkud") {
                data.getMista()[0] = waypointMisto;
			} else if (field === "via") {
				// @ts-ignore
				// data.waypointMisto = waypointMisto;
				// do nothing
            } else {
                data.getMista()[1] = waypointMisto;
            }
        } else {
            (data as any)[field === "odkud" ? "mistoOdkud" : "mistoKam"] = null;
            (data as any)[field] = null;
            (data as any)[field === "odkud" ? "regOd" : "regKam"] = null;
            (data as any)[field === "odkud" ? "pscOdkud" : "pscKam"] = null;
        }
    };
    return {
        enableCustomValue: false,
        onBlur: (osmPlace, valueChanged) => {
            if (!osmPlace?.isInitialValue && !osmPlace?.isOption) {
                setLoading(true, t("Preprava.GeocodingCoordinates"));
                const _search = async () => {
                    try {
						if (osmPlace.isCustomValue) {
							setMistoPrepravy(data, field, osmPlace);
							valueChanged(osmPlace);
						} else {
							const result = await search(osmPlace.display_name);
							toUpperCaseRemoveDiacritics(result);
							if (result && result.length > 0) {
								setMistoPrepravy(data, field, result[0]);
								valueChanged(result[0]);
							} else {
								setMistoPrepravy(data, field, null);
								valueChanged();
							}
						}
                    } catch (e) {
                        setMistoPrepravy(data, field, null);
                        valueChanged();
                    } finally {
                        setLoading(false);
                    }
                };
                invoke(_search);
            }

        },
        onChange: osmPlace => {
            if (osmPlace) {
                if (!osmPlace.isCustomValue) {
                    setMistoPrepravy(data, field, osmPlace);
                }
            }
        },
        disableClearable: false,
        selectAll: selectAll,
        resolveMpz: resolveMpz,
        maxLength: maxLength,
        checkLicense: true,
		enableCustomPlace: true,
    } as FormNominatimOptions
};

export function usePlaceAutocomplete<T extends VozidloPrepravaSharedFields>(): [(data: T, field: "odkud" | "kam", maxLength: number, selectAll?: boolean) => DataGridFieldProps] {
    const {t} = useTranslation();
    const [resolveMpz] = useMpzResolver();
    return [(data: T, field, maxLength, selectAll) => (
        {
            style: {minWidth: 200},
            type: FormInputType.Custom,
            customComponent: FormNominatim,
            name: `${field}Helper`,
            onChange: (value, data1, setData, field1) => {
                data1[`${field1}Touched`] = true;
                data1[`${field1}Helper`] = value;
                setData(data1);
            },
            checkLicense: true,
            countryCodeForCompare: () => {
                const odkud = data.regOd ?? data.mistoOdkud?.countryCode;
                const kam = data.regKam ?? data.mistoKam?.countryCode;
                return (field === "kam" && odkud === "CZ") || (field === "odkud" && kam === "CZ");
            },
            customComponentOptions: {
                ...getNominatimOptions(data, field, t, resolveMpz, maxLength, selectAll),
                checkUserProvozovna: true
            }}
    )]
}

export function FormDateRangeGroupVozidloPreprava<T extends VozidloPrepravaSharedFields>({defaultRange, paramKey, advanceKey, defaultAdvanceValue, skipValidation}: {paramKey: SystemParamKey, defaultRange:number, advanceKey: SystemParamKey, defaultAdvanceValue:number, skipValidation: boolean }) {
    const {t} = useTranslation();
    const [, initialFrom, initialTo, shouldDisabledDate, , , setMaxDate, createHandlerPicker] = useDateLogic<T>(paramKey, defaultRange, advanceKey, defaultAdvanceValue);
    const {data, setModalData} = useCodeBookDetailContext<T>();
    return (
        <>
            <Grid item lg={3} sm={6} xs={12}>
                <FormField
                   required
                   title={t("Preprava.datOd")}
                   name={"datOd"}
                   type={FormInputType.DateTime}
                   onChanged={field => createHandlerPicker(data, setModalData)(field.props.name)}
                   autoSelectFirstValueOnTab={true}
                   dateTimeOptions={{
                        initialValue:initialFrom(data),
                        timeFormat: true,
                        datePattern: moment.localeData().longDateFormat('L').substr(0, 5),
                        timePattern: 'HH:mm',
                        clearIfDisabled: true,
                        skipValidation: skipValidation,
                        dateTimePickerProps:{
                            disablePast:true,
                            shouldDisableDate:day => {
                                return shouldDisabledDate(data, moment(day),"datOd");
                            }
                        }
                   }}
                />
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
                <FormField title={t("Preprava.datDo")} name={"datDo"} type={"datetime"}
                           //disabled={!data?.datOd || data?.datOd.isBefore(moment(), "day")}
                           onChanged={field => createHandlerPicker(data, setModalData)(field.props.name)}
                           autoSelectFirstValueOnTab={true}
                           dateTimeOptions={{
                                initialValue:initialTo(data),
                                timeFormat: true,
                                addEndOfDay: true,
                                datePattern: moment.localeData().longDateFormat('L').substr(0, 5),
                                timePattern: 'HH:mm',
                                dateForCompare: data?.datOd,
                                skipValidation: skipValidation,
                               // clearIfDisabled: true,
                                dateTimePickerProps:{
                                    disablePast:true,
                                    shouldDisableDate:() => {
                                        return false;
                                    },
                                    minDate: data?.datOd,
                                    maxDate: setMaxDate(data)
                                }
                }}
                validate={(data: T)  => {
                    if (exist(data.datDo) && moment(data.datDo).isBefore(moment())) {
                        return [FieldError.Create(t("FormLocalization.DateTimePicker.minDateTimeMessage"), false, "datDo")];
                    }
                    if (exist(data.datDo) && exist(data.datOd) && moment(data.datDo).isBefore(moment(data.datOd))) {
                        return [FieldError.Create(t("FormLocalization.DateTimePicker.minDateTimeMessage"), false, "datDo")];
                    }
                    return null;
                }} />
            </Grid>

        </>
    );
}

export function FormDateRangeGroupVozidloPrepravaNew<T extends VozidloPrepravaSharedFields>({defaultRange, paramKey, advanceKey, defaultAdvanceValue, skipValidation}: {paramKey: SystemParamKey, defaultRange:number, advanceKey: SystemParamKey, defaultAdvanceValue:number, skipValidation: boolean }) {
    const {t} = useTranslation();
    const [, initialFrom, initialTo, shouldDisabledDate, , , setMaxDate, createHandlerPicker] = useDateLogic<T>(paramKey, defaultRange, advanceKey, defaultAdvanceValue);
    const {data, setData} = useCodeBookDetailContextNew<T>();
    return (
        <>
            <Grid item lg={3} sm={6} xs={12}>
                <FormField
                    required
                    title={t("Preprava.datOd")}
                    name={"datOd"}
                    type={FormInputType.DateTime}
                    onChanged={field => createHandlerPicker(data, setData)(field.props.name)}
                    autoSelectFirstValueOnTab={true}
                    dateTimeOptions={{
                        initialValue:initialFrom(data),
                        timeFormat: true,
                        datePattern: moment.localeData().longDateFormat('L').substr(0, 5),
                        timePattern: 'HH:mm',
                        clearIfDisabled: true,
                        skipValidation: skipValidation,
                        dateTimePickerProps:{
                            disablePast:true,
                            shouldDisableDate:day => {
                                return shouldDisabledDate(data, moment(day),"datOd");
                            }
                        }
                    }}
                />
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
                <FormField title={t("Preprava.datDo")} name={"datDo"} type={"datetime"}
                           //disabled={!data?.datOd || data?.datOd.isBefore(moment(), "day")}
                           onChanged={field => createHandlerPicker(data, setData)(field.props.name)}
                           autoSelectFirstValueOnTab={true}
                           dateTimeOptions={{
                               initialValue:initialTo(data),
                               timeFormat: true,
                               addEndOfDay: true,
                               datePattern: moment.localeData().longDateFormat('L').substr(0, 5),
                               timePattern: 'HH:mm',
                               dateForCompare: data?.datOd,
                               skipValidation: skipValidation,
                               //clearIfDisabled: true,
                               dateTimePickerProps:{
                                   disablePast:true,
                                   /*shouldDisableDate:day => {
                                       return shouldDisabledDate(data, moment(day),"datDo");
                                   },*/
                                   shouldDisableDate:() => {
                                       return false;
                                   },
                                   minDate: data?.datOd,
                                   maxDate: setMaxDate(data)
                               }
                           }}
                           validate={(data: T)  => {
                               if (exist(data.datDo) && moment(data.datDo).isBefore(moment())) {
                                   return [FieldError.Create(t("FormLocalization.DateTimePicker.minDateTimeMessage"), false, "datDo")];
                               }
                               if (exist(data.datDo) && exist(data.datOd) && moment(data.datDo).isBefore(moment(data.datOd))) {
                                   return [FieldError.Create(t("FormLocalization.DateTimePicker.minDateTimeMessage"), false, "datDo")];
                               }
                               return null;
                           }} />
            </Grid>

        </>
    );
}

export function FormOdkudKam<T extends VozidloPrepravaSharedFields>({maxLength}: {maxLength: number }) {
    const {t} = useTranslation();
    const [resolveMpz] = useMpzResolver();
    const {data} = useCodeBookDetailContext<T>();
    const compareRegion = useCallback((field: "odkud" | "kam") => {
        const odkud = data.regOd ?? data.mistoOdkud?.countryCode;
        const kam = data.regKam ?? data.mistoKam?.countryCode
        return (field === "kam" && odkud === "CZ") || (field === "odkud" && kam === "CZ");
    }, [data])
    return (
        <>
            <Grid item lg={3} xs={12}>
                <FormField title={t("Preprava.odkud")} name={"odkudHelper"} type={"Custom"}
                           onChanged={() => {
                               data.odkudTouched = true;
                           }}
                           customComponent={FormNominatim}
                           customComponentOptions={{
                               ...getNominatimOptions(data, "odkud", t, resolveMpz, maxLength),
                               countryCodeForCompare: () => compareRegion("odkud"),
                               checkUserProvozovna: true
                           }}
                           required
                           additionalServerErrorKeys={["waypointy[0].countryCode", "waypointy[0]"]}
                           validate={(d: T) => {
                               if (!d.mistoOdkud?.koordinat && d.odkudTouched) {
                                   return [FieldError.Create(t("FormLocalization.FieldMessages.CoordinatesMissing").replace("{0}", t("Preprava.odkud")), false, "odkudHelper")];
                               }
                               return null
                           }}/>
            </Grid>
            <Grid item lg={3} xs={12}>
                <FormField title={t("Preprava.kam")} name={"kamHelper"} type={"Custom"}
                           onChanged={() => {
                               data.kamTouched = true;
                           }}
                           customComponent={FormNominatim}
                           customComponentOptions={{
                               ...getNominatimOptions(data, "kam", t, resolveMpz, maxLength),
                               countryCodeForCompare: () => compareRegion("kam"),
                               checkUserProvozovna: true
                           }}
                           required
                           additionalServerErrorKeys={["waypointy[1].countryCode", "waypointy[1]" ]}
                           validate={(d: T) => {
                               if (!d.mistoKam?.koordinat && d.kamTouched) {
                                   return [FieldError.Create(t("FormLocalization.FieldMessages.CoordinatesMissing").replace("{0}", t("Preprava.kam")), false, "kamHelper")];
                               }
                               return null
                           }}/>
            </Grid>
        </>
    );
}

export function FormOdkudKamNew<T extends VozidloPrepravaSharedFields>({maxLength}: {maxLength: number }) {
    const {t} = useTranslation();
    const [resolveMpz] = useMpzResolver();
    const {data} = useCodeBookDetailContextNew<T>();
    const compareRegion = useCallback((field: "odkud" | "kam") => {
        const odkud = data.regOd ?? data.mistoOdkud?.countryCode;
        const kam = data.regKam ?? data.mistoKam?.countryCode
        return (field === "kam" && odkud === "CZ") || (field === "odkud" && kam === "CZ");
    }, [data])
    return (
        <>
            <Grid item lg={3} xs={12}>
                <FormField title={t("Preprava.odkud")} name={"odkudHelper"} type={"Custom"}
                           onChanged={() => {
                               data.odkudTouched = true;
                           }}
                           customComponent={FormNominatim}
                           customComponentOptions={{
                               ...getNominatimOptions(data, "odkud", t, resolveMpz, maxLength),
                               countryCodeForCompare: () => compareRegion("odkud"),
                               checkUserProvozovna: true
                           }}
                           required
                           additionalServerErrorKeys={["waypointy[0].countryCode", "waypointy[0]"]}
                           validate={(d: T) => {
							   // @ts-ignore
							   const mistoBaseIsNotBlank = isNotBlank(d.regOd) && isNotBlank(d.pscOdkud) && isNotBlank(d.odkud);
                               if (!d.mistoOdkud?.koordinat && d.odkudTouched && !mistoBaseIsNotBlank) {
                                   return [FieldError.Create(t("FormLocalization.FieldMessages.CoordinatesMissing").replace("{0}", t("Preprava.odkud")), false, "odkudHelper")];
                               }
                               return null
                           }}/>
            </Grid>
            <Grid item lg={3} xs={12}>
                <FormField title={t("Preprava.kam")} name={"kamHelper"} type={"Custom"}
                           onChanged={() => {
                               data.kamTouched = true;
                           }}
                           customComponent={FormNominatim}
                           customComponentOptions={{
                               ...getNominatimOptions(data, "kam", t, resolveMpz, maxLength),
                               countryCodeForCompare: () => compareRegion("kam"),
                               checkUserProvozovna: true
                           }}
                           required
                           additionalServerErrorKeys={["waypointy[1].countryCode", "waypointy[1]" ]}
                           validate={(d: T) => {
							   // @ts-ignore
							   const mistoBaseIsNotBlank = isNotBlank(d.regKam) && isNotBlank(d.pscKam) && isNotBlank(d.kam);
                               if (!d.mistoKam?.koordinat && d.kamTouched && !mistoBaseIsNotBlank) {
                                   return [FieldError.Create(t("FormLocalization.FieldMessages.CoordinatesMissing").replace("{0}", t("Preprava.kam")), false, "kamHelper")];
                               }
                               return null
                           }}/>
            </Grid>
        </>
    );
}


export const useVPPdfLayout = (user: User, isPreprava: boolean = true) => {

    const {t} = useTranslation();
    const {pdfLayout: provozovnaPdfLayout} = useProvozovnaPdfLayout(user);

    const pdfLayout = (data: any, fields: any, pageBreak: boolean, index: number, origin: any, extendedData?: any) => {

        const item = (field: string, unit?: string, marginLeft: number = 5, customField?: string) => {
            return <div style={{marginLeft: marginLeft}}><strong>{`${customField ?? fields[field]}: `}</strong><span>{!data[field] ? '-' : `${data[field]} ${unit ?? ''}`}</span></div>
        }

        const itemCustom = (title: string, data: string, checkString?: boolean, marginLeft: number = 5) => {
            //Test string length
            if (checkString && data && data.length > 25) {
                const stringPartsArray = data.split(' ');
                for (let i = 0; i < stringPartsArray.length; i++) {
                    if (stringPartsArray[i].length > 25) {
                        const parts = stringPartsArray[i].match(/.{1,25}/g);
                        stringPartsArray[i] = parts.join(' ');
                    }
                }

                data = stringPartsArray.join(' ');
            }
            return <div style={{marginLeft: marginLeft}}><strong>{title ? `${title}: ` : null}</strong><span>{data}</span></div>
        }

        const getNSJ = () => {
            const f = []
            if (data["naves"] === 'Ano') f.push(fields["naves"])
            if (data["souprava"] === 'Ano') f.push(fields["souprava"])
            if (data["jine"] === 'Ano') f.push(fields["jine"])
            return <div style={{marginLeft: 5}}><strong>{`${t('Preprava.nsj')}: `}</strong><span>{f.join(', ')}</span></div>
        }

        const getNakladka = () => {
            const f = []
            if (data["nakladkaZezadu"] === 'Ano') f.push(t("Preprava.nakladkaZezaduShort"))
            if (data["nakladkaBokem"] === 'Ano') f.push(t("Preprava.nakladkaBokemShort"))
            if (data["nakladkaShora"] === 'Ano') f.push(t("Preprava.nakladkaShoraShort"))
            return <div style={{marginLeft: 5}}><strong>{`${t('Preprava.zpusobNakladky')}: `}</strong><span>{f.join(', ')}</span></div>
        }
        const getDoplnky = () => {
            const f = []
            if (data["adr"] === 'Ano') f.push(fields["adr"])
            if (data["druhyRidic"] === 'Ano') f.push(fields["druhyRidic"])
            if (data["hydraulickaRuka"] === 'Ano') f.push(fields["hydraulickaRuka"])
            if (data["nadrozmernyNaklad"] === 'Ano') f.push(fields["nadrozmernyNaklad"])
            if (data["naklProstorVcelku"] === 'Ano') f.push(fields["naklProstorVcelku"])
            if (data["zakazDokladky"] === 'Ano') f.push(fields["zakazDokladky"])
            if (data["zvedaciCelo"] === 'Ano') f.push(fields["zvedaciCelo"])
            return <div style={{marginLeft: 5}}><strong>{`${t('Preprava.doplnky')}: `}</strong><span>{f.join(', ')}</span></div>
        }
        const provozovna = origin?.uzivatel?.provozovna;

        return (<div className={'font-9'} key={'div_' + index}>
            {!pageBreak ? <table data-pdfmake="{'widths':['*','*']}" style={{marginBottom: 10}}>
                <tr>
                    <td rowSpan={2} style={{border: 0}}><img src={'logo'} style={{width: 200}} alt={"img"} /></td>
                    <td style={{border: 0, textAlign: 'right'}}>{`${t('Default.PdfExportedUser')}: ${user.jmeno ?? '-'}`}</td>
                </tr>
                <tr>
                    <td style={{border: 0, textAlign: 'right'}}>{`${t('Default.PdfExportedDate')}: ${moment().format('L LT')}`}</td>
                </tr>
            </table> : null}
            <hr/>
            <div style={{textAlign: 'center'}}>
                <h6>{t('Default.PdfHeader').replace("{0}", isPreprava ? "přepravy" : "volného vozu").replace("{1}", origin.nabidkaId ?? origin.prepravaId ?? origin.vozidloId ?? origin.id ?? '')}</h6>
            </div>
            <hr/>
            {itemCustom(t('Preprava.odkud'), data.odkud ? formatTempPlace(origin.regOd, origin.pscOdkud, origin.odkud) : null)}
            {itemCustom(t('Preprava.kam'), data.kam ? formatTempPlace(origin.regKam, origin.pscKam, origin.kam) : null)}
            {item('datOd')}
            {item('datDo')}
            {getNSJ()}
            <table data-pdfmake="{'widths':['*','*','*']}">
                <tr>
                    <td style={{border: 0}}>{item('delka', 'm', 0)}</td>
                    <td style={{border: 0}}>{item('sirka', 'm', 0)}</td>
                    <td style={{border: 0}}>{item('vyska', 'm', 0)}</td>
                </tr>
            </table>
            <table data-pdfmake="{'widths':['*','*','*']}">
                <tr>
                    <td style={{border: 0}}>{item('vaha', 't', 0)}</td>
                    <td style={{border: 0}}>{item('objem', 'm3', 0)}</td>
                    <td style={{border: 0}}>{item('lozPlocha', 'm2', 0)}</td>
                </tr>
            </table>
            {itemCustom(t('Preprava.verejnaPozn'), origin?.verejnaPozn)}
            {item('palety')}
            {item('druhy')}
            {itemCustom(t('Preprava.cena'), data.cena ? `${data.cena} ${data.currency}` : null)}
            {getDoplnky()}
            {getNakladka()}
            {itemCustom(t('Provozovna.Kod'), provozovna?.kod)}
            {itemCustom(t('Preprava.rychlyKontakt'), data.rychlyKontakt)}
            {itemCustom(t('Preprava.zadavatel'), data.uzivatel)}
            {item('dispecer')}
            {itemCustom(t('Preprava.modifiedOn'), origin?.modifiedOn ? moment(origin.modifiedOn).format('L LT') : null)}
			{extendedData?.list &&
		        <table data-pdfmake="{'widths':['*','*','*']}">
			        <tr>
				        <th>{t('Poznamka.poznamka')}</th>
				        <th>{t('Poznamka.uzivatel')}</th>
				        <th>{t('Poznamka.datIns')}</th>
			        </tr>
					{extendedData?.list?.map((poznamka: ArchivPoznamka, index: number) => {
						return (
							<tr key={index}>
								<td>{poznamka.poznamka}</td>
								<td>{poznamka.uzivatel?.jmeno}</td>
								<td>{moment(poznamka.datIns).format('L LT')}</td>
							</tr>
						)
					})}
		        </table>
			}
            {provozovnaPdfLayout(data, fields, pageBreak, index, origin)}
        </div>)
    }

    return {pdfLayout}
}
