import {useTranslation} from "react-i18next";
import {useAppContext} from "../../../../context/AppContext";
import * as React from "react";
import {useRef} from "react";
import {useViewColumns} from "../_vp/VPColumns";
import {NabidkaType} from "../../../../model/NabidkaType";
import {useEnumCiselnikSelect, useYesNoSelect} from "../../../../raal_components/SelectOptions";
import {CiselnikTyp} from "../../../../model/Ciselnik";
import {InvalDuvodVozidlo} from "../../../../model/CommonTypes";
import {useCiselnikValues, useLocalizeCiselnikValue} from "../../../../context/DataContext";
import {DataGridExposed} from "../../../../raal_components/grid/DataGrid";
import {VPFilter, VPListVozidloAdmin} from "../../../../model/PrepravaVozidlo";
import {
    exportableFieldsAdminView,
    exportableFieldsView,
    getExportableProps,
    getFilterVersion,
    useVPPdfLayout
} from "../_vp/PrepravaAVozidlaShared";
import {Dial} from "../../../../raal_components/dial/Dial";
import {globalStyles} from "../../../../context/ThemeModeContext";
import {FilterForm} from "../_vp/VPFilter";
import {TabHelperNew} from "../../../../raal_components/controller/TabHelper";
import {ExtendedViewNew} from "../_vp/VPExtendedView";
import {
    CISELNIK_DRUH,
    DEFAULT_ADVANCE_VALUE,
    DEFAULT_RANGE_VALUE,
    PARAM_ADVANCE_KEY,
    PARAM_RANGE_KEY,
    prepareCols
} from "../prohlizeni/PrepravyCiselnikView";
import {Vozidlo} from "../../../../model/Vozidlo";
import {VlastniVozyCiselnikViewProps,} from "../prohlizeni/VlastniVozyCiselnikView";
import {VolneVozyViewAdminPartNew} from "../prohlizeni/VolneVozyViewAdminPart";
import {VolneVozyViewHistoryPartNew} from "../prohlizeni/VolneVozyViewHistoryPart";
import {ComponentMode} from "../../../../routes";
import { httpEndpoint } from '../../../../../common/utils/HttpUtils';
import { ScrollableList } from '../../../../../common/utils/Util';
import { ArchivPoznamka } from '../../../../model/ArchivPoznamka';
import moment from 'moment';
import {useClearZaznamOznaceni, useZaznamOznaceni, useZaznamOznaceniStyle, ZaznamOznaceniTyp} from "../Oznaceni";

export function VolneVozyCiselnikArchiveView(props: VlastniVozyCiselnikViewProps) {
    const {t} = useTranslation();
    const {user} = useAppContext();
    const oznacenoFilter = useRef(!props.admin);
    const refreshFun = useRef(() => {});
    let [createColumns] = useViewColumns(CISELNIK_DRUH, oznacenoFilter.current, refreshFun, undefined, props.admin ? ["uzivatel"] : [], PARAM_RANGE_KEY, DEFAULT_RANGE_VALUE, PARAM_ADVANCE_KEY, DEFAULT_ADVANCE_VALUE, props.admin, {archive: true, viewArchive: true, typ: NabidkaType.VOZIDLO, refactor: true}, true);
    const dateKey = "vozy-archive-view-browse-date";
    const endpoint = props.admin ? "admin/vozidlo-archiv" : "user/vozidlo-prohlizeni-archiv";
    const invalDuvSelect = useEnumCiselnikSelect({isClearable: true, ciselnikTyp: CiselnikTyp.V, enm: InvalDuvodVozidlo, enmName: "Enumerations.InvalDuvodVozidlo"});
    const yesNoSelect = useYesNoSelect({isClearable:true});
    const locFun = useLocalizeCiselnikValue(CiselnikTyp.P);
    const dtGrid = useRef<DataGridExposed<VPListVozidloAdmin, VPFilter>>();
    const {druhyJoined} = useCiselnikValues(CISELNIK_DRUH);
    const {pdfLayout} = useVPPdfLayout(user);
    const zaznamOznaceni = useZaznamOznaceniStyle(ZaznamOznaceniTyp.ARCHIV_PROHLIZENI_VOZY);
    const clearZaznamOznaceni = useClearZaznamOznaceni(
        useZaznamOznaceni("user", ZaznamOznaceniTyp.ARCHIV_PROHLIZENI_VOZY),
        () => {dtGrid.current?.table()?.refresh()}
    );

    return <Dial<VPListVozidloAdmin, VPFilter, Vozidlo>
        mode={props.mode}
        lastBrowsedDateKey={dateKey}
        logActivity
        gridRef={dtGrid}
        onGridMount={()=>{
            refreshFun.current = () => dtGrid.current?.table()?.refresh();
            oznacenoFilter.current = dtGrid.current.table().getFilter().filters.oznaceno;
        }}
        onFilterChanged={(data) => {
            oznacenoFilter.current = data?.oznaceno ?? false;
        }}
        config={{
            lockSupport: {enabled: false},
            overflowHidden: true,
            requiredColumns: ['odkud','kam','souprava','naves','jine','delka','vaha','druhy','druh','datum'],
            alternativeButtons: [clearZaznamOznaceni],
            exportConfig: {
                exportable: true,
                exportAll: props.admin,
                exportDetailOnly: !props.admin,
                endpoint: endpoint,
                fileName: "export_archiv_volne_vozy",
                translationPrefix: ['Preprava', 'User', 'Archive'],
                formats: ["pdf", "xls", "csv", "xml"],
                exportAllFormats: ["csv"],
                exportableProps: getExportableProps(props.admin ? exportableFieldsAdminView : exportableFieldsView, true),
				extendedDataEndpoint: (data) => {
					return httpEndpoint<ScrollableList<ArchivPoznamka>>(ScrollableList, `user/vozidlo-prohlizeni-archiv/${data.id}/poznamka?page=1&pageSize=15&overallCount=true`, {});
				},
				extendedDataMapper: (data) => {
					let headers : any = {};
					let values : any = {};
					data?.list?.map((poznamka: ArchivPoznamka, index: number) => {
						headers['poznamka' + index] = `${t('Poznamka.poznamka')} ${index}`;
						headers['jmeno' + index] = `${t('Poznamka.uzivatel')} ${index}`;
						headers['datIns' + index] = `${t('Poznamka.datIns')} ${index}`;

						values['poznamka' + index] = poznamka.poznamka;
						values['jmeno' + index] = poznamka.uzivatel?.jmeno;
						values['datIns' + index] = moment(poznamka.datIns).format('L LT');
					})
					return {headers, values};
				},
                pdfLayout: (data, fields, pageBreak, index, origin, extendedData) => pdfLayout(data, fields, pageBreak, index, origin, extendedData),
                extendedProps: [
                    {
                        type: ["csv", "xls"],
                        addExtendedProps: (data) => {
                            return {
                                provozovna: data.uzivatel?.provozovna?.kod,
                                ...{archived: data.deletedOn || data.datArch}
                            }
                        }
                    }
                ],
                formattedProps: [
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "druhy",
                        format: (data) => {
                            return druhyJoined(data)
                        }
                    },
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "invalDuv",
                        format: (data) => {
                            return locFun("Enumerations.InvalDuvodVozidlo", InvalDuvodVozidlo, data)
                        }
                    },
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "rychlyKontakt",
                        format: (data) => {
                            return data ? `${data.slice(0, 4)} ${data.slice(4)}` : null;
                        }
                    },
					{
						type: ["csv", "xls"],
						field: "pscKam",
						format: (data) => {
							return data ? data.replace(/="|"$/g, '') : null;
						}
					},
					{
						type: ["csv", "xls"],
						field: "pscOdkud",
						format: (data) => {
							return data ? data.replace(/="|"$/g, '') : null;
						}
					}
                ]
            },
            options: (props.admin) ? {
                rowStyle: (data: any) => {
                    if (!data.valid)
                        return globalStyles.rowStyleAlert;
                    if(data.stav===InvalDuvodVozidlo.DUPLICITA && props.admin){
                        return globalStyles.rowStyleDuplicated;
                    }
                    return undefined;
                }
            } : {
                rowStyle: (data: any) => {
                    if (data.klasifikace?.barva) {
                        return {backgroundColor: data.klasifikace?.barva}
                    }
                    const oznaceniStyle = zaznamOznaceni.getStyle(data);
                    if(oznaceniStyle) return oznaceniStyle;
                }
            },
            version:getFilterVersion(),
            tableTitle : t("Dials.VolneVozy"), endpoint:endpoint, clazz : VPListVozidloAdmin, filtering:true,
            hideAddNewActions: true,
            columns: prepareCols(createColumns, props.admin, true, true, false, t, yesNoSelect, locFun, invalDuvSelect),
            hideDefaultDummyAction:false,
            defaultQueryParameters: props.filter,
        }}
        hideSaveButton
        hideNewButtonOnEdit
        clazzDetail={Vozidlo}
        crudConfig={{editEnabled: false, removeEnabled: false, addEnabled: false}}
        layoutFilter={() => <FilterForm {...{lastBrowseDateDataKey: dateKey, typCiselniku:CISELNIK_DRUH, showOznaceno:true, preprava: false, viewing: true, defaultRange:DEFAULT_RANGE_VALUE, typAdvance: PARAM_ADVANCE_KEY, defaultAdvance: DEFAULT_ADVANCE_VALUE, isArchiveOfBrowsedOffers: true, admin: props.admin, archive: true}}/>}
        layoutDetail={()=><TabHelperNew<Vozidlo> render={(data)=>
            <>
                <ExtendedViewNew ciselnikTyp={CISELNIK_DRUH} data={data} admin={props.admin} archive viewArchive/>
                {props.admin ? <VolneVozyViewAdminPartNew vozidlo={data} archive/> : undefined}
            </>}/>}
        filterClazz={VPFilter}
        tabs={[
            props.admin ? {
                title: t("Preprava.History"),
                render: ()=><TabHelperNew<Vozidlo> render={(data) => <VolneVozyViewHistoryPartNew vozidlo={data} archive admin={props.admin} mode={ComponentMode.GridMode}/>}/>
            } : undefined
        ].filter(i => i)}
    />;
}
