import {JsonIgnore, JsonProperty} from "../../common/utils/objectmapper/Mapper";
import {MomentConverters} from "./Converters";
import {Moment} from "moment";
import {UserDispecer, UserViewDispecer} from "./Dispecer";
import {User, UserBasic} from "./User";
import {Currency} from "./Currency";
import {NumberRange} from "../../common/component/form/FormNumberRange";
import {CachedDetailRow} from "./CachedDetailRow";
import {ItemAge} from "../../common/component/form/FormItemAge";
import {BasicUserData} from "./CommonTypes";
import {Klasifikace} from "./Klasifikace";
import {UserInfo} from "./UserInfo";
import {DateRange} from "../../common/component/form/FormDateRange";
import {OznaceniTyp} from "../page/Dials/user/Oznaceni";

export enum InzeratSekce {
    INZERCE = "INZERCE"
}

export enum InvalidDuvodInzerat {
    OK,
    PRILIS_STARY,
    ZAKAZANE_SLOVO,
    ZNEPLATNENO_OPERATOREM,
    UZIVATEL_ZAKAZ,
    PROVOZOVNA_POZASTAVENA,
    NEZNAME_TEL_CISLO = 8,
    DUPLICITA = 9,
    UZIVATEL_POZASTAVEN,
    UZIVATEL_BEZ_TELEFONU,
    ODEBRANE_PRAVA
}

export class Inzerat {
    id: number;

    sekce: InzeratSekce;

    popis: string;

    cena: number;

    @JsonProperty({converters: MomentConverters})
    datIns: Moment;

    valid: boolean;

    @JsonProperty({type: {enum: InvalidDuvodInzerat}})
    invalDuv: InvalidDuvodInzerat;

    uzivatelPozast: boolean;

    @JsonProperty({type: {clazz: UserDispecer}})
    dispecer: UserDispecer;

    @JsonProperty({type: {clazz: User}})
    uzivatel: BasicUserData;

    @JsonProperty({converters: MomentConverters})
    modifiedOn: Moment;

    @JsonProperty({type: {clazz: Currency}})
    currency: Currency;

    jednotka?: string;

    email?: string;

    rychlyKontakt?: string;

    pocetEditaci?: number;
}

export class LockableInzerat extends Inzerat {
    tabId?: string;
    @JsonProperty({type: {clazz: UserInfo}})
    lockUserInfo?: UserInfo;
}

export class InzeratFilter extends Inzerat {
    cenaRange?: NumberRange
    @JsonProperty({type: {clazz: ItemAge}})
    itemAge: ItemAge
    deletedOnRange?: DateRange
    modifiedOnRange?: DateRange
    entityType?: string
    inzeratId?: number
}

export class InzeratFilterOznaceno extends InzeratFilter {
    oznaceno?: boolean;

    dispecerText?: string;

    provozovna?: string;

    uzivatelText?: string;

    klasifikace?: string;

}

export class InzeratView extends CachedDetailRow<InzeratViewDetail> {
    id: number;

    sekce: InzeratSekce;

    popis: string;

    cena: number;

    @JsonProperty({converters: MomentConverters})
    datIns: Moment;

    @JsonProperty({converters: MomentConverters})
    datArch: Moment;

    @JsonProperty({type: {clazz: UserViewDispecer}})
    dispecer: UserViewDispecer;

    @JsonProperty({converters: MomentConverters})
    modifiedOn: Moment;

    @JsonProperty({type: {clazz: Currency}})
    currency: Currency;

    oznaceno?: boolean;

    uzivatelOznaceni?: [OznaceniTyp]

    provozovnaOznaceni?: [OznaceniTyp]

    provozovna: string;

    firma: string;

    rychlyKontakt?: string;

    @JsonProperty({type: {clazz: Klasifikace}})
    klasifikace?: Klasifikace;

    jednotka: string;

    email: string;
}

export class InzeratViewArchiv extends InzeratView {
    @JsonProperty({type: {clazz: BasicUserData}})
    @JsonIgnore({ignoreSet: true, ignoreGet: false})
    uzivatel?: BasicUserData;
}

export class InzeratViewAdmin extends InzeratView {
    valid?: boolean;

    @JsonProperty({type: {enum: InvalidDuvodInzerat}})
    invalDuv?: InvalidDuvodInzerat;

    @JsonProperty({type: {clazz: User}})
    uzivatel?: User;

    //pro historicke zaznamy
    inzeratId?: number;

    parentExists?: boolean;

    //pro archivní zaznamy
    @JsonProperty({converters: MomentConverters})
    deletedOn: Moment;

    nabidkaId: number;

    typ: string;

    neverPozn: string;

    entityType?: string
}

export class InzeratViewDetail extends InzeratViewAdmin {
    @JsonProperty({type: {clazz: UserBasic}})
    owner: UserBasic;
}

export class InzeratViewUpdate {
    id: number;

    sekce: InzeratSekce;

    popis: string;

    cena: number;

    @JsonProperty({converters: MomentConverters})
    datIns: Moment;

    @JsonProperty({type: {clazz: UserViewDispecer}})
    dispecer: UserViewDispecer;

    @JsonProperty({converters: MomentConverters})
    modifiedOn: Moment;

    @JsonProperty({type: {clazz: Currency}})
    currency: Currency;

    provozovna: string;

    firma: string;

    rychlyKontakt?: string;

    valid?: boolean;

    @JsonProperty({type: {enum: InvalidDuvodInzerat}})
    invalDuv?: InvalidDuvodInzerat;

    @JsonProperty({type: {clazz: BasicUserData}})
    uzivatel?: BasicUserData
}
