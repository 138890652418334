import React from "react";
import * as faIcon from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import {DashboardTile} from "./DashboardTile";
import {DashboardVideo} from "./DashboardVideo";

export function DashboardMaster({jednouzivatelskaLicence}: {
    jednouzivatelskaLicence: boolean,
}) {
    const {t} = useTranslation();

    return (
        <>
            <Grid style={{padding:20}}>
                {!jednouzivatelskaLicence && <DashboardVideo videoURL="/videos/master_tutorial_cz.mov" thumbnailTranslationUrl={"StaticLinks.Thumbnail.MasterTutorial"} />}
                <Grid container spacing={4}>
                    <DashboardTile text={t("Provozovna.Title")} icon={faIcon.faBuilding} href={"/master/provozovna"}/>
                    <DashboardTile text={t("Dials.Smlouvy")} icon={faIcon.faFileInvoice} href={"/master/smlouvy"}/>
                    <DashboardTile text={t("Dials.Users")} icon={faIcon.faUser} href={"/master/uzivatele"}/>
                </Grid>
            </Grid>
        </>
    );
}