import * as DG from "../DataGrid.d";
import {TemplateDialogType} from "../DataGrid.d";
import React, {
    MutableRefObject,
    ReactElement,
    ReactNode,
    Ref,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from "react";
import {LoadingExposed} from "../../../../common/component/Loading";
import {FormButtonProps} from "../../form/FormButton";
import {Badge, Box, Button, Grid, IconButton, Tooltip, useMediaQuery} from "@material-ui/core";
import {createStyles, makeStyles, Theme, withStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {useTemplates} from "../MTOverrides";
import {useAppContext} from "../../../context/AppContext";
import {exist, isDeepEqualNullIgnored, isObjectEmpty} from "../../../../common/utils/Util";
import {useSubscribe} from "use-pubsub-js";
import {AutoRefreshContext} from "../../../AutoRefresh";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Clear";
import {StandaloneField} from "../../../../common/component/form/StandaloneField";
import {FormInputType} from "../../form/Form";
import {DGContext} from "../DataGrid";
import {showFilterHeaderDialog} from "./FilterHeaderModal";
import SaveIcon from '@mui/icons-material/Save';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {FormFilter, FormFilterOptions, useFilterSelectPopup} from "../../form/FormFilter";
import {useTableCache} from "../MTCache";

/**
 * V novem objektu to je kvuli statu, aby se po zmene selectu zbytecne neprerenderovaval cely filtr
 * @constructor
 */
interface FilterHeaderProps<Filter> {
    gridProps:DG.Props<any>
    open:boolean
    indicator:ReactNode
    filters:Filter
    onFilterChange:(data?:Filter, name?:string) => void
    onValueChanged?:() => void
    setOpen:(open:boolean, skipRefresh?: boolean, autoFilter?: boolean)=>void
    loadingRef: MutableRefObject<LoadingExposed>
    customFilterHeaderButtons?: () => ReactElement<FormButtonProps, any>[]
    onClearFilter?: () => void
    filtersRef: MutableRefObject<Filter>
    filterHeaderRef?: Ref<FilterHeaderExposed>
}

export type FilterHeaderExposed = {
    filtersChanged(data: any): void
}

const useStyles = makeStyles((theme) =>
    createStyles({
        tooltip: {
            textTransform: "uppercase",
            fontSize: "10pt"
        },
        button: {
            lineHeight: "normal",
            textAlign: "left",
            fontSize: "12px",
            fontWeight: 700,
            width: "min-content",
            padding: "5px 7px 3px 5px",
            height: "36px",
            flexGrow: 1,
        },
        filterButton: {
            width: "100%",
            height: "100%",
            fontWeight: 700,
            lineHeight: "normal"
        }
    })
);

export const FilterHeader = <Filter extends any>({gridProps, onClearFilter, open, filters, onFilterChange, onValueChanged, setOpen, indicator, loadingRef, customFilterHeaderButtons, filtersRef, filterHeaderRef, filterClazz}:DG.FilterProps<Filter> & FilterHeaderProps<Filter>) => {
    const {getState} = useContext(DGContext);
    const isLarge = useMediaQuery<Theme>(theme => theme.breakpoints.up('lg'));
    const typ = `${gridProps.id || gridProps.endpoint}`;
    const state = getState();
    const {t} = useTranslation();
    const fns = useTemplates<Filter>(filtersRef, filterClazz);
    const closeButtonRef = useRef<HTMLButtonElement>();
    const [s, setS] = useState(true);
    // eslint-disable-next-line
    const forceUpdate = () => setS(!s);
    const {checkDataChanged} = useAppContext();
    const isEmpty = isObjectEmpty(filters);
    const [isTemplateSet, setIsTemplateSet] = useState(false);
    const classes = useStyles();
    const { clearTableCache } = useTableCache(gridProps.id || gridProps.endpoint, gridProps?.cache?.group);
    const StyledBadge = withStyles((themeDef: Theme) =>
        createStyles({
            badge: {
                left: -2,
                top: 18,
                border: `2px solid ${themeDef.palette.background.paper}`,
                padding: '0 4px',
            },
            root: {
                flexGrow: 1
            }
        }),
    )(Badge);

    const filterChanged = (clear: boolean, filterName?: string, timeout: number = 1, useStateValue: boolean = false) => {
        if (exist(onClearFilter) && clear) {
            onClearFilter();
        }
        if(clear) clearTableCache();

        setIsTemplateSet(false);
        loadingRef.current.setShow(true);
        setTimeout(() => !clear ? onFilterChange(useStateValue? getState().current.filters : filters, filterName) : onFilterChange(), timeout);
    }

    const handler  = (token?:string | symbol, message?: string) => {
        if (token === 'defaultTemplateSet' + gridProps.tableTitle ?? '') {
            setIsTemplateSet(message ? Boolean(message) : false);
        }
    }

    const { unsubscribe, resubscribe } = useSubscribe({ token: 'defaultTemplateSet' + gridProps.tableTitle ?? '', handler })

    const [isFilterEmpty, setIsFilterEmpty] = useState(isDeepEqualNullIgnored(filters, {}, true));
    const [isTemplateChanged, setIsTemplateChanged] = useState(fns.isTemplateModified(filters));

    useImperativeHandle(filterHeaderRef, () => {
        return {
            filtersChanged: (data: any) => {
                let isModified = fns.isTemplateModified(data);
                if (isModified == null){
                    const isEmpty = isDeepEqualNullIgnored(data, {}, true);
                    if(isFilterEmpty !== isEmpty) setIsFilterEmpty(isEmpty);
                }
                if(isTemplateChanged !== isModified) setIsTemplateChanged(isModified);
            }
        }
    })

    useEffect(() => {
        resubscribe();
        return () => {
            unsubscribe();
        }
        // eslint-disable-next-line
    }, [])

    const processTemplateChange = (value: any) => {
        checkDataChanged(async () => {
            if (value) {
                await fns.applyTemplate(value);
                loadingRef.current.setShow(true);
                setOpen(false);
                onFilterChange(getState().current.filters) // 4584, povodne onValueChanged();
            }
            else {
                await fns.applyTemplate(null);
                filterChanged(true, null, 700);
            }
        })
    }

    const showPopup = useFilterSelectPopup({
        typ: typ,
        templateFunctions: fns,
        setSelectedValue: (v) => processTemplateChange(v.value),
    });

    return <AutoRefreshContext {...gridProps}>
        {
            !open ? (
                <Box display="flex" flexDirection={isLarge ? "row" : "column"} style={{justifyContent: "space-between"}}>
                    <Grid container justifyContent={"space-between"} alignItems={"center"} style={{columnGap: "20px", rowGap: "6px", marginTop: "-6px"}}>
                        <Box style={{width: '100%'}}>
                            {customFilterHeaderButtons&&customFilterHeaderButtons()}
                        </Box>
                        <Box style={!isLarge ? {width: '100%'} : {flexGrow: 50}}>
                            {indicator || <div/>}
                        </Box>
                        <Box display="flex" flexDirection={"row"} flexWrap={"wrap"} style={!isLarge ? {width: '100%', columnGap: "5px", rowGap: "5px", flexGrow: 1} : {columnGap: "5px", rowGap: "5px", flexGrow: 1}}>
                            <Tooltip title={isEmpty && !isTemplateSet && !state.current?.name ? t("Filter.TooltipForEmptyFilter") : t("Filter.TooltipForFilledFilter")}>
                                <StyledBadge color="error" invisible={isEmpty && !isTemplateSet && !state.current?.name} anchorOrigin={{vertical: 'top', horizontal: 'left'}}>
                                    <Button className={classes.button} onClick={() => setOpen(true)}
                                            color="primary" variant="contained">
                                            <FilterListIcon style={{marginRight: 10}}/>
                                         {t("Filter.OpenFilter")}
                                    </Button>
                                </StyledBadge>
                            </Tooltip>
                            {!gridProps.disableExtendedFilterButtons && <>
                                <Button disabled={(state.current?.id == null && isFilterEmpty) || (state.current?.id != null && !isTemplateChanged)} className={classes.button} color="primary" variant="contained" onClick={() => {
                                    showFilterHeaderDialog(TemplateDialogType.NAME_DIALOG, fns, () => {
                                        setOpen(false);
                                        filterChanged(false, null, 700);
                                    }, state.current?.id, typ, state.current?.vychozi, state.current?.name);
                                }}><SaveIcon style={{marginRight: 5}}/>{t("Filter.SaveFilter")}</Button>
                                <Button className={classes.button} color="primary" variant="contained" onClick={() => {
                                    showPopup(state.current.id).then();
                                }}><FilterAltIcon style={{marginRight: 5}}/>{t("Filter.SavedFilters")}</Button>
                                <Button className={classes.button} color="primary" variant="contained" onClick={() => {
                                    filterChanged(true, null, 700);
                                }}><ClearIcon style={{marginRight: 5}}/>{t("Filter.ClearFilter")}</Button>
                            </>}
                            <Button className={classes.button} onClick={() => {filterChanged(false, null, 700, true);}}
                                    color="primary" variant="contained">
                                <SearchIcon style={{marginRight: 5}}/> {t("Filter.DoFilter")}
                            </Button>
                        </Box>
                    </Grid>
                </Box>
            ) : (
                <Box style={{padding: 5}} key={`${s}`}>
                    <Grid container spacing={1}>
                        <Grid item lg={12} sm={12} md={12} xs={12} style={{padding: 0}}>
                            <Box style={{textAlign: "end"}}>
                                <IconButton ref={closeButtonRef} onClick={() => setOpen(false, null, true)} focusRipple={true} autoFocus={true} onKeyDown={(e) => {
                                    if (e.key === 'Escape') setOpen(false);
                                }}>
                                    <CloseIcon/>
                                </IconButton>
                            </Box>
                        </Grid>
                        <Grid item lg={12} sm={12} md={12} xs={12}>
                            <Grid container spacing={1} alignItems={"center"}>
                                {
                                    indicator && (
                                        <Grid item lg={12} sm={12} md={12} xs={12}>
                                            {indicator}
                                        </Grid>
                                    )
                                }
                                <Grid item lg={12} sm={12} md={12} xs={12}>
                                    <Grid container spacing={1} alignItems={"center"} style={{width: '100%'}}>

                                        <Grid item lg={6} md={12} sm={12} xs={12}>
                                            <Grid container>
                                                <StandaloneField<FormFilterOptions<Filter>>
                                                    value={state.current}
                                                    type={FormInputType.Custom}
                                                    onValueChanged={processTemplateChange}
                                                    customComponent={FormFilter}
                                                    customComponentOptions={{
                                                        templateFunctions: fns,
                                                        filters: filters,
                                                        filtersType: typ,
                                                        isFilterChanged: isTemplateChanged,
                                                        isDefault: state.current?.vychozi,
                                                        filterChanged: (filterName: string, clear?: boolean) => {
                                                            setOpen(false);
                                                            filterChanged(clear ?? false, filterName, 700);
                                                        }
                                                    }}
                                                    title={t("Filter.Templates")}
                                                    disabled={false}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1} lg={6} md={12} sm={12} xs={12} style={{paddingLeft: 4}}>
                                            <Grid item lg={4} md={12} sm={12} xs={12}>
                                                <Button className={classes.filterButton} color="primary" variant="contained" onClick={() => {
                                                    showPopup(state.current.id).then();
                                                }}><FilterAltIcon style={{marginRight: 5}}/>{t("Filter.SavedFilters")}</Button>
                                            </Grid>
                                            <Grid item lg={4} md={12} sm={12} xs={12}>
                                                <Button onClick={() => {
                                                    setOpen(false);
                                                    filterChanged(true, null, 700);
                                                }} color="secondary"
                                                        variant="contained" className={classes.filterButton}>
                                                    <ClearIcon style={{marginRight: 5}}/>
                                                    {t("Filter.ClearFilter")}
                                                </Button>
                                            </Grid>
                                            <Grid item lg={4} md={12} sm={12} xs={12}>
                                                <Button onClick={() => {
                                                    setOpen(false);
                                                    filterChanged(false, null, 700);
                                                }}
                                                        color="primary"
                                                        variant="contained" className={classes.filterButton}>
                                                    <SearchIcon style={{marginRight: 5}}/>
                                                    {t("Filter.DoFilter")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            )
        }
    </AutoRefreshContext>
}
