import {FormField, useFormData} from "../../../../raal_components/form/Form";
import {useTranslation} from "react-i18next";
import {
    useCiselnikSelect,
    useCurrencySelectObj,
    useDateIsoSelect,
    useEnumCiselnikSelect,
    useEnumeratedSelect,
    useKlasifikaceSelect,
    useUserBasicSelect,
    useUserDispecerSelect,
    useYesNoSelect
} from "../../../../raal_components/SelectOptions";
import {Grid} from "@material-ui/core";
import {FormNumberRange, FormNumberRangeOptions} from "../../../../../common/component/form/FormNumberRange";
import React, {ReactNode, useCallback} from "react";
import {CiselnikTyp} from "../../../../model/Ciselnik";
import {
    cenaConstraint,
    paletyConstraint,
    sizeFormat,
    sizeIntegerFormat,
    vahaConstraint
} from "./PrepravaAVozidlaShared";
import {
    InvalDuvodPreprava,
    InvalDuvodVozidlo,
    PrepravaSearchEntityType,
    VolneVozySearchEntityType
} from "../../../../model/CommonTypes";
import {FormDateRangeSelect} from "../../../../raal_components/FormTwoDatesSelect";
import {SystemParamKey} from "../../../../model/SystemParameter";
import {cenaFormat} from "../zadani/InzerceCiselnik";
import {FormDateRange, FormDateRangeOptions} from "../../../../../common/component/form/FormDateRange";
import {VPFilter} from "../../../../model/PrepravaVozidlo";
import {
    FormVicinityContainer,
    FormVicinityContainerNew,
    Vicinity
} from "../../../../../common/component/form/FormVicinityContainer";
import _ from "lodash";

export type FiltrFormProps = {
    lastBrowseDateDataKey?: string
    typCiselniku:CiselnikTyp
    showOznaceno?: boolean
    preprava: boolean
    viewing?: boolean
    typAdvance:SystemParamKey
    defaultRange:number
    defaultAdvance:number
    admin?: boolean
    archive?: boolean
    history?: boolean
    singleView?: boolean
    disableMultichoice?: boolean
    children?: ReactNode
    hideItemAge?: boolean
    archiveZadani?: boolean
    search?: boolean
    historyTab?: boolean
    oldVersion?: boolean //pokud false, tak se použije FormVicinityContainerNew. Po úpravě všeho na New bude možné smazat celou logiku rozlišování
    isArchiveOfBrowsedOffers?: boolean
}

export const njsSwitch = (type: 'N' | 'S' | 'J', data: any) => {
    _.set(data, 'naves', type === 'N');
    _.set(data, 'souprava', type === 'S');
    _.set(data, 'jine', type === 'J');
}

export function FilterForm({typCiselniku, showOznaceno = false, preprava, viewing = false, typAdvance, defaultRange, defaultAdvance, admin = false, archive = false, history = false, singleView = false, children = undefined, disableMultichoice = false, archiveZadani = false, search = false, historyTab = false, oldVersion = false, isArchiveOfBrowsedOffers = false}: FiltrFormProps) {
    const {t} = useTranslation();
    const currencySelectProps = useCurrencySelectObj({isClearable: true});
    const dateFromSelect = useDateIsoSelect({dateType: 'datOd', typAdvance: typAdvance, defaultAdvanceValue: defaultAdvance})
    const yesNoSelect = useYesNoSelect({isClearable:true});
    const spacing = 1;
    const dateSpacing = 0;
    const ciselnikSelectFilter = useCiselnikSelect(typCiselniku, {isClearable: true, isMulti: disableMultichoice!==true});
    const userSelectProps = useUserBasicSelect({isClearable:true});
    const invalDuvSelect = useEnumCiselnikSelect({isClearable: true, ciselnikTyp: preprava ? CiselnikTyp.P : CiselnikTyp.V, enm: preprava ? InvalDuvodPreprava : InvalDuvodVozidlo, enmName: preprava ? "Enumerations.InvalDuvodPreprava" : "Enumerations.InvalDuvodVozidlo"});
    const klasifikaceSelect = useKlasifikaceSelect({isClearable: true, isMulti: true})
    const rangeNumberSharedOptions = {spacing: 1, numberProps: {format:sizeFormat, constraint:vahaConstraint}};
    const rangePaletySharedOptions = {spacing: 1, numberProps: {format: sizeIntegerFormat, constraint: paletyConstraint}};
    const rangeCenaSharedOptions = {spacing: 1, numberProps: {format: cenaFormat, constraint: cenaConstraint}};
    const dispecerSelectProps = useUserDispecerSelect({isClearable:true});
    const filterData = useFormData<VPFilter>();
    const searchTypeSelect = useEnumeratedSelect(preprava ? PrepravaSearchEntityType : VolneVozySearchEntityType, preprava ? "SearchEnum.Preprava" : "SearchEnum.VolneVozy", "string", {isClearable:true});

    const compareRegion = useCallback((field: "okoliOdkud" | "okoliKam") => {
        const odkud = filterData.regOd ?? filterData.okoliOdkud?.countryCode;
        const kam = filterData.regKam ?? filterData.okoliKam?.countryCode;
        return (field === "okoliKam" && odkud === "CZ") || (field === "okoliOdkud" && kam === "CZ");
    }, [filterData])

    return (
        <Grid container spacing={1}>
            <Grid container item xs={12} spacing={spacing}>
                {children}
                <Grid container item spacing={1}>
                    <Grid item lg={6} sm={6} xs={12}>
                        <FormField name={"okoliOdkud"} type={"Custom"} customComponent={oldVersion ? FormVicinityContainer : FormVicinityContainerNew}
                                   customComponentOptions={{
                                       disableClearable: true,
                                       checkLicense: true,
                                       titleOkoli: t("Preprava.okoliOdkud"),
                                       titleRadius: t("Preprava.radiusOdkud"),
                                       titleMpz: t("Preprava.mpz"),
                                       titlePsc: t("Preprava.psc"),
                                       titleNazevMista: t("Preprava.odkud"),
                                       showTitle: true,
                                       filterForm: true,
                                       countryCodeForCompare: () => compareRegion("okoliOdkud"),
                                       onChange: (okoli: Vicinity) => {
                                           filterData.okoliOdkud = okoli
                                       }
                                   }}
                        />
                    </Grid>
                    <Grid item lg={6} sm={6} xs={12}>
                        <FormField name={"okoliKam"} type={"Custom"} customComponent={oldVersion ? FormVicinityContainer : FormVicinityContainerNew}
                                   customComponentOptions={{
                                       disableClearable: true,
                                       checkLicense: true,
                                       titleOkoli: t("Preprava.okoliKam"),
                                       titleRadius: t("Preprava.radiusKam"),
                                       titleMpz: t("Preprava.mpz"),
                                       titlePsc: t("Preprava.psc"),
                                       titleNazevMista: t("Preprava.kam"),
                                       showTitle: true,
                                       filterForm: true,
                                       countryCodeForCompare: () => compareRegion("okoliKam"),
                                       onChange: (okoli: Vicinity) => {
                                           filterData.okoliKam = okoli
                                       }
                                   }}
                        />
                    </Grid>
                </Grid>
                {!search&&<Grid item lg={6} sm={12} xs={12}>
                    <FormField title={t("Preprava.druhy")} name={disableMultichoice !== true ? "druhy" : "druh"}
                               type={"select"} selectProps={ciselnikSelectFilter}/>
                </Grid>}
                <Grid item lg={2} sm={4} xs={12}>
                    <FormField title={t("Preprava.naves")} name={"naves"} type={"checkbox"} onWillChange={(field, newValue) => {
                        if (newValue) njsSwitch('N', filterData);
                    }} forceUpdateWhenDataChanged={['souprava', 'jine']} />
                </Grid>
                <Grid item lg={2} sm={4} xs={12}>
                    <FormField title={t("Preprava.souprava")} name={"souprava"} type={"checkbox"} onWillChange={(field, newValue) => {
                        if (newValue) njsSwitch('S', filterData)
                    }} forceUpdateWhenDataChanged={['naves', 'jine']} />
                </Grid>
                <Grid item lg={2} sm={4} xs={12}>
                    <FormField title={t("Preprava.jine")} name={"jine"} type={"checkbox"} onWillChange={(field, newValue)=> {
                        if (newValue) njsSwitch('J', filterData)
                    }} forceUpdateWhenDataChanged={['souprava', 'naves']} />
                </Grid>
                <Grid item lg={2} sm={12} xs={12}>
                    <FormField title={t("Currency.Title")} name='currency' type='select' selectProps={currencySelectProps}/>
                </Grid>
                <Grid item lg={2} sm={12} xs={12}>
                    <FormField title={t("Default.Telefon")} name={"rychlyKontakt"} type={"text"}/>
                </Grid>
                <Grid item lg={2} sm={12} xs={12}>
                    <FormField title={t("Default.Email")} name={"email"} type={"text"}/>
                </Grid>
                <Grid item lg={3} sm={12} xs={12}>
                    {viewing ? <FormField title={t("Preprava.dispecer")} name={"dispecerText"} type={"text"}/> : <FormField title={t("Inzerce.Dispecer")} name='dispecer' type='select' selectProps={dispecerSelectProps}/>}
                </Grid>

                <Grid item lg={3} sm={12} xs={12}>
                    <FormField title={t("Provozovna.Kod")} name={"provozovna"} type={"text"}/>
                </Grid>

                {!archiveZadani && (viewing || admin) ? undefined :
                <Grid item xs={12} sm={12} lg={6}>
                    <FormField title={t("User.Title")} name='uzivatelFilter' type='select' selectProps={userSelectProps}/>
                </Grid>}
                {!archiveZadani && (viewing===true || showOznaceno) ? undefined : !admin || search  ? <Grid item lg={6} sm={12} xs={12}>
                    <FormField title={t("PVI.InvalDuv")} name={"invalDuv"} type={"select"} selectProps={invalDuvSelect}/>
                    </Grid> : null
                }
                {admin || search ?
                    <>
                        <Grid item lg={6} sm={12} xs={12}>
                            <FormField<FormDateRangeOptions> title={t("Preprava.datOd")} name={"datOdRange"} type={"Custom"}
                                                             customComponent={FormDateRange} customComponentOptions={{spacing}}/>
                        </Grid>
                        <Grid item lg={6} sm={12} xs={12}>
                            <FormField<FormDateRangeOptions> title={t("Preprava.datDo")} name={"datDoRange"} type={"Custom"}
                                                             customComponent={FormDateRange} customComponentOptions={{spacing}}/>
                        </Grid>
                    </>
                :
                    <Grid item lg={6} sm={12} xs={12}>
                        <FormField name={"vpRange"} type={"Custom"}
                        customComponent={FormDateRangeSelect} customComponentOptions={{dateSpacing, dateFromSelect, typParametru: typAdvance, defaultRange}}/>
                    </Grid>
                }
                {
                    admin || search ?
                        <>
                            <Grid item xs={12} sm={12} lg={6}>
                                <FormField title={t("PVI.Valid")} name={"valid"} type={"select"} selectProps={yesNoSelect}/>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                                <FormField title={t("PVI.InvalDuv")} name={"invalDuv"} type={"select"} selectProps={invalDuvSelect}/>
                            </Grid>
                        </>
                        : null
                }
                <Grid item lg={6} sm={12} xs={12}>
                    <FormField<FormNumberRangeOptions> title={t("Preprava.AirDistanceShort")} name={"airDistanceRange"} type={"Custom"}
                                                       customComponent={FormNumberRange} customComponentOptions={rangeNumberSharedOptions}/>
                </Grid>
                <Grid item lg={6} sm={12} xs={12}>
                    <FormField<FormNumberRangeOptions> title={t("Preprava.delka")} name={"delkaRange"} type={"Custom"}
                                                       customComponent={FormNumberRange} customComponentOptions={rangeNumberSharedOptions}/>
                </Grid>
                <Grid item lg={6} sm={12} xs={12}>
                    <FormField<FormNumberRangeOptions> title={t("Preprava.vaha")} name={"vahaRange"} type={"Custom"}
                                                       customComponent={FormNumberRange} customComponentOptions={rangeNumberSharedOptions}/>
                </Grid>
                <Grid item lg={6} sm={12} xs={12}>
                    <FormField title={t("Preprava.cena")} name="cenaRange" type="Custom" customComponent={FormNumberRange} customComponentOptions={rangeCenaSharedOptions}/>
                </Grid>
                <Grid item lg={6} sm={12} xs={12}>
                    <FormField<FormNumberRangeOptions> title={t("Preprava.palety")} name={"paletyRange"} type={"Custom"}
                                                       customComponent={FormNumberRange} customComponentOptions={rangePaletySharedOptions}/>
                </Grid>
                <Grid item lg={6} sm={12} xs={12}>
                    <FormField<FormNumberRangeOptions> title={t("Preprava.sirka")} name={"sirkaRange"} type={"Custom"}
                                                       customComponent={FormNumberRange} customComponentOptions={rangeNumberSharedOptions}/>
                </Grid>
                <Grid item lg={6} sm={12} xs={12}>
                    <FormField<FormNumberRangeOptions> title={t("Preprava.vyska")} name={"vyskaRange"} type={"Custom"}
                                                       customComponent={FormNumberRange} customComponentOptions={rangeNumberSharedOptions}/>
                </Grid>
                <Grid item lg={6} sm={12} xs={12}>
                    <FormField<FormNumberRangeOptions> title={t("Preprava.lozPlochaSmaller")} name={"lozPlochaRange"}
                                                       type={"Custom"} customComponent={FormNumberRange} customComponentOptions={rangeNumberSharedOptions}/>
                </Grid>
                <Grid item lg={6} sm={12} xs={12}>
                    <FormField<FormNumberRangeOptions> title={t("Preprava.objem")} name={"objemRange"} type={"Custom"}
                                                       customComponent={FormNumberRange} customComponentOptions={rangeNumberSharedOptions}/>
                </Grid>
                <Grid item lg={8} sm={12} xs={12}>
                    <FormField title={t("Preprava.verejnaPozn")} name={"verejnaPozn"} type={"text"} textFieldProps={{inputProps: {maxLength: 30}}}/>
                </Grid>

                <Grid item lg={4} sm={6} xs={12}>
                    <FormField title={t("Preprava.nakladkaZezadu")} name={"nakladkaZezadu"} type={"select"} selectProps={yesNoSelect}/>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                    <FormField title={t("Preprava.nakladkaBokem")} name={"nakladkaBokem"} type={"select"} selectProps={yesNoSelect}/>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                    <FormField title={t("Preprava.nakladkaShora")} name={"nakladkaShora"} type={"select"} selectProps={yesNoSelect}/>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                    <FormField title={t("Preprava.naklProstorVcelku")} name={"naklProstorVcelku"} type={"select"} selectProps={yesNoSelect}/>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                    <FormField title={t("Preprava.zvedaciCelo")} name={"zvedaciCelo"} type={"select"} selectProps={yesNoSelect}/>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                    <FormField title={t("Preprava.druhyRidic")} name={"druhyRidic"} type={"select"} selectProps={yesNoSelect}/>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                    <FormField title={t("Preprava.zakazDokladky")} name={"zakazDokladky"} type={"select"} selectProps={yesNoSelect}/>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                    <FormField title={t("Preprava.adr")} name={"adr"} type={"select"} selectProps={yesNoSelect}/>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                    <FormField title={t("Preprava.hydraulickaRuka")} name={"hydraulickaRuka"} type={"select"} selectProps={yesNoSelect}/>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                    <FormField title={t("Preprava.nadrozmernyNaklad")} name={"nadrozmernyNaklad"} type={"select"} selectProps={yesNoSelect}/>
                </Grid>
                {admin || search ?
                <Grid item lg={4} sm={12} xs={12}>
                    <FormField title={t("User.Title")} name={"uzivatelText"} type={"text"} textFieldProps={{inputProps: {maxLength: 30}}}/>
                </Grid> : undefined}
                {viewing && !admin && !archive ?
                <Grid item xs={12} sm={12} lg={4}>
                    <FormField title={t("Klasifikace.klasifikace")} name='klasifikace.klasifikace' type={"select"} selectProps={klasifikaceSelect}/>
                </Grid>
                 : undefined}
                 {/* skryto na žádost zákazníka dle #3832
                 {showOznaceno && Boolean(user.provozovna) && !history && !archive ?
                     <Grid item lg={4} sm={12} xs={12}>
                         <FormField title={t("PVI.Marked")} name={"oznaceno"} type={"select"} selectProps={yesNoSelect}/>
                     </Grid> : null
                 }*/}
                {history && !singleView ?
                    <Grid item lg={4} sm={6} xs={12}>
                        <FormField title={t("Preprava.id")} name={"parentId"} type={"number"} numberProps={{format: '0.[00]'}}/>
                    </Grid> : null}
                {search ?
                    <Grid item xs={12} sm={6} lg={4}>
                        <FormField title={t("SearchEnum.entityType")} name='entityType' type='select' selectProps={searchTypeSelect} serverValidation={{translate:t}}/>
                    </Grid> : null
                }
                <Grid item lg={4} sm={6} xs={12}>
                    <FormField<FormDateRangeOptions> title={t("PVI.Modified")} name={"modifiedOnRange"} type={"Custom"}
                                                     customComponent={FormDateRange}
                                                     customComponentOptions={{spacing: 1, timeFormat: false}}/>
                </Grid>
                {archive || search  ?
                    <Grid item lg={4} sm={6} xs={12}>
                        <FormField<FormDateRangeOptions> title={t("Archive.Archived")} name={isArchiveOfBrowsedOffers ?  "datInsRange" : "deletedOnRange"} type={"Custom"} customComponent={FormDateRange} customComponentOptions={{spacing: 1, timeFormat: false}}/>
                    </Grid> : null
                }
                {((search || admin) && !historyTab) && (
                    <Grid item lg={4} sm={6} xs={12}>
                        <FormField title={t("Default.Id")} name={'id'} type={"number"} numberProps={{format: '0.[00]'}}/>
                    </Grid>
                )}
                {archiveZadani || search ? <Grid item lg={12} sm={12} xs={12}>
                    <FormField title={t("Preprava.neverPozn")} name={"neverPozn"} type={"text"}/>
                </Grid> : null}
            </Grid>
        </Grid>);
}
