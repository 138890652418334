import React from "react";
import {DashboardUser} from "./DashboardUser";
import {useAppContext} from "../../context/AppContext";
import {UserRole} from "../../model/User";
import {DashboardAdmin} from "./DashboardAdmin";
import {DashboardMaster} from "./DashboardMaster";
import {Paper} from "@material-ui/core";

export function Dashboard() {
    const {hasUserRole, user} = useAppContext();

    return (
        <Paper style={{flexGrow: "inherit"}}>
            {hasUserRole(UserRole.ROLE_USER) && <DashboardUser />}
            {hasUserRole(UserRole.ROLE_ADMIN) && <DashboardAdmin />}
            {!hasUserRole(UserRole.ROLE_USER) && hasUserRole(UserRole.ROLE_MASTER) && <DashboardMaster jednouzivatelskaLicence={user.jednouzivatelskaLicence}/>}
        </Paper>
    );
}